// src/components/OrderPageComponents/hooks/useInitializeItemProperties.js
import {useEffect} from 'react';

export function useInitializeItemProperties(
    fromConfigureButton,
    foundItem,
    selectedItems,
    includeLegLevelers,
    setIncludeLegLevelers,
    setItemProperties,
    itemProperties
) {
    // Initialize `itemProperties` when `fromConfigureButton` is true
    useEffect(() => {
        if (fromConfigureButton && Object.keys(itemProperties).length === 0) {
            const item = {
                itemId: foundItem[0]?.id,
                backPanel: foundItem[0]?.backPanel || 'Inset',
                serialNumber: foundItem[0]?.serialNumber || 'test',
                drawerType: foundItem[0]?.drawers === '0' ? false : foundItem[0]?.drawerType || 'Wooden 5/8"',
                gapTop: (1 / 8).toFixed(3),
                gapBottom: (1 / 8).toFixed(3),
                gapLeft: (1 / 8).toFixed(3),
                gapRight: (1 / 8).toFixed(3),
                jointControl: foundItem[0]?.jointControl,
                jointMethod: foundItem[0]?.jointControl === '0' ? false : foundItem[0]?.jointMethod || 'Biscuit',
                heightRange: foundItem[0]?.heightRange,
                widthRange: foundItem[0]?.widthRange,
                depthRange: foundItem[0]?.depthRange,
                frontMaterial: foundItem[0]?.frontMaterial || '3/4" Medex',
                caseMaterial: foundItem[0]?.caseMaterial || '3/4" Pre-finished Maple',
                backPanelMaterial:
                    foundItem[0]?.backPanelMaterial || foundItem[0]?.caseMaterial || '3/4" Pre-finished Maple',
                quantity: foundItem[0]?.quantity || 1,
                hingeSide: foundItem[0]?.doors === '0' ? false : foundItem[0]?.hingeSide || 'Left',
                height: foundItem[0]?.heightRange.split('-')[0],
                width: foundItem[0]?.widthRange.split('-')[0],
                depth: foundItem[0]?.depthRange.split('-')[0],
                positionName: foundItem[0]?.positionName,
                caseEdge: foundItem[0]?.caseEdge,
                frontEdge: foundItem[0]?.frontEdge,
                edge1:
                    foundItem[0]?.edgeBandingBottom === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
                edge2:
                    foundItem[0]?.edgeBandingRight === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
                edge3:
                    foundItem[0]?.edgeBandingTop === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
                edge4:
                    foundItem[0]?.edgeBandingLeft === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
                edgeBandingType: foundItem[0]?.edgeBandingType || 'No Edgebanding',
                description: foundItem[0]?.description,
                drawers: foundItem[0]?.drawers,
                doors: foundItem[0]?.doors,
                topDrwrHeight: foundItem[0]?.topDrwrHeight || '1',
                topDrwrHeightValue: foundItem[0]?.topDrwrHeightValue || '6',
                adjShelves: foundItem[0]?.adjShelves,
                numOfShelves: foundItem[0]?.numOfShelves || 'Parametric Shelves',
                // includeLegLevelers: includeLegLevelers ? 'true' : 'false',
                includeLegLevelers: foundItem[0]?.includeLegLevelers === 'true' || false,

                bottomPanelConnector: includeLegLevelers ? 'Leg Levelers' : null,
                hingePlate: foundItem[0]?.doors === '1' ? foundItem[0]?.hingePlate : 'Cross',
                excludeFronts: foundItem[0]?.excludeFronts || false,
                matBack: foundItem[0]?.matBack,
                legLevelers: foundItem[0]?.legLevelers,
                gapControl: foundItem[0]?.gapControl,
                filterTags: foundItem[0]?.filterTags,
            };
            setItemProperties(item);
            setIncludeLegLevelers(item.includeLegLevelers === 'true');
        }
    }, [fromConfigureButton, foundItem, setItemProperties, setIncludeLegLevelers, itemProperties]);

    // Initialize `itemProperties` for non-configuration mode
    useEffect(() => {
        if (!fromConfigureButton && Object.keys(itemProperties).length === 0) {
            const commonProps = {};
            if (selectedItems.length > 0) {
                Object.keys(selectedItems[0]).forEach((key) => {
                    if (selectedItems.every((item) => item[key] === selectedItems[0][key])) {
                        commonProps[key] = selectedItems[0][key];
                    }
                });
            }
            setItemProperties(commonProps);
            setIncludeLegLevelers(commonProps.includeLegLevelers === 'true');
        }
    }, [fromConfigureButton, selectedItems, setItemProperties, setIncludeLegLevelers, itemProperties]);

    // Synchronize `includeLegLevelers` with `foundItem`
    useEffect(() => {
        if (fromConfigureButton && Object.keys(foundItem).length > 0) {
            setIncludeLegLevelers(foundItem[0].includeLegLevelers === 'true');
        }
    }, [fromConfigureButton, foundItem, setIncludeLegLevelers]);
}

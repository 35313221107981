// store.js
import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {persistReducer, persistStore} from 'redux-persist';
import cartReducer from '../features/cart/cartSlice';
import userReducer from '../features/user/userSlice';
import globalSettingsReducer from '../features/globalSettings/globalSettingsSlice'
import suggestedArticlesReducer from '../features/suggestedArticles/suggestedArticlesSlice'
import showroomReducer from '../features/showroom/showroomSlice';
import materialsReducer from '../features/materials/materialsSlice';
import edgebandingReducer from '../features/edgebanding/edgebandingSlice';
import chatBotReducer from '../features/chatBot/chatBotSlice';
import orderReducer from '../features/orders/orderSlice';
import vendorReducer from '../features/vendors/vendorSlice';
import orderVendorReducer from '../features/orderVendor/orderVendorSlice'


const persistConfig = {
    key: 'root',
    storage,
};

const appReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    globalSettings: globalSettingsReducer,
    suggestedArticles: suggestedArticlesReducer,
    chatBot: chatBotReducer,
    showroom: showroomReducer,
    materials: materialsReducer,
    edgebanding: edgebandingReducer,
    order: orderReducer,
    vendors: vendorReducer,
    orderVendor: orderVendorReducer,

    // include other reducers here
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        storage.removeItem('persist:root'); // remove persisted data from storage
        state = undefined; // reset the state to initial state
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);
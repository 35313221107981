// OrderDetailPage.jsx
import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Table} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import DataService from '../../../api/DataService';
import OrderStatusManagement from './OrderStatusManagement';
import MaterialsTable from './tables/MaterialsTable';
import EdgebandingTable from './tables/EdgebandingTable';
import DrawersTable from './tables/DrawersTable';
import DoorsTable from './tables/DoorsTable';
import NewMaterialModal from './modals/NewMaterialModal';
import NewEdgebandingModal from './modals/NewEdgebandingModal';
import './OrderManagementStyles.css';
import {fetchVendors} from "../../../features/vendors/vendorSlice";
import {clearOrderVendors, fetchOrderVendorsByOrderId} from "../../../features/orderVendor/orderVendorSlice";
import {format} from "date-fns";

/**
 * Component representing the detailed view of an order.
 * Handles fetching order data, managing state, and communicating with the backend.
 */
const OrderDetailPage = () => {
    const token = useSelector((state) => state.user.accessToken);
    const {orderId} = useParams();
    const dispatch = useDispatch();
    const vendors = useSelector((state) => state.vendors.vendors);
    // const vendorsStatus = useSelector((state) => state.vendors.status);

    // Redux state for orderVendors
    const {
        orderVendors = [],
        loading: vendorsLoading,
        error: vendorsError
    } = useSelector((state) => state.orderVendor) || {};

    // Single order states
    const [order, setOrder] = useState(null);
    const [articles, setArticles] = useState([]);
    const [searchError, setSearchError] = useState('');

    // Image existence
    const [imageExistsMap, setImageExistsMap] = useState({});

    // Article selection
    const [selectedArticles, setSelectedArticles] = useState(new Set());

    // Filter articles by position name
    const [searchFilter, setSearchFilter] = useState('');

    // Materials & Edgebanding
    const [materials, setMaterials] = useState([]);
    const [edgebandingOptions, setEdgebandingOptions] = useState([]);

    // Drawers and Doors
    const [drawers, setDrawers] = useState([]);
    const [doors, setDoors] = useState([]);

    // Remaining balance
    const [remainingBalance, setRemainingBalance] = useState(0.0);

    // Tabs
    const [activeTab, setActiveTab] = useState('materials');

    // Saving changes
    const [saving, setSaving] = useState(false);

    // Modals
    const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
    const [showNewEdgebandingModal, setShowNewEdgebandingModal] = useState(false);

    // Validation
    const [materialValidation, setMaterialValidation] = useState({});
    const [edgebandingValidation, setEdgebandingValidation] = useState({});

    // Alerts
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // Vendor relationships (sourced from Redux store)
    const [vendorRelationships, setVendorRelationships] = useState([]);

    // New Material & Edgebanding
    const [newMaterial, setNewMaterial] = useState({
        name: '',
        description: '',
        thickness: '',
        rawCost: '',
        visible: '1',
    });
    const [newEdgebanding, setNewEdgebanding] = useState({
        imosName: '',
        englishDescription: '',
        visible: '1',
    });

    // Environment
    const baseURL = process.env.REACT_APP_BASEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;

    useEffect(() => {
        if (!orderId) return;

        // **Step 1: Clear Previous OrderVendors**
        // This ensures that when a new order is loaded, the previous order's vendors are removed from the state.
        dispatch(clearOrderVendors());

        // **Step 2: Fetch Order Summary**
        fetchOrderSummary(orderId);

        // **Step 3: Fetch Materials and Edgebanding Options**
        fetchMaterials();
        fetchEdgebandingOptions();

        // **Step 4: Fetch Drawers and Doors**
        fetchDrawers();

        // **Step 5: Fetch Vendors**
        dispatch(fetchVendors());

        // **Step 6: Fetch OrderVendors for the Current Order**
        dispatch(fetchOrderVendorsByOrderId(orderId));

        // **Cleanup on Unmount**
        return () => {
            dispatch(clearOrderVendors());
        };
        // eslint-disable-next-line
    }, [orderId, dispatch]);

    useEffect(() => {
        // **Step 7: Update Vendor Relationships When OrderVendors Change**
        if (orderVendors && orderVendors.length > 0) {
            setVendorRelationships(orderVendors);
            // Update the order's vendor IDs based on fetched relationships
            const updatedOrder = {...order};
            orderVendors.forEach((rel) => {
                switch (rel.role) {
                    case 'CNC_VENDOR':
                        updatedOrder.cncVendorId = rel.vendorId;
                        break;
                    case 'DRAWER_VENDOR':
                        updatedOrder.drawerVendorId = rel.vendorId;
                        break;
                    case 'HARDWARE_VENDOR':
                        updatedOrder.hardwareVendorId = rel.vendorId;
                        break;
                    case 'ASSEMBLY_VENDOR':
                        updatedOrder.assemblyVendorId = rel.vendorId;
                        break;
                    case 'FINISHING_VENDOR':
                        updatedOrder.finishingVendorId = rel.vendorId;
                        break;
                    case 'SHIPPING_VENDOR':
                        updatedOrder.shippingVendorId = rel.vendorId;
                        break;
                    default:
                        break;
                }
            });
            setOrder(updatedOrder);
        } else {
            // **Step 8: Clear Vendor Relationships If No OrderVendors Are Present**
            setVendorRelationships([]);
        }
        // eslint-disable-next-line
    }, [orderVendors]);

    /**
     * Fetches the summary details of the order, excluding vendor relationships.
     *
     * @param {string} id - The ID of the order to fetch.
     */
    const fetchOrderSummary = async (id) => {
        try {
            const response = await axios.get(`${baseURL}/api/orders/${id}/summary`, {
                headers: {'Authorization': `Bearer ${token}`},
            });
            if (response.data.order) {
                setOrder({
                    ...response.data.order,
                });
                setArticles(response.data.articles);
                // Vendor relationships are now handled by the Redux slice
                setSearchError('');
                setSelectedArticles(new Set());
                setRemainingBalance(response.data.remainingBalance);
            } else {
                setSearchError('Order not found');
                setOrder(null);
                setArticles([]);
            }
        } catch (error) {
            console.error('Error fetching order summary:', error);
            setSearchError(
                error.response?.status === 404
                    ? 'Order not found'
                    : 'An error occurred while fetching the order. Please try again.'
            );
            setOrder(null);
            setArticles([]);
        }
    };

    /**
     * Fetches all available materials from the backend.
     */
    const fetchMaterials = async () => {
        try {
            const response = await DataService.getAllMaterials();
            const sortedMaterials = response.sort((a, b) => {
                if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                    return -1;
                } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                    return 1;
                }
                return a.description.localeCompare(b.description);
            });
            setMaterials(sortedMaterials);
        } catch (error) {
            console.error('Error fetching materials:', error);
        }
    };

    /**
     * Fetches all available edgebanding options from the backend.
     */
    const fetchEdgebandingOptions = async () => {
        try {
            const response = await DataService.getAllEdgebanding();
            setEdgebandingOptions(response);
        } catch (error) {
            console.error('Error fetching edgebanding:', error);
        }
    };

    /**
     * Fetches drawer and door details for the order.
     */
    const fetchDrawers = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/internal/getDrawers/${orderId}`, {
                headers: {'Authorization': `Bearer ${token}`},
            });
            setDrawers(response.data.drawers);
            setDoors(response.data.doors);
        } catch (error) {
            console.error('Error fetching drawers:', error);
        }
    };

    /**
     * Handles changes to order status fields.
     *
     * @param {string} field - The status field to update.
     * @param {string} value - The new value for the status field.
     */
    const handleStatusChange = (field, value) => {
        setOrder((prev) => ({...prev, [field]: value}));
    };

    /**
     * Handles vendor assignments to specific roles.
     *
     * @param {string} roleKey - The vendor role key (e.g., 'cncParts').
     * @param {string} vendorId - The ID of the selected vendor.
     */
    const handleVendorAssignment = (roleKey, vendorId) => {
        const roleMap = {
            cncParts: 'CNC_VENDOR',
            drawerBoxes: 'DRAWER_VENDOR',
            hardware: 'HARDWARE_VENDOR',
            assembly: 'ASSEMBLY_VENDOR',
            finishing: 'FINISHING_VENDOR',
            shipping: 'SHIPPING_VENDOR',
        };

        const vendorPropertyMap = {
            cncParts: 'cncVendorId',
            drawerBoxes: 'drawerVendorId',
            hardware: 'hardwareVendorId',
            assembly: 'assemblyVendorId',
            finishing: 'finishingVendorId',
            shipping: 'shippingVendorId',
        };

        const vendorProperty = vendorPropertyMap[roleKey];
        const role = roleMap[roleKey];
        const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format

        // **Step 1: Update the Order's Vendor ID**
        setOrder((prev) => ({
            ...prev,
            [vendorProperty]: vendorId,
        }));

        // **Step 2: Update Vendor Relationships in Local State**
        if (vendorId) {
            setVendorRelationships((prev) => {
                const existingIndex = prev.findIndex((rel) => rel.role === role);
                if (existingIndex !== -1) {
                    // **Update Existing Relationship**
                    const updatedRelationships = [...prev];
                    const vendor = vendors.find((v) => v.id === parseInt(vendorId));
                    updatedRelationships[existingIndex] = {
                        ...updatedRelationships[existingIndex],
                        vendorId: parseInt(vendorId),
                        vendorName: vendor ? vendor.name : '',
                        updatedAt: currentDate,
                        status: 'PENDING', // Adjust based on your logic
                    };
                    return updatedRelationships;
                } else {
                    // **Add New Relationship**
                    const vendor = vendors.find((v) => v.id === parseInt(vendorId));
                    return [
                        ...prev,
                        {
                            id: null, // Will be set by backend
                            orderId: parseInt(orderId),
                            vendorId: parseInt(vendorId),
                            vendorName: vendor ? vendor.name : '',
                            role: role,
                            status: 'PENDING', // Adjust based on your logic
                            amountOwed: 0,
                            notes: '',
                            createdAt: currentDate,
                            updatedAt: currentDate,
                        },
                    ];
                }
            });
        } else {
            // **Step 3: Remove the Relationship If Vendor Is Unassigned**
            setVendorRelationships((prev) =>
                prev.filter((rel) => rel.role !== role)
            );
        }
    };

    /**
     * Handles saving changes to the order, including vendor assignments.
     */
    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            if (!order) return;

            // **Step 1: Format Vendor Relationships for API**
            const formattedVendorRelationships = vendorRelationships.map((rel) => ({
                id: rel.id,
                orderId: parseInt(orderId),
                vendorId: parseInt(rel.vendorId),
                role: rel.role,
                status: rel.status,
                amountOwed: rel.amountOwed,
                notes: rel.notes,
                createdAt: rel.createdAt,
                updatedAt: rel.updatedAt,
            }));

            const updatedOrder = {
                ...order,
                articles: articles,
                orderVendors: formattedVendorRelationships,
            };

            // **Step 2: Send Updated Order to Backend**
            const response = await axios.post(`${baseURL}/api/internal/custom-materials`, updatedOrder, {
                headers: {'Authorization': `Bearer ${token}`},
            });

            if (response.status === 200 || response.status === 201) {
                setShowSuccessAlert(true);
                setAlertMessage('Changes have been saved successfully!');
                window.scrollTo({top: 0, behavior: 'instant'});
                // **Step 3: Refresh Order Data and Vendor Relationships**
                fetchOrderSummary(orderId);
                dispatch(fetchOrderVendorsByOrderId(orderId));
            } else {
                setShowErrorAlert(true);
                setAlertMessage('Unexpected response from the server.');
                window.scrollTo({top: 0, behavior: 'instant'});
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            setSearchError('Failed to save changes. Please try again.');
            setShowErrorAlert(true);
            setAlertMessage('Failed to save changes. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    /**
     * Handles changes to material fields for a specific article.
     *
     * @param {string} type - The type of material field to update (e.g., 'caseMaterial').
     * @param {string} value - The new value for the material field.
     * @param {string} positionName - The position name of the article to update.
     */
    const handleMaterialChange = (type, value, positionName) => {
        const updated = articles.map((article) =>
            article.positionName === positionName ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    /**
     * Handles bulk changes to material fields for selected articles.
     *
     * @param {string} type - The type of material field to update.
     * @param {string} value - The new value for the material field.
     */
    const handleBulkMaterialChange = (type, value) => {
        const updated = articles.map((article) =>
            selectedArticles.has(article.positionName) ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    /**
     * Handles changes to edgebanding fields for a specific article.
     *
     * @param {string} type - The type of edgebanding field to update.
     * @param {string} value - The new value for the edgebanding field.
     * @param {string} positionName - The position name of the article to update.
     */
    const handleEdgebandingChange = (type, value, positionName) => {
        const updated = articles.map((article) =>
            article.positionName === positionName ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    /**
     * Handles bulk changes to edgebanding fields for selected articles.
     *
     * @param {string} type - The type of edgebanding field to update.
     * @param {string} value - The new value for the edgebanding field.
     */
    const handleBulkEdgebandingChange = (type, value) => {
        const updated = articles.map((article) =>
            selectedArticles.has(article.positionName) ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    /**
     * Handles changes to a specific drawer field.
     *
     * @param {number} index - The index of the drawer to update.
     * @param {string} field - The field to update.
     * @param {string} value - The new value for the field.
     */
    const handleDrawerFieldChange = (index, field, value) => {
        const updatedDrawers = drawers.map((drawer, idx) =>
            idx === index ? {...drawer, [field]: value} : drawer
        );
        setDrawers(updatedDrawers);
    };

    /**
     * Handles changes to a specific door field.
     *
     * @param {number} index - The index of the door to update.
     * @param {string} field - The field to update.
     * @param {string} value - The new value for the field.
     */
    const handleDoorFieldChange = (index, field, value) => {
        const updatedDoors = doors.map((door, idx) =>
            idx === index ? {...door, [field]: value} : door
        );
        setDoors(updatedDoors);
    };

    /**
     * Handles selecting or deselecting all articles based on the search filter.
     *
     * @param {object} e - The event object.
     */
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const filtered = articles
                .filter((a) => a.positionName.toLowerCase().includes(searchFilter.toLowerCase()))
                .map((a) => a.positionName);
            setSelectedArticles(new Set(filtered));
        } else {
            setSelectedArticles(new Set());
        }
    };

    /**
     * Handles selecting or deselecting a specific article.
     *
     * @param {string} positionName - The position name of the article.
     */
    const handleSelectArticle = (positionName) => {
        const newSet = new Set(selectedArticles);
        if (newSet.has(positionName)) newSet.delete(positionName);
        else newSet.add(positionName);
        setSelectedArticles(newSet);
    };

    /**
     * Validates the new material form.
     *
     * @returns {boolean} - Returns true if the form is valid, otherwise false.
     */
    const validateMaterialForm = () => {
        const errors = {};
        if (!newMaterial.name.trim()) errors.name = 'Name is required';
        if (!newMaterial.description.trim()) errors.description = 'Description is required';
        if (!newMaterial.thickness) errors.thickness = 'Thickness is required';
        if (!newMaterial.rawCost) errors.rawCost = 'Raw cost is required';
        setMaterialValidation(errors);
        return Object.keys(errors).length === 0;
    };

    /**
     * Handles the submission of the new material form.
     */
    const handleNewMaterialSubmit = async () => {
        if (!validateMaterialForm()) return;
        try {
            const response = await DataService.createNewMaterial(newMaterial, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Material created successfully!');
                setShowNewMaterialModal(false);
                await fetchMaterials();
                setNewMaterial({
                    name: '',
                    description: '',
                    thickness: '',
                    rawCost: '',
                    visible: '1',
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create material. Please try again.');
            console.error('Error creating new material:', error);
        }
    };

    /**
     * Validates the new edgebanding form.
     *
     * @returns {boolean} - Returns true if the form is valid, otherwise false.
     */
    const validateEdgebandingForm = () => {
        const errors = {};
        if (!newEdgebanding.imosName.trim()) errors.imosName = 'Imos name is required';
        if (!newEdgebanding.englishDescription.trim())
            errors.englishDescription = 'Description is required';
        setEdgebandingValidation(errors);
        return Object.keys(errors).length === 0;
    };

    /**
     * Handles the submission of the new edgebanding form.
     */
    const handleNewEdgebandingSubmit = async () => {
        if (!validateEdgebandingForm()) return;
        try {
            const response = await DataService.createNewEdgebanding(newEdgebanding, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Edgebanding created successfully!');
                setShowNewEdgebandingModal(false);
                await fetchEdgebandingOptions();
                setNewEdgebanding({
                    imosName: '',
                    englishDescription: '',
                    visible: '1',
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create edgebanding. Please try again.');
            console.error('Error creating new edgebanding:', error);
        }
    };

    /**
     * Filters articles based on the search filter.
     */
    const filteredArticles = articles.filter((a) =>
        a.positionName.toLowerCase().includes(searchFilter.toLowerCase())
    );

    /**
     * Determines if all filtered articles are selected.
     */
    const isAllSelected =
        filteredArticles.length > 0 &&
        filteredArticles.every((a) => selectedArticles.has(a.positionName));

    return (
        <>
            <Container fluid className="om-container">
                {showSuccessAlert && (
                    <Alert
                        variant="success"
                        onClose={() => setShowSuccessAlert(false)}
                        dismissible
                        className="mt-3 om-alert-success"
                    >
                        {alertMessage}
                    </Alert>
                )}
                {showErrorAlert && (
                    <Alert
                        variant="danger"
                        onClose={() => setShowErrorAlert(false)}
                        dismissible
                        className="mt-3 om-alert-error"
                    >
                        {alertMessage}
                    </Alert>
                )}
                {searchError && (
                    <Row>
                        <Col>
                            <Alert variant="danger" className="om-alert">
                                {searchError}
                            </Alert>
                        </Col>
                    </Row>
                )}
                {order && (
                    <>
                        {/* Order Details and Special Instructions */}
                        <Row className="mb-4 mt-2">
                            <Col md={6}>
                                <Card className="om-order-details">
                                    <Card.Header className="om-card-header">
                                        <Card.Title className="om-card-title">Order Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <Table className="om-details-table" borderless>
                                            <tbody>
                                            <tr>
                                                <td className="om-details-label">Order ID:</td>
                                                <td className="om-details-value">{order.orderId}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Customer Email:</td>
                                                <td className="om-details-value">{order.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Project Name:</td>
                                                <td className="om-details-value">{order.projectName}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Purchase Order:</td>
                                                <td className="om-details-value">{order.purchaseOrder}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Status:</td>
                                                <td className="om-details-value">{order.status}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Payment Status:</td>
                                                {/*<td className="om-details-value">{remainingBalance > 0 && order?.invoiceTxId !== null ? 'Outstanding' : 'asd'}</td>*/}
                                                {remainingBalance > 0 && order?.invoiceTxId !== null && (
                                                    <td className="om-details-value">Outstanding</td>
                                                )}
                                                {remainingBalance <= 0 && order?.invoiceTxId !== null && (
                                                    <td className="om-details-value">Paid in Full</td>
                                                )}
                                                {order?.invoiceTxId === null && (
                                                    <td className="om-details-value">No Payment</td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Date:</td>
                                                <td className="om-details-value">
                                                    {order?.date ? format(new Date(order?.date + 'T00:00:00'), 'MM/dd/yyyy') : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Apply Discount:</td>
                                                <td className="om-details-value">
                                                    <Form.Control
                                                        type="number"
                                                        value={order.discountValue || ''}
                                                        onChange={(e) =>
                                                            setOrder({...order, discountValue: e.target.value})
                                                        }
                                                        placeholder="Enter discount value"
                                                        className="mb-2 discount-inputs"
                                                    />
                                                    <Form.Select
                                                        value={order.discountType || ''}
                                                        onChange={(e) =>
                                                            setOrder({...order, discountType: e.target.value})
                                                        }
                                                        className="discount-inputs"
                                                    >
                                                        <option value="">Select Discount Type</option>
                                                        <option value="PERCENTAGE">PERCENTAGE</option>
                                                        <option value="FIXED">FIXED</option>
                                                    </Form.Select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">
                                                    <a href={`/user/customer-portal/${order.orderId}/summary`}>
                                                        View Item Details
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">
                                                    <a href={`/user/customer-portal/${order.orderId}/materials&hardware`}>
                                                        View Component List
                                                    </a>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">
                                                    <a href={`/admin/internal/${order.orderId}/check-list`}>
                                                        View Order Checklist
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="om-special-instructions">
                                    <Card.Header className="om-card-header">
                                        <Card.Title className="om-card-title">Special Instructions</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <p className="om-instructions-text">
                                            {order.specialInstructions || 'No special instructions provided'}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {/* Order Status Management */}
                        <OrderStatusManagement
                            order={order}
                            handleStatusChange={handleStatusChange}
                            handleVendorAssignment={handleVendorAssignment}
                            vendorRelationships={vendorRelationships}
                        />
                        {/* Filter and Save Button */}
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group className="om-filter-group">
                                    <Form.Label className="om-filter-label">Filter by Position Name</Form.Label>
                                    <Form.Control
                                        className="om-filter-input"
                                        type="text"
                                        placeholder="Enter Position Name"
                                        value={searchFilter}
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="text-end">
                                <Button
                                    onClick={handleSaveChanges}
                                    disabled={saving}
                                    className="om-save-btn"
                                    variant="dark"
                                >
                                    {saving ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="me-2"/>
                                            Saving Changes...
                                        </>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                        {/* Articles Section */}
                        <Row className="om-articles-section">
                            <Col>
                                <Card className="om-table-card">
                                    <Card.Header className="om-card-header">
                                        <Nav variant="tabs" className="om-nav-tabs">
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'materials' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('materials')}
                                                >
                                                    Materials
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'edgebanding' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('edgebanding')}
                                                >
                                                    Edgebanding
                                                </Nav.Link>
                                            </Nav.Item>
                                            {drawers.length > 0 && (
                                                <Nav.Item>
                                                    <Nav.Link
                                                        className={`om-nav-link ${activeTab === 'drawers' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('drawers')}
                                                    >
                                                        Drawers
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )}
                                            {doors.length > 0 && (
                                                <Nav.Item>
                                                    <Nav.Link
                                                        className={`om-nav-link ${activeTab === 'doors' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('doors')}
                                                    >
                                                        Doors
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )}
                                        </Nav>
                                    </Card.Header>
                                    <Card.Body className="om-table-wrapper">
                                        {activeTab === 'materials' && (
                                            <>
                                                <div className="mb-3">
                                                    <Button
                                                        className="om-create-material-btn"
                                                        variant="dark"
                                                        onClick={() => setShowNewMaterialModal(true)}
                                                    >
                                                        Create New Material
                                                    </Button>
                                                </div>
                                                <MaterialsTable
                                                    articles={filteredArticles}
                                                    materials={materials}
                                                    selectedArticles={selectedArticles}
                                                    handleSelectAll={handleSelectAll}
                                                    handleSelectArticle={handleSelectArticle}
                                                    isAllSelected={isAllSelected}
                                                    handleMaterialChange={handleMaterialChange}
                                                    handleBulkMaterialChange={handleBulkMaterialChange}
                                                    imageExistsMap={imageExistsMap}
                                                    orderId={orderId}
                                                    thumbNail={thumbNail}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'edgebanding' && (
                                            <>
                                                <div className="mb-3">
                                                    <Button
                                                        className="om-create-edgebanding-btn"
                                                        variant="dark"
                                                        onClick={() => setShowNewEdgebandingModal(true)}
                                                    >
                                                        Create New Edgebanding
                                                    </Button>
                                                </div>
                                                <EdgebandingTable
                                                    articles={filteredArticles}
                                                    edgebandingOptions={edgebandingOptions}
                                                    selectedArticles={selectedArticles}
                                                    handleSelectAll={handleSelectAll}
                                                    handleSelectArticle={handleSelectArticle}
                                                    isAllSelected={isAllSelected}
                                                    handleEdgebandingChange={handleEdgebandingChange}
                                                    handleBulkEdgebandingChange={handleBulkEdgebandingChange}
                                                    imageExistsMap={imageExistsMap}
                                                    orderId={orderId}
                                                    thumbNail={thumbNail}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'drawers' && (
                                            <DrawersTable
                                                drawers={drawers}
                                                order={order}
                                                handleDrawerFieldChange={handleDrawerFieldChange}
                                            />
                                        )}
                                        {activeTab === 'doors' && (
                                            <DoorsTable doors={doors} handleDoorFieldChange={handleDoorFieldChange}/>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
                {/* Modals */}
                <NewMaterialModal
                    show={showNewMaterialModal}
                    onHide={() => setShowNewMaterialModal(false)}
                    newMaterial={newMaterial}
                    setNewMaterial={setNewMaterial}
                    handleNewMaterialSubmit={handleNewMaterialSubmit}
                    materialValidation={materialValidation}
                />
                <NewEdgebandingModal
                    show={showNewEdgebandingModal}
                    onHide={() => setShowNewEdgebandingModal(false)}
                    newEdgebanding={newEdgebanding}
                    setNewEdgebanding={setNewEdgebanding}
                    handleNewEdgebandingSubmit={handleNewEdgebandingSubmit}
                    edgebandingValidation={edgebandingValidation}
                />
            </Container>
        </>
    );
};

export default OrderDetailPage;

// // src/components/OrderSummary/OrderSummaryComponent.js
// import React, {useRef, useState} from 'react';
// import {useNavigate, useParams} from 'react-router-dom';
// import {useSelector} from "react-redux";
// import {UserLoggedIn} from '../../../hooks/isUserLoggedIn';
// import {useOrderSummary} from './hooks/useOrderSummary';
// import {generatePDF} from './utils/pdfGenerator';
// import {ArticleCard} from './ArticleCard';
// import './OrderSummaryStyles.css';
//
// const OrderSummaryComponent = () => {
//     const {orderId} = useParams();
//     const userLoggedIn = UserLoggedIn();
//     const token = useSelector(state => state.user.accessToken);
//     const navigate = useNavigate();
//     const orderSummaryContainerRef = useRef(null);
//     const downloadButtonRef = useRef(null);
//     const [downloading, setDownloading] = useState(false);
//
//     const baseURL = process.env.REACT_APP_BASEURL;
//     const imageURL = process.env.REACT_APP_IMAGEURL;
//     const thumbNail = process.env.REACT_APP_THMBNAIL;
//     const orderDWG = process.env.REACT_APP_THMBNAIL + `${orderId}/` + 'Article_Report/' + `${orderId}.DWG`;
//
//     const {order, articles, imageExistsMap} = useOrderSummary(orderId, token, baseURL);
//
//     const handleArticleClick = (positionName) => {
//         const article = articles.find(article => article.positionName === positionName);
//         navigate(`/api/cabinet/quality-control/${orderId}/${positionName}`, {state: {article, order}});
//     };
//
//     const downloadPDF = async () => {
//         setDownloading(true);
//         if (!order) return;
//
//         const pdf = await generatePDF(articles, order, imageExistsMap, imageURL, thumbNail);
//         pdf.save(`order-${order.orderId}.pdf`);
//         setDownloading(false);
//     };
//
//     const downloadOrderDWG = async () => {
//         try {
//             const response = await fetch(orderDWG);
//             if (!response.ok) {
//                 throw new Error(`Failed to fetch file: ${response.statusText}`);
//             }
//
//             const blob = await response.blob();
//             const link = document.createElement('a');
//             const url = URL.createObjectURL(blob);
//             link.href = url;
//             link.download = `${orderId}.DWG`;
//
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//
//             URL.revokeObjectURL(url);
//         } catch (error) {
//             console.error('Error downloading file:', error);
//         }
//     };
//
//     return (
//         <div className="qsc-order-summary-page qsc-min-vh-100">
//             <div className="qsc-order-summary-container" id="qsc-order-summary-container"
//                  ref={orderSummaryContainerRef}>
//                 <div className="qsc-main-container">
//                     <div className="qsc-header-container">
//                         <h2 className="qsc-text-center qsc-mb-4">Item Details</h2>
//                         <div className="qsc-test-container">
//                             <button
//                                 ref={downloadButtonRef}
//                                 onClick={downloadPDF}
//                                 className="qsc-download-pdf-button"
//                                 disabled={downloading}
//                             >
//                                 {downloading ? 'Downloading...' : 'Download PDF'}
//                             </button>
//                             <button
//                                 className="qsc-download-dwg-button"
//                                 onClick={downloadOrderDWG}
//                             >
//                                 Download Order DWG
//                             </button>
//                         </div>
//                     </div>
//
//                     {order && (
//                         <>
//                             <div className="qsc-order-details-card">
//                                 <div className="qsc-order-details">
//                                     <h4>Order Details</h4>
//                                     <p><strong>Order ID:</strong> {order.orderId}</p>
//                                     <p><strong>Status:</strong> {order.status}</p>
//                                     <p><strong>Date:</strong> {order.date}</p>
//                                 </div>
//                             </div>
//                             <div className="qsc-articles">
//                                 <h4>Articles</h4>
//                                 <div className="qsc-row">
//                                     {articles.map((article, index) => (
//                                         <ArticleCard
//                                             key={article.articleId}
//                                             article={article}
//                                             index={index}
//                                             imageExistsMap={imageExistsMap}
//                                             thumbNail={thumbNail}
//                                             imageURL={imageURL}
//                                             onClick={handleArticleClick}
//                                         />
//                                     ))}
//                                 </div>
//                             </div>
//                         </>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default OrderSummaryComponent;


import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {useOrderSummary} from './hooks/useOrderSummary';
import {generatePDF} from './utils/pdfGenerator';
import {generateExcel} from './utils/excelGenerator';
import ArticleCard from './ArticleCard';
import {Search} from 'lucide-react';
import './OrderSummaryStyles.css';

const OrderSummaryComponent = () => {
    const {orderId} = useParams();
    const token = useSelector((state) => state.user.accessToken);
    const navigate = useNavigate();

    // State management
    const [downloading, setDownloading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // Environment variables
    const baseURL = process.env.REACT_APP_BASEURL;
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;
    const orderDWG = process.env.REACT_APP_THMBNAIL + `${orderId}/Article_Report/${orderId}.DWG`;

    // Fetch order data
    const {order, articles, imageExistsMap} = useOrderSummary(orderId, token, baseURL);

    // Filter articles based on search term
    const filteredArticles = articles.filter(article =>
        (article.positionName &&
            article.positionName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (article.serialNumber &&
            article.serialNumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Sort by positionName
    const sortedArticles = [...filteredArticles].sort((a, b) =>
        (a.positionName || "").localeCompare(b.positionName || "")
    );

    const handleArticleClick = (positionName) => {
        const article = articles.find(a => a.positionName === positionName);
        navigate(`/api/cabinet/quality-control/${orderId}/${positionName}`, {
            state: {article, order},
        });
    };

    const downloadPDF = async () => {
        setDownloading(true);
        if (!order) return;
        const pdf = await generatePDF(articles, order, imageExistsMap, imageURL, thumbNail);
        pdf.save(`order-${order.orderId}.pdf`);
        setDownloading(false);
    };

    const downloadExcel = async () => {
        if (!order || !articles.length) return;

        const blob = generateExcel(articles, order);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `order-${order.orderId}-summary.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const downloadOrderDWG = async () => {
        try {
            const response = await fetch(orderDWG);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${orderId}.DWG`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className="os-page">
            <div className="container os-container">
                <div className="os-main">
                    {/* Header */}
                    <header className="os-header">
                        <div className="os-header-info">
                            <h1 className="os-title">Order Summary</h1>
                            {order && <p className="os-order-id">Order ID: {order.orderId}</p>}
                        </div>
                        <div className="os-header-actions">
                            <button onClick={downloadPDF} className="os-btn" disabled={downloading}>
                                {downloading ? 'Downloading...' : 'Download PDF'}
                            </button>
                            <button onClick={downloadExcel} className="os-btn">
                                Download Excel
                            </button>
                            <button onClick={downloadOrderDWG} className="os-btn">
                                Download DWG
                            </button>
                        </div>
                    </header>

                    {/* Order Details */}
                    {order && (
                        <div className="os-order-details-card">
                            <h2 className="os-section-title">Order Details</h2>
                            <p><strong>Order ID:</strong> {order.orderId}</p>
                            <p><strong>Status:</strong> {order.status}</p>
                            <p><strong>Date:</strong> {order.date}</p>
                        </div>
                    )}

                    {/* Filter Section */}
                    <div className="os-filter">
                        <div className="os-filter-row">
                            <div className="os-filter-info">
                                <h2 className="os-section-title">Items</h2>
                                <p>{sortedArticles.length} items found</p>
                            </div>
                            <div className="os-filter-search">
                                <div className="os-search-group">
                                    <span className="os-search-icon">
                                        <Search size={18}/>
                                    </span>
                                    <input
                                        type="text"
                                        className="os-search-input"
                                        placeholder="Search by name or serial number..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Articles Grid */}
                    <div className="os-articles row">
                        {sortedArticles.length === 0 ? (
                            <div className="os-no-articles">
                                <p>No articles match your search.</p>
                            </div>
                        ) : (
                            sortedArticles.map((article, index) => (
                                <div key={article.articleId || index} className="col-md-6 col-lg-4">
                                    <ArticleCard
                                        article={article}
                                        imageExistsMap={imageExistsMap}
                                        thumbNail={thumbNail}
                                        imageURL={imageURL}
                                        onClick={handleArticleClick}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSummaryComponent;
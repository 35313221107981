import React, {useState} from 'react';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import './ImageCarouselStyles.css';
import './DocImageStyles.css'
import {Helmet} from "react-helmet-async";

const DocImageCarousel = ({orderId, positionName}) => {
    const imageURL = `${process.env.REACT_APP_THMBNAIL}${orderId}/${positionName}`
    const thumbNail = `${imageURL}.png`;
    const frontND = `${imageURL}_Front_ND.png`;
    const dimFront = `${imageURL}_Dim_Front.png`;
    const dimLeft = `${imageURL}_Dim_Left.png`;
    const dimTop = `${imageURL}_Dim_Top.png`;
    const images = [thumbNail, frontND, dimFront, dimTop, dimLeft]


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="doc-image-carousel-container">
            <Helmet>
                <link rel="preload" as="image" href={thumbNail}/>
                <link rel="preload" as="image" href={frontND}/>
                <link rel="preload" as="image" href={dimFront}/>
                <link rel="preload" as="image" href={dimLeft}/>
                <link rel="preload" as="image" href={dimTop}/>
            </Helmet>
            <div className="doc-image-container">
                <img
                    src={images[currentImageIndex]}
                    className="card-img-top sr-cstm-img"
                    alt={`${orderId}/${positionName}`}
                    width="300"
                    height="400"
                    loading="eager"
                />
            </div>
            <div className="doc-image-carousel-controls">
                <FaArrowLeft onClick={handlePrevClick} className="carousel-arrow left-arrow"/>
                <FaArrowRight onClick={handleNextClick} className="carousel-arrow right-arrow"/>
            </div>
        </div>
    );
};

export default DocImageCarousel;




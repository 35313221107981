import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {setSuggestedArticles} from '../suggestedArticles/suggestedArticlesSlice';

export const sendMessage = createAsyncThunk(
    'chatBot/sendMessage',
    async ({message}, {dispatch, rejectWithValue}) => {
        try {
            const userMessage = {sender: 'user', text: message};

            const response = await axios.post('http://localhost:8000/chat', {
                sender: 'user1',
                message: message,
            });

            if (!response.data.responses || response.data.responses.length === 0) {
                return {
                    userMessage,
                    botMessage: {
                        sender: 'bot',
                        text: "I'm having trouble understanding. Please try again."
                    }
                };
            }

            const botResponse = response.data.responses[0];
            console.log('Raw bot response:', botResponse);

            let botMessage;

            // Check for materials/edge banding (custom.custom.list structure)
            if (botResponse?.custom?.custom?.list) {
                console.log('Found list in custom.custom:', botResponse.custom.custom.list);
                botMessage = {
                    sender: 'bot',
                    text: botResponse.text || "Here are the options:",
                    custom: {
                        list: botResponse.custom.custom.list
                    }
                };
            }
            // Check for catalog search response (custom.custom with dimensions, etc.)
            else if (botResponse?.custom?.custom) {
                const {descriptions, dimensions, serial_numbers, full_data} = botResponse.custom.custom;

                if (full_data && Array.isArray(full_data)) {
                    dispatch(setSuggestedArticles({
                        articles: full_data,
                        dimensions: dimensions || {}
                    }));
                }

                botMessage = {
                    sender: 'bot',
                    text: 'Here are the matching cabinets:',
                    items: serial_numbers?.map((serial, index) => ({
                        serial_number: serial,
                        description: descriptions?.[index] || ''
                    })) || [],
                    dimensions: dimensions || {}
                };
            }
            // Default text response
            else {
                botMessage = {
                    sender: 'bot',
                    text: botResponse.text || "I'm not sure how to respond to that."
                };
            }

            console.log('Final bot message:', botMessage);
            return {userMessage, botMessage};

        } catch (error) {
            console.error('Chat error:', error);
            return rejectWithValue('Error connecting to the chatbot.');
        }
    }
);
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteSavedOrder, getAllSavedOrders} from '../../api/OrderService';
import PdfWriterComponent from '../PdfWriterComponent/PdfWriterComponent';
import './SavedOrdersStyles.css';
import {Helmet} from "react-helmet-async";
import {nanoid} from "@reduxjs/toolkit";
import {reAddToCart} from "../../features/cart/cartSlice";

const SavedOrdersComponent = () => {
    const [savedOrders, setSavedOrders] = useState(null);
    const [shouldFetchOrders, setShouldFetchOrders] = useState(true);
    const email = useSelector((state) => state.user.user.email);
    const token = useSelector((state) => state.user.accessToken);
    const dispatch = useDispatch();

    const imageURL = process.env.REACT_APP_IMAGEURL;

    useEffect(() => {
        if (shouldFetchOrders) {
            const fetchSavedOrders = async () => {
                try {
                    const response = await getAllSavedOrders(email, token);
                    // const response = await fetchSavedOrders(email, token);
                    setSavedOrders(response.data);
                    setShouldFetchOrders(false);
                } catch (e) {
                    console.error('Error fetching saved orders: ', e);
                    setSavedOrders([]);
                }

            };
            fetchSavedOrders();
        }
    }, [shouldFetchOrders]);

    const handleOrderAgain = (tempOrderId) => {
        const ordersToAdd = savedOrders.filter(
            (order) => order.tempOrderId.toString() === tempOrderId.toString()
        );
        ordersToAdd.forEach((order) => {
            dispatch(reAddToCart({
                ...order,
                itemId: nanoid(),
                orderId: tempOrderId,
                generatePos: false,
                quantity: order.quantity
            }));
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const handleDeleteSavedOrder = async (tempOrderId) => {
        try {
            const response = await deleteSavedOrder(tempOrderId, token);
            if (response.status === 200) {
                setSavedOrders((prevOrders) =>
                    prevOrders.filter((order) => order.tempOrderId !== tempOrderId)
                );
                setShouldFetchOrders(true);
            } else {
                console.error('Failed to delete saved order:', response.data);
            }
        } catch (error) {
            console.error('Error deleting saved order:', error);
        }
    };

    const groupedOrders = savedOrders?.reduce((acc, order) => {
        const groupId = order.tempOrderId;
        if (!acc[groupId]) {
            acc[groupId] = [];
        }
        acc[groupId].push(order);
        return acc;
    }, {});

    return (
        <div className="saved-orders-page">
            <Helmet>
                <title>Saved Orders | The Sealab</title>
                <meta
                    name="description"
                    content="View and manage your saved cabinet orders on The Sealab. Reorder your favorite designs or delete saved orders effortlessly."
                />
                <meta
                    name="keywords"
                    content="saved orders, cabinet orders, reorder cabinets, manage cabinet orders, The Sealab saved orders, kitchen cabinets, closet cabinets"
                />
                <meta property="og:title" content="Saved Orders | The Sealab"/>
                <meta
                    property="og:description"
                    content="Easily view and manage your saved cabinet orders on The Sealab. Reorder or delete saved orders quickly."
                />
                <meta
                    property="og:url"
                    content="https://www.thesealab.com/user/profile/saved-orders"
                />
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="The Sealab"/>
            </Helmet>
            <div className="container-wide my-4 min-vh-100">
                {savedOrders === null ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading your orders...</p>
                    </div>
                ) : savedOrders?.length === 0 ? (
                    <div className="empty-state">
                        <h5>You do not have any saved orders at this time.</h5>
                        <p>Your saved orders will appear here once you create them.</p>
                    </div>
                ) : (
                    Object.entries(groupedOrders).map(([tempOrderId, orders]) => (
                        <div key={tempOrderId} className="saved-order-container">
                            <div className="saved-order-header">
                                <div className="saved-order-header-content">
                                    <h5 className="saved-order-id">Order ID: {tempOrderId}</h5>
                                    <div className="pdf-button-wrapper">
                                        <PdfWriterComponent orderDetails={orders} imageURL={imageURL}/>
                                    </div>
                                </div>
                            </div>
                            <div className="saved-order-items">
                                {orders.map((order, index) => (
                                    <div key={index} className="saved-order-item">
                                        <div className="saved-order-card">
                                            <div className="saved-order-image-wrapper">
                                                <div className="saved-order-image">
                                                    <img
                                                        src={`${imageURL}${order.serialNumber}.webp`}
                                                        alt={order.positionName}
                                                        className="saved-order-item-img"
                                                    />
                                                </div>
                                            </div>
                                            <div className="saved-order-details">
                                                <div className="detail-row">
                                                    <span className="detail-label">Item:</span>
                                                    <span className="detail-value">{order.positionName}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Serial:</span>
                                                    <span className="detail-value">{order.serialNumber}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Dimensions:</span>
                                                    <span className="detail-value">
                                                        {order.height} x {order.width} x {order.depth} in
                                                    </span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Front Material:</span>
                                                    <span className="detail-value">{order.frontMaterial}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Case Material:</span>
                                                    <span className="detail-value">{order.caseMaterial}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Quantity:</span>
                                                    <span className="detail-value">{order.quantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="saved-order-footer">
                                <button
                                    className="btn-order-again"
                                    onClick={() => handleOrderAgain(tempOrderId)}
                                >
                                    Order Again
                                </button>
                                <button
                                    className="btn-delete"
                                    onClick={() => handleDeleteSavedOrder(tempOrderId)}
                                >
                                    Delete Order
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SavedOrdersComponent;
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import sealabIcon from '../../../images/sealab_icon.png'; // Import the company icon
//
// /**
//  * Exports the purchase order as a PDF.
//  * It captures the element with the given id, adds a professional header
//  * and then saves the PDF.
//  *
//  * @param {string} elementId - The id of the element to capture.
//  */
// export const exportPOToPDF = async (elementId) => {
//     const input = document.getElementById(elementId);
//     if (!input) {
//         console.error("Element not found: ", elementId);
//         return;
//     }
//
//     // Create a new jsPDF instance
//     const pdf = new jsPDF('p', 'pt', 'a4');
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();
//
//     // Define header dimensions
//     const headerHeight = 80;
//     const margin = 20;
//
//     // Draw a header background
//     pdf.setFillColor(255, 255, 255);
//     pdf.rect(0, 0, pdfWidth, headerHeight, 'F');
//
//     // Add the company icon on the top left
//     const imgWidth = 60;
//     const imgHeight = 60;
//     pdf.addImage(sealabIcon, 'PNG', margin, (headerHeight - imgHeight) / 2, imgWidth, imgHeight);
//
//     // Add company info on the top right
//     const companyInfo = [
//         "The Sealab",
//         "info@thesealab.com",
//         "63 Flushing Ave",
//         "Building 3, Suite 1108",
//         "Brooklyn, NY 11205"
//     ];
//     pdf.setFontSize(10);
//     const infoX = pdfWidth - margin - 150; // Adjust as needed
//     let infoY = 20;
//     companyInfo.forEach(line => {
//         pdf.text(line, infoX, infoY);
//         infoY += 12;
//     });
//
//     // Capture the PO content below the header using html2canvas
//     const canvas = await html2canvas(input, {scale: 1});
//     const imgData = canvas.toDataURL('image/png');
//     const imgProps = pdf.getImageProperties(imgData);
//     // Calculate width and height for the image in the PDF
//     const contentWidth = pdfWidth - margin * 2;
//     const contentHeight = (imgProps.height * contentWidth) / imgProps.width;
//
//     // Add the captured image below the header with some top margin
//     pdf.addImage(imgData, 'PNG', margin, headerHeight + margin, contentWidth, contentHeight);
//
//     // Save the PDF
//     pdf.save('PurchaseOrder.pdf');
// };


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import sealabIcon from '../../../images/sealab_icon.png';

/**
 * Exports the purchase order as a PDF.
 * Fixes overflow issues by calculating proper dimensions and scaling.
 *
 * @param {string} elementId - The id of the element to capture.
 */
export const exportPOToPDF = async (elementId) => {
    // Get the target element
    const element = document.getElementById(elementId);
    if (!element) {
        console.error(`Element with id "${elementId}" not found.`);
        return;
    }

    // Get the actual width of the element including any overflow
    const actualWidth = Math.max(
        element.scrollWidth,
        element.offsetWidth,
        element.clientWidth
    );

    // Temporarily hide elements that should not be exported
    const noExportElements = element.querySelectorAll('.no-export');
    const originalDisplays = [];
    noExportElements.forEach(el => {
        originalDisplays.push(el.style.display);
        el.style.display = 'none';
    });

    // Wait briefly to ensure the styles update
    await new Promise(resolve => setTimeout(resolve, 100));

    // Capture the element at high resolution
    const canvas = await html2canvas(element, {
        scale: 3,
        useCORS: true,
        width: actualWidth, // Set explicit width
        windowWidth: actualWidth // Ensure proper rendering width
    });

    // Restore the display styles for the no-export elements
    noExportElements.forEach((el, index) => {
        el.style.display = originalDisplays[index];
    });

    // Conversion factors and constants
    const pxToPt = 0.75;
    const scaleFactor = 3;
    const margin = 20;
    const headerHeight = 80;

    // Convert canvas dimensions to points, accounting for scale
    const drawnWidth = (canvas.width / scaleFactor) * pxToPt;
    const drawnHeight = (canvas.height / scaleFactor) * pxToPt;

    // Calculate page dimensions with proper margins
    const minPageWidth = 800;
    const contentWidth = drawnWidth + (margin * 2);
    const pageWidth = Math.max(minPageWidth, contentWidth);
    const pageHeight = headerHeight + (margin * 2) + drawnHeight;

    // Create PDF with calculated dimensions
    const pdf = new jsPDF({
        orientation: pageWidth > pageHeight ? 'l' : 'p',
        unit: 'pt',
        format: [pageWidth, pageHeight]
    });

    // Draw header background
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pageWidth, headerHeight, 'F');

    // Add company icon
    const iconWidth = 60;
    const iconHeight = 60;
    pdf.addImage(sealabIcon, 'PNG', margin, (headerHeight - iconHeight) / 2, iconWidth, iconHeight);

    // Add company info
    const companyInfo = [
        "The Sealab",
        "info@thesealab.com",
        "63 Flushing Ave",
        "Building 3, Suite 1108",
        "Brooklyn, NY 11205"
    ];
    pdf.setFontSize(10);
    const infoX = pageWidth - margin - 150;
    let infoY = 20;
    companyInfo.forEach(line => {
        pdf.text(line, infoX, infoY);
        infoY += 12;
    });

    // Center the content horizontally if page is wider than content
    const xPosition = (pageWidth - drawnWidth) / 2;

    // Convert canvas to image and add to PDF
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', xPosition, headerHeight + margin, drawnWidth, drawnHeight);

    // Save the PDF
    pdf.save('PurchaseOrder.pdf');
};
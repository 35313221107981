// components/OrderMatHardware/HardwareTable.js

import React from 'react';

/**
 * HardwareTable Component
 *
 * This component renders a table displaying hardware items and their quantities.
 *
 * @param {Object} hardwareQuantity - Object containing hardware names as keys and their quantities as values.
 * @param {number} hardwareQuantity[hardware] - The quantity of a specific hardware item.
 *
 * @returns {JSX.Element | null} - The rendered table showing hardware data or null if no data exists.
 */
const HardwareTable = ({hardwareQuantity}) => {
    // If hardwareQuantity is null or empty, return null to avoid rendering the table
    if (!hardwareQuantity || Object.keys(hardwareQuantity).length === 0) return null;

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Hardware</h4> {/* Table title */}
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Hardware</th>
                    {/* Column header for hardware names */}
                    <th>QTY</th>
                    {/* Column header for hardware quantities */}
                </tr>
                </thead>
                <tbody>
                {Object.entries(hardwareQuantity)
                    .filter(([hardware]) => hardware !== "Placeholder") // Filter out 'Placeholder'
                    .map(([hardware, quantity]) => (
                        <tr key={hardware}>
                            <td>{hardware}</td>
                            <td>{quantity}</td>
                        </tr>
                    ))
                }

                </tbody>
            </table>
        </div>
    );
};

export default HardwareTable;

// import React, {useEffect, useRef, useState} from 'react';
// import {Button, Card, Col, Container, Form, Modal, Row, Table} from 'react-bootstrap';
// import {FaBalanceScale, FaChartLine, FaMoneyBillWave} from 'react-icons/fa';
// import axios from 'axios';
// import {jsPDF} from 'jspdf';
// import html2canvas from 'html2canvas';
// import './FinancesStyles.css';
//
// const formatCurrency = (amount) => {
//     return new Intl.NumberFormat('en-US', {
//         style: 'currency',
//         currency: 'USD',
//     }).format(amount);
// };
//
// const monthNames = [
//     "January", "February", "March", "April", "May", "June",
//     "July", "August", "September", "October", "November", "December"
// ];
//
// const Finances = () => {
//     // Default year is the current year as string.
//     const currentYear = new Date().getFullYear().toString();
//     const [searchQuery, setSearchQuery] = useState('');
//     const [transactions, setTransactions] = useState([]);
//     // displayedTransactions are used for client‐side search filtering.
//     const [displayedTransactions, setDisplayedTransactions] = useState([]);
//     const [yearFilter, setYearFilter] = useState(currentYear);
//     const [monthFilter, setMonthFilter] = useState('');
//     const [loading, setLoading] = useState(true);
//     const [financialStats, setFinancialStats] = useState({
//         totalRevenue: 0,
//         totalEstimatedCost: 0,
//         totalEstimatedProfit: 0,
//         totalActualCost: 0,
//         actualGrossProfit: 0,
//         salesTax: 0,
//         totalOverdue: 0,
//     });
//     const [filteredStats, setFilteredStats] = useState({
//         totalRevenue: 0,
//         totalEstimatedCost: 0,
//         totalEstimatedProfit: 0,
//         totalActualCost: 0,
//         totalOverdue: 0,
//         actualGrossProfit: 0,
//         salesTax: 0,
//     });
//     const [processingRefund, setProcessingRefund] = useState(false);
//     const [showRefundModal, setShowRefundModal] = useState(false);
//     const [selectedTransaction, setSelectedTransaction] = useState(null);
//     const [refundAmount, setRefundAmount] = useState('');
//     const [refundError, setRefundError] = useState('');
//     // Arrays from backend
//     const [orderActualCost, setOrderActualCost] = useState([]);
//     const [invoices, setInvoices] = useState([]);
//     // Mapping of estimated cost per order (key = orderId)
//     const [estimatedCostPerOrder, setEstimatedCostPerOrder] = useState({});
//     const baseURL = process.env.REACT_APP_BASEURL;
//
//     // Reference for PDF export
//     const tableRef = useRef(null);
//
//     // Re-fetch transactions whenever the year or month filter changes.
//     useEffect(() => {
//         fetchTransactions();
//     }, [yearFilter, monthFilter]);
//
//     // Apply client-side search filtering.
//     useEffect(() => {
//         const searchLower = searchQuery.toLowerCase();
//         const filtered = transactions.filter((tx) => {
//             return (
//                 tx.orderId?.toLowerCase().includes(searchLower) ||
//                 tx.email?.toLowerCase().includes(searchLower) ||
//                 tx.processingTxId?.toLowerCase().includes(searchLower) ||
//                 tx.invoiceTxId?.toLowerCase().includes(searchLower)
//             );
//         });
//         setDisplayedTransactions(filtered);
//     }, [searchQuery, transactions]);
//
//     // Recalculate filtered stats (based on displayedTransactions)
//     useEffect(() => {
//         const totalRevenue = displayedTransactions.reduce(
//             (acc, tx) => acc + ((tx.invoiceAmount || 0) + (tx.processingFee || 0)),
//             0
//         );
//         const totalSalesPrice = displayedTransactions.reduce(
//             (acc, tx) => acc + (tx.invoiceAmount || 0),
//             0
//         );
//         const salesTax = displayedTransactions.reduce(
//             (acc, tx) => acc + (tx.salesTax || 0),
//             0
//         );
//         // For filtered stats, we calculate total overdue as the sum of each transaction’s remainingBalance.
//         const filteredTotalOverdue = displayedTransactions.reduce(
//             (acc, tx) => acc + (tx.remainingBalance || 0),
//             0
//         );
//
//         // Use matching orderIds to filter invoices and order vendors
//         const displayedOrderIds = new Set(
//             displayedTransactions.map((tx) => String(tx.orderId).trim())
//         );
//         const filteredInvoices = invoices.filter((inv) =>
//             displayedOrderIds.has(String(inv.orderId).trim())
//         );
//         const filteredOrderVendors = orderActualCost.filter((ov) =>
//             displayedOrderIds.has(String(ov.orderId).trim())
//         );
//
//         const totalEstimatedCost = filteredInvoices.reduce(
//             (acc, inv) =>
//                 acc + (inv.getNetCost ? inv.getNetCost() : (inv.netCost || 0)),
//             0
//         );
//         const totalEstimatedProfit = filteredInvoices.reduce(
//             (acc, inv) =>
//                 acc + (inv.getNetProfit ? inv.getNetProfit() : (inv.netProfit || 0)),
//             0
//         );
//         const totalActualCost = filteredOrderVendors.reduce(
//             (acc, ov) =>
//                 acc + (ov.getAmountOwed ? ov.getAmountOwed() : (ov.amountOwed || 0)),
//             0
//         );
//         const actualGrossProfit = totalSalesPrice - totalActualCost;
//
//         setFilteredStats({
//             totalRevenue: formatCurrency(totalRevenue),
//             totalEstimatedCost: formatCurrency(totalEstimatedCost),
//             totalEstimatedProfit: formatCurrency(totalEstimatedProfit),
//             totalActualCost: formatCurrency(totalActualCost),
//             totalOverdue: formatCurrency(filteredTotalOverdue),
//             actualGrossProfit: formatCurrency(actualGrossProfit),
//             salesTax: formatCurrency(salesTax),
//         });
//     }, [displayedTransactions, invoices, orderActualCost]);
//
//     const fetchTransactions = async () => {
//         try {
//             setLoading(true);
//             // Build query parameters based on filters.
//             const params = {};
//             if (yearFilter) {
//                 params.year = yearFilter;
//                 if (monthFilter) {
//                     params.month = monthFilter;
//                 }
//             }
//             const response = await axios.get(`${baseURL}/admin/internal/finances/transactions`, {params});
//             setTransactions(response.data.transactions);
//             setOrderActualCost(response.data.orderActualCost || []);
//             setInvoices(response.data.invoices || []);
//             setEstimatedCostPerOrder(response.data.estimatedCostPerOrder || {});
//             calculateFinancialStats(response.data);
//         } catch (error) {
//             console.error('Error fetching transactions:', error);
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     // Calculate overall financial stats from the API response.
//     const calculateFinancialStats = (data) => {
//         const totalRevenue = data.transactions.reduce(
//             (acc, tx) => acc + ((tx.invoiceAmount || 0) + (tx.processingFee || 0)),
//             0
//         );
//         setFinancialStats({
//             totalRevenue: formatCurrency(totalRevenue),
//             totalEstimatedCost: formatCurrency(data.totalEstimatedCost || 0),
//             totalEstimatedProfit: formatCurrency(data.totalEstimatedProfit || 0),
//             totalActualCost: formatCurrency(data.totalActualCost || 0),
//             actualGrossProfit: formatCurrency(data.actualGrossProfit || 0),
//             salesTax: formatCurrency(
//                 data.transactions.reduce((acc, tx) => acc + (tx.salesTax || 0), 0)
//             ),
//             totalOverdue: formatCurrency(data.totalOverdue || 0),
//         });
//     };
//
//     const handleSearchChange = (e) => {
//         setSearchQuery(e.target.value);
//     };
//
//     const handleYearChange = (e) => {
//         setYearFilter(e.target.value);
//         if (!e.target.value) {
//             setMonthFilter('');
//         }
//     };
//
//     const handleMonthChange = (e) => {
//         setMonthFilter(e.target.value);
//     };
//
//     const openRefundModal = (transaction) => {
//         if (!transaction.invoiceTxId) {
//             alert('No invoice transaction ID found for this transaction');
//             return;
//         }
//         setSelectedTransaction(transaction);
//         const totalAmount = (transaction.invoiceAmount || 0) + (transaction.salesTax || 0);
//         setRefundAmount(totalAmount.toFixed(2));
//         setRefundError('');
//         setShowRefundModal(true);
//     };
//
//     const handleRefundAmountChange = (e) => {
//         const value = e.target.value;
//         setRefundAmount(value);
//         if (!selectedTransaction) return;
//         const numValue = parseFloat(value);
//         const maxAmount = (selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0);
//         if (numValue <= 0) {
//             setRefundError('Refund amount must be greater than 0');
//         } else if (numValue > maxAmount) {
//             setRefundError(`Refund amount cannot exceed ${formatCurrency(maxAmount)}`);
//         } else {
//             setRefundError('');
//         }
//     };
//
//     const handleRefund = async () => {
//         if (!selectedTransaction || !selectedTransaction.invoiceTxId) {
//             alert('No invoice transaction ID found for this transaction');
//             return;
//         }
//         const numAmount = parseFloat(refundAmount);
//         const maxAmount = (selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0);
//         if (numAmount <= 0 || numAmount > maxAmount) {
//             setRefundError(`Refund amount must be between $0.01 and ${formatCurrency(maxAmount)}`);
//             return;
//         }
//         if (window.confirm(`Are you sure you want to refund ${formatCurrency(numAmount)}?`)) {
//             try {
//                 setProcessingRefund(true);
//                 const response = await axios.post('http://localhost:8080/api/stripe/refund', {
//                     paymentIntentId: selectedTransaction.invoiceTxId,
//                     orderId: selectedTransaction.orderId,
//                     amount: Math.round(numAmount * 100)
//                 });
//                 if (response.status === 200) {
//                     alert('Refund processed successfully');
//                     setShowRefundModal(false);
//                     setSelectedTransaction(null);
//                     setRefundAmount('');
//                     await fetchTransactions();
//                 } else {
//                     alert('Error processing refund: ' + response.data);
//                 }
//             } catch (error) {
//                 console.error('Error processing refund:', error);
//                 alert('Error processing refund: ' + (error.response?.data || error.message));
//             } finally {
//                 setProcessingRefund(false);
//             }
//         }
//     };
//
//     // Export the table and header info as a PDF.
//     const exportToPDF = () => {
//         if (!tableRef.current) return;
//         html2canvas(tableRef.current, {scale: 3}).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             const pdf = new jsPDF('l', 'mm', 'a4');
//             const pdfWidth = pdf.internal.pageSize.getWidth();
//             const margin = 10;
//             const imgWidth = pdfWidth - 2 * margin;
//             const imgProps = pdf.getImageProperties(imgData);
//             const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
//
//             let headerText = 'Transactions Report';
//             if (yearFilter) {
//                 headerText += ` - Year: ${yearFilter}`;
//                 if (monthFilter) {
//                     const monthName = monthNames[parseInt(monthFilter, 10) - 1] || monthFilter;
//                     headerText += `, Month: ${monthName}`;
//                 }
//             } else {
//                 headerText += ' - All Years';
//             }
//             pdf.setFontSize(16);
//             pdf.text(headerText, pdfWidth / 2, 15, {align: 'center'});
//             pdf.addImage(imgData, 'PNG', margin, 25, imgWidth, imgHeight);
//             pdf.save(`Transactions_${yearFilter || 'All'}_${monthFilter ? monthNames[parseInt(monthFilter, 10) - 1] : 'All'}.pdf`);
//         });
//     };
//
//     return (
//         <div className="sealab-finances">
//             <Container fluid className="py-4">
//                 {/* Header */}
//                 <Row className="mb-4">
//                     <Col>
//                         <h1 className="sealab-finances-title">Sealab Finances</h1>
//                         <p className="sealab-finances-subtitle">Financial Management Dashboard</p>
//                     </Col>
//                 </Row>
//
//                 {/* Filters and Search */}
//                 <Row className="mb-4">
//                     <Col md={4} lg={3}>
//                         <Form.Group className="sealab-finances-control">
//                             <Form.Label>Year</Form.Label>
//                             <Form.Control
//                                 type="number"
//                                 placeholder="Enter year (e.g., 2025)"
//                                 value={yearFilter}
//                                 onChange={handleYearChange}
//                                 className="sealab-finances-input"
//                             />
//                         </Form.Group>
//                     </Col>
//                     <Col md={4} lg={3}>
//                         <Form.Group className="sealab-finances-control">
//                             <Form.Label>Month</Form.Label>
//                             <Form.Select
//                                 value={monthFilter}
//                                 onChange={handleMonthChange}
//                                 className="sealab-finances-select"
//                                 disabled={!yearFilter}
//                             >
//                                 <option value="">All Months</option>
//                                 <option value="1">January</option>
//                                 <option value="2">February</option>
//                                 <option value="3">March</option>
//                                 <option value="4">April</option>
//                                 <option value="5">May</option>
//                                 <option value="6">June</option>
//                                 <option value="7">July</option>
//                                 <option value="8">August</option>
//                                 <option value="9">September</option>
//                                 <option value="10">October</option>
//                                 <option value="11">November</option>
//                                 <option value="12">December</option>
//                             </Form.Select>
//                         </Form.Group>
//                     </Col>
//                     <Col md={4} lg={3}>
//                         <Form.Group className="sealab-finances-control">
//                             <Form.Label>Search Transactions</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 placeholder="Search by Order ID, Email, or Transaction ID"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                                 className="sealab-finances-search"
//                             />
//                         </Form.Group>
//                     </Col>
//                     <Col md={4} lg={3} className="d-flex align-items-end test3">
//                         <Button variant="success" onClick={exportToPDF}>
//                             Export to PDF
//                         </Button>
//                     </Col>
//                 </Row>
//
//                 {/* Data & Stats Section */}
//                 <div ref={tableRef}>
//                     {/* Stats Cards Row */}
//                     <Row className="mb-4 g-3">
//                         <Col xs={12} sm={6} md={4} lg={3} xl>
//                             <Card className="sealab-finances-card">
//                                 <Card.Body>
//                                     <div className="sealab-finances-card-icon">
//                                         <FaMoneyBillWave/>
//                                     </div>
//                                     <h6 className="sealab-finances-card-title">Total Revenue</h6>
//                                     <h3 className="sealab-finances-card-value">{filteredStats.totalRevenue}</h3>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col xs={12} sm={6} md={4} lg={3} xl>
//                             <Card className="sealab-finances-card">
//                                 <Card.Body>
//                                     <div className="sealab-finances-card-icon">
//                                         <FaBalanceScale/>
//                                     </div>
//                                     <h6 className="sealab-finances-card-title">Estimated Cost</h6>
//                                     <p className="sealab-finances-card-text">COGS: {filteredStats.totalEstimatedCost}</p>
//                                     <p className="sealab-finances-card-text">Gross
//                                         Profit: {filteredStats.totalEstimatedProfit}</p>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col xs={12} sm={6} md={4} lg={3} xl>
//                             <Card className="sealab-finances-card">
//                                 <Card.Body>
//                                     <div className="sealab-finances-card-icon">
//                                         <FaBalanceScale/>
//                                     </div>
//                                     <h6 className="sealab-finances-card-title">Actual Cost</h6>
//                                     <p className="sealab-finances-card-text">COGS: {filteredStats.totalActualCost}</p>
//                                     <p className="sealab-finances-card-text">Gross
//                                         Profit: {filteredStats.actualGrossProfit}</p>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col xs={12} sm={6} md={4} lg={3} xl>
//                             <Card className="sealab-finances-card">
//                                 <Card.Body>
//                                     <div className="sealab-finances-card-icon">
//                                         <FaBalanceScale/>
//                                     </div>
//                                     <h6 className="sealab-finances-card-title">Overdue</h6>
//                                     <h3 className="sealab-finances-card-value">{filteredStats.totalOverdue}</h3>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col xs={12} sm={6} md={4} lg={3} xl>
//                             <Card className="sealab-finances-card">
//                                 <Card.Body>
//                                     <div className="sealab-finances-card-icon">
//                                         <FaChartLine/>
//                                     </div>
//                                     <h6 className="sealab-finances-card-title">Sales Tax</h6>
//                                     <h3 className="sealab-finances-card-value">{filteredStats.salesTax}</h3>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     </Row>
//
//                     {/* Transactions Table */}
//                     <Row>
//                         <Col>
//                             <Card className="sealab-finances-table-card">
//                                 <Card.Header className="sealab-finances-table-header">
//                                     <h5 className="mb-0">Transactions</h5>
//                                 </Card.Header>
//                                 <Card.Body>
//                                     <div className="table-responsive">
//                                         <Table hover className="sealab-finances-table">
//                                             <thead>
//                                             <tr>
//                                                 <th>Order ID</th>
//                                                 <th>Email</th>
//                                                 <th>Date</th>
//                                                 <th>Sales Price</th>
//                                                 <th>Estimated Cost</th>
//                                                 <th>Actual Cost</th>
//                                                 <th>Processing Fee</th>
//                                                 <th>Sales Tax</th>
//                                                 <th>Outstanding</th>
//                                                 <th>Processing TX ID</th>
//                                                 <th>Invoice TX ID</th>
//                                                 <th>Actions</th>
//                                             </tr>
//                                             </thead>
//                                             <tbody>
//                                             {loading ? (
//                                                 <tr>
//                                                     <td colSpan="12" className="text-center">
//                                                         Loading transactions...
//                                                     </td>
//                                                 </tr>
//                                             ) : displayedTransactions.length === 0 ? (
//                                                 <tr>
//                                                     <td colSpan="12" className="text-center">
//                                                         No transactions found
//                                                     </td>
//                                                 </tr>
//                                             ) : (
//                                                 displayedTransactions.map((tx) => {
//                                                     // Calculate Actual Cost for this transaction by summing matching amounts from orderActualCost.
//                                                     const actualCost = orderActualCost
//                                                         .filter((ov) => String(ov.orderId).trim() === String(tx.orderId).trim())
//                                                         .reduce((acc, curr) => acc + (curr.amountOwed || 0), 0);
//                                                     // Retrieve Estimated Cost for this transaction from the mapping.
//                                                     const estimatedCost = estimatedCostPerOrder[String(tx.orderId).trim()] || 0;
//                                                     return (
//                                                         <tr key={tx.id}>
//                                                             <td>{tx.orderId}</td>
//                                                             <td>{tx.email}</td>
//                                                             <td>{tx.date}</td>
//                                                             <td>{formatCurrency(tx.invoiceAmount)}</td>
//                                                             <td>{formatCurrency(estimatedCost)}</td>
//                                                             <td>{formatCurrency(actualCost)}</td>
//                                                             <td>{formatCurrency(tx.processingFee)}</td>
//                                                             <td>{formatCurrency(tx.salesTax)}</td>
//                                                             <td>{formatCurrency(tx.remainingBalance)}</td>
//                                                             <td>{tx.processingTxId}</td>
//                                                             <td>{tx.invoiceTxId}</td>
//                                                             <td>
//                                                                 <div className="d-flex gap-2">
//                                                                     <Button
//                                                                         variant="outline-primary"
//                                                                         size="sm"
//                                                                         className="sealab-finances-action-btn"
//                                                                         onClick={() =>
//                                                                             window.open(`/internal/order-management/${tx.orderId}`, '_blank')
//                                                                         }
//                                                                     >
//                                                                         View Order
//                                                                     </Button>
//                                                                     <Button
//                                                                         variant="outline-danger"
//                                                                         size="sm"
//                                                                         className="sealab-finances-action-btn"
//                                                                         onClick={() => openRefundModal(tx)}
//                                                                         disabled={processingRefund || !tx.invoiceTxId}
//                                                                     >
//                                                                         Refund
//                                                                     </Button>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     );
//                                                 })
//                                             )}
//                                             </tbody>
//                                         </Table>
//                                     </div>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     </Row>
//                 </div>
//             </Container>
//
//             {/* Refund Modal */}
//             <Modal show={showRefundModal} onHide={() => setShowRefundModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Process Refund</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {selectedTransaction && (
//                         <div>
//                             <p className="mb-2">
//                                 <strong>Order ID:</strong> {selectedTransaction.orderId}
//                             </p>
//                             <p className="mb-2">
//                                 <strong>Email:</strong> {selectedTransaction.email}
//                             </p>
//                             <p className="mb-2">
//                                 <strong>Original
//                                     Amount:</strong> {formatCurrency(selectedTransaction.invoiceAmount || 0)}
//                             </p>
//                             <p className="mb-2">
//                                 <strong>Sales Tax:</strong> {formatCurrency(selectedTransaction.salesTax || 0)}
//                             </p>
//                             <p className="mb-3">
//                                 <strong>Total
//                                     Amount:</strong> {formatCurrency((selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0))}
//                             </p>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Refund Amount ($)</Form.Label>
//                                 <Form.Control
//                                     type="number"
//                                     step="0.01"
//                                     min="0.01"
//                                     max={(selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0)}
//                                     value={refundAmount}
//                                     onChange={handleRefundAmountChange}
//                                     isInvalid={!!refundError}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {refundError}
//                                 </Form.Control.Feedback>
//                                 <Form.Text className="text-muted">
//                                     Enter an amount between $0.01
//                                     and {formatCurrency((selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0))}
//                                 </Form.Text>
//                             </Form.Group>
//                         </div>
//                     )}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowRefundModal(false)}>
//                         Cancel
//                     </Button>
//                     <Button variant="danger" onClick={handleRefund}
//                             disabled={processingRefund || !!refundError || !refundAmount}>
//                         {processingRefund ? 'Processing...' : 'Process Refund'}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };
//
// export default Finances;


import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Container, Form, Modal, Row, Table} from 'react-bootstrap';
import {FaBalanceScale, FaChartLine, FaMoneyBillWave} from 'react-icons/fa';
import axios from 'axios';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import './FinancesStyles.css';

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);
};

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const Finances = () => {
    // Default year is the current year as string.
    const currentYear = new Date().getFullYear().toString();
    const [searchQuery, setSearchQuery] = useState('');
    const [transactions, setTransactions] = useState([]);
    // displayedTransactions are used for client‐side search filtering.
    const [displayedTransactions, setDisplayedTransactions] = useState([]);
    const [yearFilter, setYearFilter] = useState(currentYear);
    const [monthFilter, setMonthFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [financialStats, setFinancialStats] = useState({
        totalRevenue: 0,
        totalEstimatedCost: 0,
        totalEstimatedProfit: 0,
        totalActualCost: 0,
        actualGrossProfit: 0,
        salesTax: 0,
        totalOverdue: 0,
    });
    const [filteredStats, setFilteredStats] = useState({
        totalRevenue: 0,
        totalEstimatedCost: 0,
        totalEstimatedProfit: 0,
        totalActualCost: 0,
        totalOverdue: 0,
        actualGrossProfit: 0,
        salesTax: 0,
    });
    const [processingRefund, setProcessingRefund] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [refundAmount, setRefundAmount] = useState('');
    const [refundError, setRefundError] = useState('');
    // Arrays from backend
    const [orderActualCost, setOrderActualCost] = useState([]);
    const [invoices, setInvoices] = useState([]);
    // Mapping of estimated cost per order (key = orderId)
    const [estimatedCostPerOrder, setEstimatedCostPerOrder] = useState({});
    const baseURL = process.env.REACT_APP_BASEURL;

    // Reference for PDF export
    const tableRef = useRef(null);

    // Re-fetch transactions whenever the year or month filter changes.
    useEffect(() => {
        fetchTransactions();
    }, [yearFilter, monthFilter]);

    // Apply client-side search filtering.
    useEffect(() => {
        const searchLower = searchQuery.toLowerCase();
        const filtered = transactions.filter((tx) => {
            return (
                tx.orderId?.toLowerCase().includes(searchLower) ||
                tx.email?.toLowerCase().includes(searchLower) ||
                tx.processingTxId?.toLowerCase().includes(searchLower) ||
                tx.invoiceTxId?.toLowerCase().includes(searchLower)
            );
        });
        setDisplayedTransactions(filtered);
    }, [searchQuery, transactions]);

    // Recalculate filtered stats (based on displayedTransactions)
    useEffect(() => {
        const totalRevenue = displayedTransactions.reduce(
            (acc, tx) => acc + ((tx.invoiceAmount || 0) + (tx.processingFee || 0)),
            0
        );
        const totalSalesPrice = displayedTransactions.reduce(
            (acc, tx) => acc + (tx.invoiceAmount || 0),
            0
        );
        const salesTax = displayedTransactions.reduce(
            (acc, tx) => acc + (tx.salesTax || 0),
            0
        );
        // For filtered stats, we calculate total overdue as the sum of each transaction’s remainingBalance.
        const filteredTotalOverdue = displayedTransactions.reduce(
            (acc, tx) => acc + (tx.remainingBalance || 0),
            0
        );

        // Use matching orderIds to filter invoices and order vendors
        const displayedOrderIds = new Set(
            displayedTransactions.map((tx) => String(tx.orderId).trim())
        );
        const filteredInvoices = invoices.filter((inv) =>
            displayedOrderIds.has(String(inv.orderId).trim())
        );
        const filteredOrderVendors = orderActualCost.filter((ov) =>
            displayedOrderIds.has(String(ov.orderId).trim())
        );

        const totalEstimatedCost = filteredInvoices.reduce(
            (acc, inv) =>
                acc + (inv.getNetCost ? inv.getNetCost() : (inv.netCost || 0)),
            0
        );
        const totalEstimatedProfit = filteredInvoices.reduce(
            (acc, inv) =>
                acc + (inv.getNetProfit ? inv.getNetProfit() : (inv.netProfit || 0)),
            0
        );
        const totalActualCost = filteredOrderVendors.reduce(
            (acc, ov) =>
                acc + (ov.getAmountOwed ? ov.getAmountOwed() : (ov.amountOwed || 0)),
            0
        );
        const actualGrossProfit = totalSalesPrice - totalActualCost;

        setFilteredStats({
            totalRevenue: formatCurrency(totalRevenue),
            totalEstimatedCost: formatCurrency(totalEstimatedCost),
            totalEstimatedProfit: formatCurrency(totalEstimatedProfit),
            totalActualCost: formatCurrency(totalActualCost),
            totalOverdue: formatCurrency(filteredTotalOverdue),
            actualGrossProfit: formatCurrency(actualGrossProfit),
            salesTax: formatCurrency(salesTax),
        });
    }, [displayedTransactions, invoices, orderActualCost]);

    const fetchTransactions = async () => {
        try {
            setLoading(true);
            // Build query parameters based on filters.
            const params = {};
            if (yearFilter) {
                params.year = yearFilter;
                if (monthFilter) {
                    params.month = monthFilter;
                }
            }
            const response = await axios.get(`${baseURL}/admin/internal/finances/transactions`, {params});
            setTransactions(response.data.transactions);
            setOrderActualCost(response.data.orderActualCost || []);
            setInvoices(response.data.invoices || []);
            setEstimatedCostPerOrder(response.data.estimatedCostPerOrder || {});
            calculateFinancialStats(response.data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    // Calculate overall financial stats from the API response.
    const calculateFinancialStats = (data) => {
        const totalRevenue = data.transactions.reduce(
            (acc, tx) => acc + ((tx.invoiceAmount || 0) + (tx.processingFee || 0)),
            0
        );
        setFinancialStats({
            totalRevenue: formatCurrency(totalRevenue),
            totalEstimatedCost: formatCurrency(data.totalEstimatedCost || 0),
            totalEstimatedProfit: formatCurrency(data.totalEstimatedProfit || 0),
            totalActualCost: formatCurrency(data.totalActualCost || 0),
            actualGrossProfit: formatCurrency(data.actualGrossProfit || 0),
            salesTax: formatCurrency(
                data.transactions.reduce((acc, tx) => acc + (tx.salesTax || 0), 0)
            ),
            totalOverdue: formatCurrency(data.totalOverdue || 0),
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleYearChange = (e) => {
        setYearFilter(e.target.value);
        if (!e.target.value) {
            setMonthFilter('');
        }
    };

    const handleMonthChange = (e) => {
        setMonthFilter(e.target.value);
    };

    const openRefundModal = (transaction) => {
        if (!transaction.invoiceTxId) {
            alert('No invoice transaction ID found for this transaction');
            return;
        }
        setSelectedTransaction(transaction);
        const totalAmount = (transaction.invoiceAmount || 0) + (transaction.salesTax || 0);
        setRefundAmount(totalAmount.toFixed(2));
        setRefundError('');
        setShowRefundModal(true);
    };

    const handleRefundAmountChange = (e) => {
        const value = e.target.value;
        setRefundAmount(value);
        if (!selectedTransaction) return;
        const numValue = parseFloat(value);
        const maxAmount = (selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0);
        if (numValue <= 0) {
            setRefundError('Refund amount must be greater than 0');
        } else if (numValue > maxAmount) {
            setRefundError(`Refund amount cannot exceed ${formatCurrency(maxAmount)}`);
        } else {
            setRefundError('');
        }
    };

    const handleRefund = async () => {
        if (!selectedTransaction || !selectedTransaction.invoiceTxId) {
            alert('No invoice transaction ID found for this transaction');
            return;
        }
        const numAmount = parseFloat(refundAmount);
        const maxAmount = (selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0);
        if (numAmount <= 0 || numAmount > maxAmount) {
            setRefundError(`Refund amount must be between $0.01 and ${formatCurrency(maxAmount)}`);
            return;
        }
        if (window.confirm(`Are you sure you want to refund ${formatCurrency(numAmount)}?`)) {
            try {
                setProcessingRefund(true);
                const response = await axios.post('http://localhost:8080/api/stripe/refund', {
                    paymentIntentId: selectedTransaction.invoiceTxId,
                    orderId: selectedTransaction.orderId,
                    amount: Math.round(numAmount * 100)
                });
                if (response.status === 200) {
                    alert('Refund processed successfully');
                    setShowRefundModal(false);
                    setSelectedTransaction(null);
                    setRefundAmount('');
                    await fetchTransactions();
                } else {
                    alert('Error processing refund: ' + response.data);
                }
            } catch (error) {
                console.error('Error processing refund:', error);
                alert('Error processing refund: ' + (error.response?.data || error.message));
            } finally {
                setProcessingRefund(false);
            }
        }
    };

    // Export the table and header info as a PDF.
    const exportToPDF = () => {
        if (!tableRef.current) return;
        html2canvas(tableRef.current, {scale: 3}).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const margin = 10;
            const imgWidth = pdfWidth - 2 * margin;
            const imgProps = pdf.getImageProperties(imgData);
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

            let headerText = 'Transactions Report';
            if (yearFilter) {
                headerText += ` - Year: ${yearFilter}`;
                if (monthFilter) {
                    const monthName = monthNames[parseInt(monthFilter, 10) - 1] || monthFilter;
                    headerText += `, Month: ${monthName}`;
                }
            } else {
                headerText += ' - All Years';
            }
            pdf.setFontSize(16);
            pdf.text(headerText, pdfWidth / 2, 15, {align: 'center'});
            pdf.addImage(imgData, 'PNG', margin, 25, imgWidth, imgHeight);
            pdf.save(`Transactions_${yearFilter || 'All'}_${monthFilter ? monthNames[parseInt(monthFilter, 10) - 1] : 'All'}.pdf`);
        });
    };

    return (
        <div className="sealab-finances">
            <Container fluid className="py-4">
                {/* Header */}
                <Row className="mb-4">
                    <Col>
                        <h1 className="sealab-finances-title">Sealab Finances</h1>
                        <p className="sealab-finances-subtitle">Financial Management Dashboard</p>
                    </Col>
                </Row>

                {/* Filters and Search */}
                <Row className="mb-4">
                    <Col md={4} lg={3}>
                        <Form.Group className="sealab-finances-control">
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter year (e.g., 2025)"
                                value={yearFilter}
                                onChange={handleYearChange}
                                className="sealab-finances-input"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} lg={3}>
                        <Form.Group className="sealab-finances-control">
                            <Form.Label>Month</Form.Label>
                            <Form.Select
                                value={monthFilter}
                                onChange={handleMonthChange}
                                className="sealab-finances-select"
                                disabled={!yearFilter}
                            >
                                <option value="">All Months</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4} lg={3}>
                        <Form.Group className="sealab-finances-control">
                            <Form.Label>Search Transactions</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Search by Order ID, Email, or Transaction ID"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="sealab-finances-search"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} lg={3} className="d-flex align-items-end test3">
                        <Button variant="success" onClick={exportToPDF}>
                            Export to PDF
                        </Button>
                    </Col>
                </Row>

                {/* Data & Stats Section */}
                <div ref={tableRef}>
                    {/* Stats Cards Row */}
                    <Row className="mb-4 g-3">
                        <Col xs={12} sm={6} md={4} lg={3} xl>
                            <Card className="sealab-finances-card">
                                <Card.Body>
                                    <div className="sealab-finances-card-icon">
                                        <FaMoneyBillWave/>
                                    </div>
                                    <h6 className="sealab-finances-card-title">Total Revenue</h6>
                                    <h3 className="sealab-finances-card-value">{filteredStats.totalRevenue}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl>
                            <Card className="sealab-finances-card">
                                <Card.Body>
                                    <div className="sealab-finances-card-icon">
                                        <FaBalanceScale/>
                                    </div>
                                    <h6 className="sealab-finances-card-title">Estimated Cost</h6>
                                    <p className="sealab-finances-card-text">COGS: {filteredStats.totalEstimatedCost}</p>
                                    <p className="sealab-finances-card-text">
                                        Gross Profit: {filteredStats.totalEstimatedProfit}
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl>
                            <Card className="sealab-finances-card">
                                <Card.Body>
                                    <div className="sealab-finances-card-icon">
                                        <FaBalanceScale/>
                                    </div>
                                    <h6 className="sealab-finances-card-title">Actual Cost</h6>
                                    <p className="sealab-finances-card-text">COGS: {filteredStats.totalActualCost}</p>
                                    <p className="sealab-finances-card-text">
                                        Gross Profit: {filteredStats.actualGrossProfit}
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl>
                            <Card className="sealab-finances-card">
                                <Card.Body>
                                    <div className="sealab-finances-card-icon">
                                        <FaBalanceScale/>
                                    </div>
                                    <h6 className="sealab-finances-card-title"
                                    >Overdue</h6>
                                    <h3 className="sealab-finances-card-value"
                                        style={{color: 'red'}}>{filteredStats.totalOverdue}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl>
                            <Card className="sealab-finances-card">
                                <Card.Body>
                                    <div className="sealab-finances-card-icon">
                                        <FaChartLine/>
                                    </div>
                                    <h6 className="sealab-finances-card-title">Sales Tax</h6>
                                    <h3 className="sealab-finances-card-value">{filteredStats.salesTax}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Transactions Table */}
                    <Row>
                        <Col>
                            <Card className="sealab-finances-table-card">
                                <Card.Header className="sealab-finances-table-header">
                                    <h5 className="mb-0">Transactions</h5>
                                </Card.Header>
                                <Card.Body>
                                    <div className="table-responsive">
                                        <Table hover className="sealab-finances-table">
                                            <thead>
                                            <tr>
                                                <th>Order ID</th>
                                                <th>Email</th>
                                                <th>Date</th>
                                                <th>Sales Price</th>
                                                <th>Estimated Cost</th>
                                                <th>Actual Cost</th>
                                                <th>Processing Fee</th>
                                                <th>Sales Tax</th>
                                                <th>Outstanding</th>
                                                <th>Processing TX ID</th>
                                                <th>Invoice TX ID</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        Loading transactions...
                                                    </td>
                                                </tr>
                                            ) : displayedTransactions.length === 0 ? (
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No transactions found
                                                    </td>
                                                </tr>
                                            ) : (
                                                displayedTransactions.map((tx) => {
                                                    // Calculate Actual Cost for this transaction by summing matching amounts from orderActualCost.
                                                    const actualCost = orderActualCost
                                                        .filter((ov) => String(ov.orderId).trim() === String(tx.orderId).trim())
                                                        .reduce((acc, curr) => acc + (curr.amountOwed || 0), 0);
                                                    // Retrieve Estimated Cost for this transaction from the mapping.
                                                    const estimatedCost = estimatedCostPerOrder[String(tx.orderId).trim()] || 0;
                                                    return (
                                                        <tr key={tx.id}>
                                                            <td>{tx.orderId}</td>
                                                            <td>{tx.email}</td>
                                                            <td>{tx.date}</td>
                                                            <td>{formatCurrency(tx.invoiceAmount)}</td>
                                                            <td>{formatCurrency(estimatedCost)}</td>
                                                            <td>{formatCurrency(actualCost)}</td>
                                                            <td>{formatCurrency(tx.processingFee)}</td>
                                                            <td>{formatCurrency(tx.salesTax)}</td>
                                                            <td style={{
                                                                color: tx.remainingBalance > 0 ? 'red' : 'black',
                                                                fontWeight: tx.remainingBalance > 0 ? 'bold' : 'normal'
                                                            }}>
                                                                {formatCurrency(tx.remainingBalance)}
                                                            </td>
                                                            <td>{tx.processingTxId}</td>
                                                            <td>{tx.invoiceTxId}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        size="sm"
                                                                        className="sealab-finances-action-btn"
                                                                        onClick={() =>
                                                                            window.open(`/internal/order-management/${tx.orderId}`, '_blank')
                                                                        }
                                                                    >
                                                                        View Order
                                                                    </Button>
                                                                    <Button
                                                                        variant="outline-danger"
                                                                        size="sm"
                                                                        className="sealab-finances-action-btn"
                                                                        onClick={() => openRefundModal(tx)}
                                                                        disabled={processingRefund || !tx.invoiceTxId}
                                                                    >
                                                                        Refund
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>

            {/* Refund Modal */}
            <Modal show={showRefundModal} onHide={() => setShowRefundModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Process Refund</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTransaction && (
                        <div>
                            <p className="mb-2">
                                <strong>Order ID:</strong> {selectedTransaction.orderId}
                            </p>
                            <p className="mb-2">
                                <strong>Email:</strong> {selectedTransaction.email}
                            </p>
                            <p className="mb-2">
                                <strong>Original
                                    Amount:</strong> {formatCurrency(selectedTransaction.invoiceAmount || 0)}
                            </p>
                            <p className="mb-2">
                                <strong>Sales Tax:</strong> {formatCurrency(selectedTransaction.salesTax || 0)}
                            </p>
                            <p className="mb-3">
                                <strong>Total
                                    Amount:</strong> {formatCurrency((selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0))}
                            </p>
                            <Form.Group className="mb-3">
                                <Form.Label>Refund Amount ($)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    min="0.01"
                                    max={(selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0)}
                                    value={refundAmount}
                                    onChange={handleRefundAmountChange}
                                    isInvalid={!!refundError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {refundError}
                                </Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    Enter an amount between $0.01
                                    and {formatCurrency((selectedTransaction.invoiceAmount || 0) + (selectedTransaction.salesTax || 0))}
                                </Form.Text>
                            </Form.Group>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRefundModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleRefund}
                            disabled={processingRefund || !!refundError || !refundAmount}>
                        {processingRefund ? 'Processing...' : 'Process Refund'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Finances;

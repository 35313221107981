// components/Profile/ProfileComponent.js

import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import ChangedPasswordModal from '../../modals/ChangePasswordModal';
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../features/user/userSlice";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import './ProfileStyles.css';

const ProfileComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const name = useSelector(state => state.user.user.firstName);
    const lastName = useSelector(state => state.user.user.lastName);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/');
        }
    }, [userLoggedIn, navigate]);

    const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);

    const handleOpenModal = () => setShowChangePasswordModal(true);
    const handleCloseModal = () => setShowChangePasswordModal(false);

    const handleLogout = () => {
        dispatch(logOut());
        navigate('/');
    };

    if (!userLoggedIn) {
        return null;
    }

    return (
        <div className="sealab-profile-wrapper">
            <Helmet>
                <title>{`${name} | The Sealab`}</title>
                <meta name="description"
                      content="Manage your profile, view past orders, change your password, or logout. All your cabinet management in one place."/>
                <meta name="keywords"
                      content="user profile, edit profile, view orders, change password, kitchen cabinets, closet cabinets, e-commerce, cabinet store"/>
            </Helmet>

            {/*<NavbarComponent/>*/}

            <div className="sealab-profile-content">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="sealab-profile-card">
                                <div className="sealab-profile-header">
                                    <h1 className="sealab-profile-title">My Profile</h1>
                                    {/*<div className="sealab-profile-avatar">*/}
                                    {/*    {capitalizeFirstLetter(name)?.charAt(0)}*/}
                                    {/*</div>*/}
                                    {/*<h2 className="sealab-profile-name">{capitalizeFirstLetter(name)}</h2>*/}
                                    <h2 className="sealab-profile-name">{`${capitalizeFirstLetter(name)}` + ' ' + `${capitalizeFirstLetter(lastName)}`}</h2>
                                </div>

                                <div className="sealab-profile-actions">
                                    <NavLink to="/user/edit-profile" className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">✏️</span>
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Edit Profile</div>
                                            <div className="sealab-button-subtitle">Update your personal information
                                            </div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="/user/profile/saved-orders" className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">📦</span>
                                            <i className="bi bi-box-seam"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Saved Orders</div>
                                            <div className="sealab-button-subtitle">View your order history</div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="#" onClick={handleOpenModal} className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">🔒</span>
                                            <i className="bi bi-shield-lock"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Change Password</div>
                                            <div className="sealab-button-subtitle">Update your security credentials
                                            </div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="/" onClick={handleLogout} className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">🚪</span>
                                            <i className="bi bi-box-arrow-right"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Logout</div>
                                            <div className="sealab-button-subtitle">Sign out of your account</div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangedPasswordModal show={showChangePasswordModal} handleClose={handleCloseModal}/>
        </div>
    );
};

export default ProfileComponent;
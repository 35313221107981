// features/materials/materialsSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

// Async thunks for API calls
export const fetchMaterials = createAsyncThunk(
    'materials/fetchMaterials',
    async (_, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${baseURL}/getAllMaterials`);
            return response.data || []; // Ensure it always returns an array
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch materials');
        }
    }
);

export const createMaterial = createAsyncThunk(
    'materials/createMaterial',
    async ({material, token}, {rejectWithValue}) => {
        try {
            const response = await axios.post(
                `${baseURL}/api/internal/create-material`,
                {
                    name: material.name,
                    description: material.description,
                    thickness: parseFloat(material.thickness),
                    rawCost: parseFloat(material.rawCost),
                    visible: parseInt(material.visible, 10),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create material');
        }
    }
);

// Slice
const materialsSlice = createSlice({
    name: 'materials',
    initialState: {
        items: [], // All materials
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null, // Error message, if any
    },
    reducers: {
        // Reducer for sorting materials (optional)
        sortMaterials: (state) => {
            state.items = [...state.items].sort((a, b) => {
                if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                    return -1;
                } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                    return 1;
                }
                return a.description.localeCompare(b.description);
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMaterials.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMaterials.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload || []; // Ensure items is always an array
                state.error = null;
            })
            .addCase(fetchMaterials.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(createMaterial.fulfilled, (state, action) => {
                state.items.push(action.payload); // Add the new material to items
            });
    },
});

// Selectors with `reselect`
export const selectAllMaterials = (state) => state.materials.items;

// Memoized selector for visible materials
export const selectVisibleMaterials = createSelector(
    [selectAllMaterials],
    (items) => items.filter((material) => material.visible === 1) // Filter for visible materials
);

// Memoized selector for sorted materials (optional)
export const selectSortedMaterials = createSelector(
    [selectAllMaterials],
    (items) => {
        return [...items].sort((a, b) => {
            if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                return -1;
            } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                return 1;
            }
            return a.description.localeCompare(b.description);
        });
    }
);

export const selectMaterialsStatus = (state) => state.materials.status;
export const selectMaterialsError = (state) => state.materials.error;

export const {sortMaterials} = materialsSlice.actions;

export default materialsSlice.reducer;

// components/ShowRoom/CabinetCard/CabinetCard.js

import React from 'react';
import ImageCarousel from '../ImageCarouselComponent/ImageCarousel'; // Component to display an image carousel
import QuantityInput from './QuantityInput'; // Component to manage quantity input
import CardButtons from './CardButtons'; // Component to handle action buttons (e.g., Configure, Add to Cart)

/**
 * CabinetCard Component
 *
 * A reusable card component for displaying cabinet details in the showroom.
 * Includes an image carousel, title, description, quantity input, and action buttons.
 *
 * Props:
 * - cabinet: Object containing details of the cabinet (serial number, description, etc.).
 * - cardWidth: Number representing the width of the card in pixels.
 * - onQuantityChange: Function to handle quantity input changes.
 * - onConfigure: Function to handle the Configure button click.
 * - onAddToCart: Function to handle the Add to Cart button click.
 */
const CabinetCard = ({
                         cabinet, // Cabinet details object
                         cardWidth, // Width of the card
                         onQuantityChange, // Callback for quantity changes
                         onConfigure, // Callback for Configure button
                         onAddToCart // Callback for Add to Cart button
                     }) => (
    <div
        className="card base-card h-100 showroom-cstm-card" // Bootstrap classes for card styling
        style={{width: `${cardWidth}px`}} // Inline style to set card width dynamically
    >
        {/* Image carousel for the cabinet */}
        <ImageCarousel cabinet={cabinet}/>

        {/* Card overlay to display cabinet details */}
        <div className="card-overlay showroom-card-overlay">
            {/* Cabinet serial number as the card title */}
            <h5 className="card-title showroom-card-title">{cabinet.serialNumber}</h5>

            {/* Cabinet description */}
            <p className="card-description showroom-card-description">{cabinet.description}</p>

            {/* Quantity input component */}
            <QuantityInput
                onChange={(value) => onQuantityChange(cabinet.id, value)} // Passes quantity change event to parent
            />

            {/* Action buttons (e.g., Configure, Add to Cart) */}
            <CardButtons
                onConfigure={() => onConfigure(cabinet)} // Triggers Configure callback with the cabinet object
                onAddToCart={() => onAddToCart(cabinet)} // Triggers Add to Cart callback with the cabinet object
            />
        </div>
    </div>
);

export default CabinetCard;

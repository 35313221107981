// import React, {useEffect, useRef, useState} from 'react';
// import {Link, useNavigate, useParams} from 'react-router-dom';
// import {useDispatch, useSelector} from 'react-redux';
// import axios from 'axios';
// import './OrderDetailsStyles.css';
// import 'jspdf-autotable';
// import PaymentModal from "../../../modals/PaymentModal";
// import {format, parseISO} from "date-fns";
// import EditOrderAgreement from '../../../modals/EditOrderAgreement';
// import {fetchArticlesByOrderId} from '../../../api/OrderService';
// import {reAddToCart, setIsRevisedOrder, setOrderInfo} from '../../../features/cart/cartSlice';
// import sealabIcon from '../../../images/sealab_icon.png';
// import checkmarkImg from '../../../images/checkmark.png';
//
// const OrderDetailsComponent = () => {
//     const user = useSelector(state => state.user.user);
//     const {orderId} = useParams();
//     const [order, setOrder] = useState(null);
//     const [invoiceData, setInvoiceData] = useState(null);
//     const [errorMessage, setErrorMessage] = useState(null);
//     const token = useSelector(state => state.user.accessToken);
//     const [showPaymentModal, setShowPaymentModal] = useState(false);
//     const [additionalPaymentModal, setAdditionalPaymentModal] = useState(false);
//     const [additionalPrice, setAdditionalPrice] = useState(0.00);
//     const [showEditOrderAgreement, setShowEditOrderAgreement] = useState(false);
//     const [showRevisionsModal, setShowRevisionsModal] = useState(false);
//     const [includeHardware, setIncludeHardware] = useState(order?.includeHardware);
//     const [includeAssembly, setIncludeAssembly] = useState(order?.includeAssembly);
//     const [includeFinishing, setIncludeFinishing] = useState(order?.includeFinishing);
//     const [includeDrawerboxes, setIncludeDrawerboxes] = useState(order?.includeDrawerboxes);
//     const [includeSalesTax, setIncludeSalesTax] = useState(false);
//     const [remainingBalance, setRemainingBalance] = useState(invoiceData?.remainingBalance);
//     const [showAsterisk, setShowAsterisk] = useState(false);
//     const [status, setStatus] = useState('');
//     const baseURL = process.env.REACT_APP_BASEURL;
//     const thumbNail = process.env.REACT_APP_THMBNAIL;
//     const drawingsURL = `${process.env.REACT_APP_THMBNAIL}${orderId}/Article_Report.zip`;
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const salesTax = 1.08875
//     const fetchCalled = useRef(false); // Track if API call has already been made
//
//     const handleClosePaymentModal = () => {
//         setShowPaymentModal(false);
//         setAdditionalPaymentModal(false);
//     };
//
//     // const handlePayment = () => {
//     //     e.preventDefault();
//     //     setShowPaymentModal(true);
//     // };
//
//     const handleEditOrder = () => {
//         if (invoiceData?.numOfRevisions > 0) {
//             setShowEditOrderAgreement(true);
//         } else {
//             setShowRevisionsModal(true);
//         }
//     };
//
//     const handleAgreeEditOrder = async () => {
//         try {
//             const response = await fetchArticlesByOrderId(orderId, token);
//
//             const articlesData = response.data.articles;
//             const orderInfo = response.data.orderInfo;
//
//             Object.entries(articlesData).forEach(([key, articleGroup]) => {
//                 if (articleGroup.length === 1) {
//                     // Dispatch single article
//                     dispatch(reAddToCart({...articleGroup[0], itemId: articleGroup[0].articleId, generatePos: false}));
//                 } else if (articleGroup.length > 1) {
//                     // Group articles together
//                     const firstArticle = articleGroup[0];
//                     const groupedArticle = {
//                         ...firstArticle,
//                         itemId: firstArticle.articleId,
//                         quantity: articleGroup.length,
//                         positionName: firstArticle.positionName
//                     };
//                     dispatch(reAddToCart(groupedArticle));
//                 }
//             });
//
//             dispatch(setOrderInfo(orderInfo));
//             dispatch(setIsRevisedOrder(true));
//             setShowEditOrderAgreement(false);
//             navigate('/cart', {state: {orderId: orderId}});
//         } catch (error) {
//             console.error('Error fetching articles by order ID:', error);
//         }
//     };
//
//
//     useEffect(() => {
//         const fetchOrderDetails = async () => {
//             try {
//                 const [orderResponse, statusResponse, invoiceResponse] = await Promise.all([
//                     axios.get(`${baseURL}/api/orders/${orderId}`, {
//                         headers: {'Authorization': `Bearer ${token}`}
//                     }),
//                     axios.get(`${baseURL}/user/customer-portal/${orderId}/getStatus`, {
//                         headers: {'Authorization': `Bearer ${token}`}
//                     }),
//                     axios.post(`${baseURL}/user/customer-portal/${orderId}`, {
//                         includeHardware: order?.includeHardware,
//                         includeAssembly: order?.includeAssembly,
//                         includeFinishing: order?.includeFinishing,
//                         includeDrawerboxes: order?.includeDrawerboxes
//                     }, {
//                         headers: {
//                             'Authorization': `Bearer ${token}`,
//                             'Content-Type': 'application/json'
//                         }
//                     })
//                 ]);
//                 setOrder(orderResponse.data);
//                 setStatus(statusResponse.data);
//                 setInvoiceData(invoiceResponse.data);
//                 // setAdditionalPrice(prev => prev + invoiceData?.remainingBalance);
//
//                 const {
//                     totalAssemblyCost,
//                     totalDrawerCost,
//                     totalFinishingCost,
//                     totalHardwareCost,
//                     remainingBalance
//                 } = invoiceResponse.data
//
//                 setAdditionalPrice((totalAssemblyCost + totalFinishingCost + totalDrawerCost + totalHardwareCost) * 1.08875 + remainingBalance)
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 if (error.response && error.response.status === 404) {
//                     setErrorMessage("Sorry. Your order is still being processed. You will receive an email when your order has been processed.");
//                 } else {
//                     setErrorMessage("An error occurred while fetching data. Please try again later.");
//                 }
//             }
//         };
//         fetchOrderDetails();
//     }, [orderId, token, includeHardware, includeAssembly, includeFinishing, includeDrawerboxes]);
//
//
//     const handleCheckboxChange = async (name, value) => {
//         if (name === 'includeHardware')
//             order.includeHardware = String(value);
//         if (name === 'includeAssembly')
//             order.includeAssembly = String(value);
//         if (name === 'includeFinishing')
//             order.includeFinishing = String(value);
//         if (name === 'includeDrawerboxes')
//             order.includeDrawerboxes = String(value);
//
//
//         try {
//             const response = await axios.post(`${baseURL}/user/customer-portal/${orderId}`, {
//                 includeHardware: order?.includeHardware,
//                 includeAssembly: order?.includeAssembly,
//                 includeFinishing: order?.includeFinishing,
//                 includeDrawerboxes: order?.includeDrawerboxes
//             }, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json'
//                 }
//             });
//
//             if (name === 'includeHardware') setIncludeHardware(value);
//             if (name === 'includeAssembly') setIncludeAssembly(value);
//             if (name === 'includeFinishing') setIncludeFinishing(value);
//             if (name === 'includeDrawerboxes') setIncludeDrawerboxes(value);
//
//             const {totalAssemblyCost, totalDrawerCost, totalFinishingCost, totalHardwareCost} = response.data
//             // setAdditionalPrice((totalAssemblyCost + totalFinishingCost + totalDrawerCost + totalHardwareCost) * 1.08875)
//         } catch (error) {
//             console.error('Error updating options:', error);
//         }
//     };
//
//     const downloadInvoicePDF = async () => {
//         const {jsPDF} = await import("jspdf");
//
//         // Create a new jsPDF instance with custom page height
//         const doc = new jsPDF({
//             orientation: 'portrait',
//             unit: 'mm',
//             format: [210, 330] // A4 width with increased height (297mm to 350mm)
//         });
//
//         // Add company logo
//         doc.addImage(sealabIcon, 'PNG', 10, 10, 30, 30);
//
//         // Add company info
//         const pageWidth = doc.internal.pageSize.getWidth();
//         const companyInfo = [
//             'The Sealab',
//             '63 Flushing Ave',
//             'Building 3, Suite 1108',
//             'Brooklyn, NY 11205',
//             'info@thesealab.com'
//         ];
//
//         doc.setFontSize(10);
//         let startY = 15;
//         companyInfo.forEach((line) => {
//             doc.text(line, pageWidth - 10, startY, {align: 'right'});
//             startY += 5;
//         });
//
//         // Add invoice title, order ID, and date aligned with the company info
//         const centerY = 25; // Adjust Y position for alignment
//         doc.setFontSize(16);
//         doc.text('Invoice', pageWidth / 2, 15, {align: 'center'});
//
//         doc.setFontSize(12);
//         doc.text(`Order ID: ${order?.orderId || ''}`, pageWidth / 2, centerY, {align: 'center'});
//         doc.text(`Date: ${invoiceData?.date || ''}`, pageWidth / 2, centerY + 5, {align: 'center'});
//
//         // Adjust starting Y position to ensure no overlap
//         let currentY = startY + 10;
//
//         // Add bill to and project address info aligned on the left and right
//         doc.setFontSize(14);
//         doc.text('Bill To:', 10, currentY + 25);
//
//         // Align Project Address header further right to match with the content underneath
//         const projectAddressX = pageWidth - 50; // Shifted right for alignment
//         doc.text('Project Address:', projectAddressX, currentY + 25);
//
//         doc.setFontSize(12);
//         currentY += 35;
//
//         const addText = (text, x, y) => {
//             if (text) {
//                 doc.text(text, x, y);
//             }
//         };
//
//         // Bill To
//         addText(`${user?.firstName || ''} ${user?.lastName || ''}`.trim(), 10, currentY);
//         addText(`${user?.address1 || ''} ${user?.address2 || ''} ${user?.apartment || ''}`.trim(), 10, currentY + 5);
//         addText(`${user?.city ? `${user.city}, ` : ''}${user?.state || ''} ${user?.zipcode || ''}`.trim(), 10, currentY + 10);
//
//         // Project Address
//         addText(order?.projectName || '', projectAddressX, currentY);
//         addText(order?.purchaseOrder || '', projectAddressX, currentY + 5)
//         addText(`${order?.projectAddress1 || ''} ${order?.projectAddress2 || ''}`.trim(), projectAddressX, currentY + 10);
//         const projectLocation = [
//             order?.projectCity || '',
//             order?.projectState || '',
//             order?.projectZipcode || ''
//         ].filter(Boolean).join(', ');
//         addText(projectLocation, projectAddressX, currentY + 10);
//
//         // Add invoice items table with increased size for readability
//         currentY += 20;
//         const tableData = Object.entries(invoiceData?.posPrice || {}).map(([pos, posPrice]) => [
//             pos,
//             `Dimensions: ${invoiceData?.dimensions[pos] || ''} \nQuantity: ${invoiceData?.quantity[pos] || ''}`.trim(),
//             `$${posPrice?.toFixed(2) || '0.00'}`
//         ]);
//
//         doc.autoTable({
//             head: [['Item Name', 'Description', 'Item Price']],
//             body: tableData,
//             startY: currentY + 10, // Reduce spacing above the table
//             headStyles: {
//                 fillColor: [0, 0, 0], // Black background for header
//                 textColor: [255, 255, 255], // White text
//                 fontSize: 12, // Increase header font size for readability
//             },
//             bodyStyles: {
//                 fontSize: 12, // Increase table content font size for readability
//             },
//         });
//
//         // Add invoice total
//         const totalY = doc.lastAutoTable.finalY + 10;
//         doc.setFontSize(14);
//         doc.text(`Subtotal: $${invoiceData?.totalPrice?.toFixed(2) || '0.00'}`, pageWidth - 15, totalY, {align: 'right'});
//         doc.text(`Sales Tax: 8.875%`, pageWidth - 15, totalY + 7, {align: 'right'})
//         doc.text(`Total: $${(invoiceData?.totalPrice * salesTax).toFixed(2)}`, pageWidth - 15, totalY + 14, {align: 'right'})
//
//         // Add "Included" section with checkmark or x
//         const includedItems = [
//             {name: 'Hardware', included: order?.includeHardware !== "false"},
//             {name: 'Assembly', included: order?.includeAssembly !== "false"},
//             {name: 'Finishing', included: order?.includeFinishing !== "false"},
//             {name: 'Drawerboxes', included: order?.includeDrawerboxes !== "false"}
//         ];
//
//         const includedY = totalY + 30;
//         doc.setFontSize(12);
//         doc.text('*Included*', 10, includedY);
//
//         let y = includedY + 6;
//         includedItems.forEach(item => {
//             if (item.included) {
//                 // For included items, use [✓] with a visible checkmark
//                 doc.text('[', 10, y);
//                 doc.addImage(checkmarkImg, 'PNG', 11, y - 3, 3.5, 3.5); // Adjust the Y-position and size for centering
//                 doc.text(']', 14, y);
//                 doc.text(item.name, 18, y);
//             } else {
//                 // For not included items, use [X]
//                 doc.text('[ ]', 10, y);
//                 doc.text(item.name, 18, y);
//             }
//             y += 6; // Adjust vertical spacing for proper alignment
//         });
//
//         // Add "TERMS" section with title on top and paragraph below, aligned to the right
//         const termsTitleY = includedY;
//         doc.setFontSize(12);
//         doc.text('*TERMS*', pageWidth - 50, termsTitleY, {align: 'center'});
//
//         const termsText =
//             'Payment due upon receipt. NYC sales tax applied where applicable. ' +
//             'Local pickup only at this time. Custom orders are non-refundable. ' +
//             'Order modifications may incur charges. We reserve the right to correct errors. ' +
//             'Full terms at www.thesealab.com/the-sealab/legal/terms. ' +
//             'By accepting delivery, customer agrees to all terms and conditions.';
//
//         const termsY = termsTitleY + 5;
//         const maxWidth = 100; // Set maximum width to control paragraph width
//         doc.setFontSize(10);
//         doc.text(termsText, pageWidth - 50, termsY, {align: 'center', maxWidth});
//
//         // Footer
//         doc.setFontSize(12);
//         doc.text('Thank you for your business!', 10, y + 10, {align: 'left'});
//
//         doc.save(`quote_${order?.orderId || 'quote'}.pdf`);
//
//     };
//
//
//     const handleDownloadDrawings = () => {
//         const link = document.createElement('a');
//         link.href = drawingsURL;
//         link.setAttribute('download', 'Article_Report.zip');
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };
//
//     const getStatusBars = () => {
//         const filledBars = status === ('Complete' || 'Delivered') ? 3 : status === ('In Production' || 'Shipped') ? 2 : status === 'Pending' ? 1 : 0;
//         const totalBars = 3;
//
//         return (
//             <div className="status-label">
//                 <label>Order Status: {status}</label>
//                 <div className="status-bar">
//                     {Array.from({length: totalBars}, (_, i) => (
//                         <div
//                             key={i}
//                             className={`status-bar-segment ${i < filledBars ? 'filled' : ''}`}
//                         />
//                     ))}
//                 </div>
//             </div>
//         );
//     };
//
//     const handleAdditionalPayments = (e) => {
//         const {totalAssemblyCost, totalDrawerCost, totalFinishingCost, totalHardwareCost} = invoiceData
//         e.preventDefault();
//         e.stopPropagation();
//         if ((includeHardware || includeAssembly || includeFinishing || includeDrawerboxes) && (!order?.hardwarePaid ||
//             !order?.assemblyPaid || !order?.finishingPaid || !order?.drawerBoxesPaid)) {
//             setIncludeSalesTax(true)
//         }
//
//         // setAdditionalPrice((totalAssemblyCost + totalDrawerCost + totalFinishingCost + totalHardwareCost) * 1.08875)
//         if (additionalPrice > 0) {
//             // console.log(additionalPrice)
//             setAdditionalPaymentModal(true);
//         }
//     };
//     return (
//         <div className="min-vh-100">
//             <div className="container my-5">
//                 {errorMessage && (
//                     <div className="d-flex justify-content-center align-items-center">
//                         <div className="text-center font-weight-bold">
//                             <strong>{errorMessage}</strong>
//                         </div>
//                     </div>
//                 )}
//                 {!errorMessage && invoiceData && (
//                     <>
//                         <h1 className="text-center mb-4">Order Details</h1>
//                         <div className="invoice-header">
//                             <div className="invoice-header-left">
//                                 <p>Order ID: {order?.orderId}</p>
//                                 <Link to={`/user/customer-portal/${orderId}/summary`}>View Item Details</Link>
//                                 <Link to={`/user/customer-portal/${orderId}/materials&hardware`}>View Order Component
//                                     Lists</Link>
//                             </div>
//                             <div className="invoice-header-right">
//                                 {getStatusBars()}
//                             </div>
//                         </div>
//
//                         {/* Invoice Template */}
//                         <div className="invoice-container">
//                             <div className="invoice-header">
//                                 <div className="company-info">
//                                     <h2>The Sealab</h2>
//                                     <p>63 Flushing Ave, Building 3 Suite 1108</p>
//                                     <p>Brooklyn NY 11205</p>
//                                     <p>info@thesealab.com</p>
//                                 </div>
//                                 <div className="invoice-details">
//                                     <h3>Invoice</h3>
//                                     <p><strong>Order ID:</strong> {order?.orderId}</p>
//                                     <p>
//                                         {/*<strong>Date:</strong> {order?.date ? format(new Date(order?.date), 'MM/dd/yyyy') : ''}*/}
//                                         <strong>Date:</strong> {order?.date ? format(new Date(order?.date + 'T00:00:00'), 'MM/dd/yyyy') : ''}
//
//                                     </p>
//                                     <p><strong>Remaining Revisions:</strong> {invoiceData.numOfRevisions}</p>
//                                 </div>
//                             </div>
//                             <div className="invoice-body">
//                                 <div className="bill-to">
//                                     <h4>Bill To:</h4>
//                                     <p>{user?.firstName} {user?.lastName}</p>
//                                     <p>{user?.address1} {user?.address2 === 'null' || null ? '' : user?.address2} {user?.apartment === 'null' || null ? '' : user?.apartment}</p>
//                                     <p>
//                                         {/*{user.city ? `${user?.city}, ` : ''}{user?.state} {user?.zipcode}*/}
//                                         {user?.city === 'null' || null || user?.city === '' ? '' : `${user?.city},`}{user?.state === 'null' || null ? '' : user?.state}{user?.zipcode === 'null' || null ? '' : user?.zipcode}
//                                     </p>
//                                 </div>
//                                 <div className="ship-to">
//                                     <h4>Project Address:</h4>
//                                     <p>Project Name: {order?.projectName}</p>
//                                     <p>Purchase Order: {order?.purchaseOrder}</p>
//                                     <p>
//                                         {order?.projectAddress1}
//                                         {order?.projectAddress2 ? ', ' + order.projectAddress2 : ''}
//                                     </p>
//                                     <p>
//                                         {order?.projectCity ? order.projectCity : ''}
//                                         {order?.projectState ? ', ' + order.projectState : ''}
//                                         {order?.projectZipcode ? ' ' + order.projectZipcode : ''}
//                                     </p>
//                                 </div>
//                             </div>
//                             <div className="invoice-items">
//                                 <table>
//                                     <thead>
//                                     <tr>
//                                         <th></th>
//                                         <th>Item Name</th>
//                                         <th>Description</th>
//                                         <th className="text-right">Item Price</th>
//                                     </tr>
//                                     </thead>
//                                     <tbody>
//                                     {Object.entries(invoiceData?.posPrice || {})
//                                         .sort(([posA], [posB]) => posA.localeCompare(posB))
//                                         .map(([pos, posPrice]) => (
//                                             <tr key={pos}>
//                                                 <td>
//                                                     <img
//                                                         src={`${thumbNail}${orderId}/${pos.includes('-') ? pos.split('-')[0] : pos}.png`}
//                                                         alt={pos} className="invoice-img"/>
//                                                 </td>
//                                                 <td className="invoice-td-text">{pos}</td>
//                                                 <td className="invoice-td-text">Dimensions: {invoiceData.dimensions[pos]}
//                                                     <br/>Quantity: {invoiceData.quantity[pos]}</td>
//                                                 <td className="invoice-td-text text-right">${posPrice.toFixed(2)}</td>
//                                             </tr>
//                                         ))
//                                     }
//                                     </tbody>
//                                 </table>
//                             </div>
//                             <div className="invoice-total text-right">
//                                 <p>Sub Total: ${invoiceData?.totalPrice?.toFixed(2)}</p>
//                                 <p>Sales Tax: 8.875%</p>
//                                 <p>Total: ${(invoiceData?.totalPrice * salesTax)?.toFixed(2)}
//                                     {additionalPrice !== 0 && order?.invoiceTxId !== null ?
//                                         <>
//                                             <p
//                                                 className="invoice-amt-paid"> (Paid
//                                                 on {format(parseISO(invoiceData.date), 'MM/dd/yyyy')}):
//                                                 {/*${((invoiceData?.totalPrice - additionalPrice) * 1.08875).toFixed(2)}*/}
//                                                 ${(invoiceData?.amountPaid * 1.08875).toFixed(2)}
//                                             </p>
//                                             <p className="testing8">Due Prior to Shipping:
//                                                 {/*${(additionalPrice * salesTax).toFixed(2)}</p>*/}
//                                                 ${(additionalPrice).toFixed(2)}</p>
//                                         </>
//                                         : ''}
//
//                                 </p>
//                             </div>
//                             <div className="invoice-footer">
//                                 <div className="not-included">
//                                     <p>*Included*</p>
//                                     <form>
//                                         <div className="form-check">
//                                             <input
//                                                 className="form-check-input"
//                                                 type="checkbox"
//                                                 checked={order?.includeHardware === 'true'}
//                                                 disabled={order?.hardwarePaid}
//                                                 onChange={(e) => handleCheckboxChange('includeHardware', e.target.checked)}
//                                             />
//                                             <label className="form-check-label">
//                                                 Hardware
//                                             </label>
//                                         </div>
//                                         <div className="form-check">
//                                             <input
//                                                 className="form-check-input"
//                                                 type="checkbox"
//                                                 checked={order?.includeAssembly === 'true'}
//                                                 disabled={order?.assemblyPaid}
//                                                 onChange={(e) => handleCheckboxChange('includeAssembly', e.target.checked)}
//                                             />
//                                             <label className="form-check-label">
//                                                 Assembly
//                                             </label>
//                                         </div>
//                                         <div className="form-check">
//                                             <input
//                                                 className="form-check-input"
//                                                 type="checkbox"
//                                                 checked={order?.includeFinishing === 'true'}
//                                                 disabled={order?.finishingPaid}
//                                                 onChange={(e) => handleCheckboxChange('includeFinishing', e.target.checked)}
//                                             />
//                                             <label className="form-check-label">
//                                                 Finishing
//                                             </label>
//                                         </div>
//                                         <div className="form-check">
//                                             <input
//                                                 className="form-check-input"
//                                                 type="checkbox"
//                                                 checked={order?.includeDrawerboxes === 'true'}
//                                                 disabled={order?.drawerBoxesPaid}
//                                                 onChange={(e) => handleCheckboxChange('includeDrawerboxes', e.target.checked)}
//                                             />
//                                             <label className="form-check-label">
//                                                 Drawerboxes
//                                             </label>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                             {status === "Pending" && (
//                                 <div className="pay-btn-div">
//                                     {/*<button className="btn btn-dark mb-4" id="pay-now-btn" onClick={handlePayment}*/}
//                                     <button className="btn btn-dark mb-4" id="pay-now-btn" onClick={(e) => {
//                                         e.preventDefault();
//                                         e.stopPropagation();
//                                         setShowPaymentModal(true);
//                                     }}
//                                             type="button">
//                                         Pay Now
//                                     </button>
//                                     <button className="btn btn-dark mb-4" onClick={handleEditOrder}>
//                                         Edit Order
//                                     </button>
//                                 </div>
//                             )}
//                             {/*{status !== "Pending" && additionalPrice > 0.0 && (order.includeAssembly === 'true' || order.includeDrawerboxes === 'true' || order.includeFinishing === 'true'*/}
//                             {/*    || order.includeHardware === 'true') && (!order.hardwarePaid || !order.assemblyPaid || !order.finishingPaid || !order.drawerBoxesPaid) && (*/}
//
//                             {status !== "Pending" && additionalPrice > 0.0 && (order.includeAssembly === 'true' || order.includeDrawerboxes === 'true' || order.includeFinishing === 'true'
//                                 || order.includeHardware === 'true') && (!order.hardwarePaid || !order.assemblyPaid || !order.finishingPaid || !order.drawerBoxesPaid) && (
//
//                                 <div className="pay-btn-div">
//                                     <button className="btn btn-dark mb-4" id="pay-now-btn"
//                                             onClick={handleAdditionalPayments}>
//                                         Pay Now
//
//                                     </button>
//                                 </div>
//                             )}
//
//                             <div className="terms-section text-right">
//                                 <p><strong>*TERMS*</strong></p>
//                                 <p>Payment due upon receipt. NYC sales tax applied where applicable. Local pickup
//                                     only at this time. Custom orders are non-refundable. Order modifications may
//                                     incur charges. We reserve the right to correct errors. Full terms at <a
//                                         href="https://www.thesealab.com/the-sealab/legal/terms">www.thesealab.com/the-sealab/legal/terms</a>.
//                                     By accepting delivery, customer agrees to all terms and conditions.</p>
//                             </div>
//                         </div>
//                         <button className="btn btn-dark mb-4" onClick={downloadInvoicePDF}>
//                             Download Quote as PDF
//                         </button>
//
//                         <div className="row">
//                             <div className="col-md-6 mb-4">
//                                 <h2>Drawings</h2>
//                                 <button onClick={handleDownloadDrawings} className="btn btn-dark mb-4">
//                                     Download Article_Report.zip
//                                 </button>
//                             </div>
//                         </div>
//                     </>
//                 )}
//             </div>
//             <PaymentModal show={showPaymentModal} onHide={handleClosePaymentModal} orderId={orderId}
//                           price={(invoiceData?.totalPrice * salesTax).toFixed(2)}
//                           processingFee={false} invoicePayment={true} showOptions={true}/>
//             <PaymentModal show={additionalPaymentModal} onHide={handleClosePaymentModal} orderId={orderId}
//                           price={(additionalPrice).toFixed(2)} processingFee={false} invoicePayment={true}/>
//
//             <EditOrderAgreement
//                 show={showEditOrderAgreement}
//                 onHide={() => setShowEditOrderAgreement(false)}
//                 onAgree={handleAgreeEditOrder}
//                 orderId={orderId}
//             />
//             <EditOrderAgreement
//                 show={showRevisionsModal}
//                 onHide={() => setShowRevisionsModal(false)}
//                 orderId={orderId}
//                 outOfRevisions
//             />
//         </div>
//     );
// };
//
// export default OrderDetailsComponent;


import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import './OrderDetailsStyles.css';
import 'jspdf-autotable';
import PaymentModal from "../../../modals/PaymentModal";
import {format, parseISO} from "date-fns";
import EditOrderAgreement from '../../../modals/EditOrderAgreement';
import {fetchArticlesByOrderId} from '../../../api/OrderService';
import {reAddToCart, setIsRevisedOrder, setOrderInfo} from '../../../features/cart/cartSlice';
import sealabIcon from '../../../images/sealab_icon.png';
import checkmarkImg from '../../../images/checkmark.png';

const OrderDetailsComponent = () => {
    const user = useSelector(state => state.user.user);
    const {orderId} = useParams();
    const [order, setOrder] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const token = useSelector(state => state.user.accessToken);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [additionalPaymentModal, setAdditionalPaymentModal] = useState(false);
    const [additionalPrice, setAdditionalPrice] = useState(0.00);
    const [showEditOrderAgreement, setShowEditOrderAgreement] = useState(false);
    const [showRevisionsModal, setShowRevisionsModal] = useState(false);
    // These states hold the current toggle values (as strings "true" or "false") for options that are not paid.
    const [includeHardware, setIncludeHardware] = useState("false");
    const [includeAssembly, setIncludeAssembly] = useState("false");
    const [includeFinishing, setIncludeFinishing] = useState("false");
    const [includeDrawerboxes, setIncludeDrawerboxes] = useState("false");
    const [includeSalesTax, setIncludeSalesTax] = useState(false);
    const [status, setStatus] = useState('');

    // totalsChanged is true if any not‑paid option has been toggled away from its original value.
    const [totalsChanged, setTotalsChanged] = useState(false);
    const baseURL = process.env.REACT_APP_BASEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;
    const drawingsURL = `${process.env.REACT_APP_THMBNAIL}${orderId}/Article_Report.zip`;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const salesTax = 1.08875;
    const fetchCalled = useRef(false);
    // Save the originally fetched (toggleable) options from the API so we know if the user has made changes.
    const originalOptions = useRef(null);

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
        setAdditionalPaymentModal(false);
    };

    const handleEditOrder = () => {
        if (invoiceData?.numOfRevisions > 0) {
            setShowEditOrderAgreement(true);
        } else {
            setShowRevisionsModal(true);
        }
    };


    const handleAgreeEditOrder = async () => {
        try {
            const response = await fetchArticlesByOrderId(orderId, token);
            const articlesData = response.data.articles;
            const orderInfo = response.data.orderInfo;

            Object.entries(articlesData).forEach(([key, articleGroup]) => {
                if (articleGroup.length === 1) {
                    dispatch(reAddToCart({...articleGroup[0], itemId: articleGroup[0].articleId, generatePos: false}));
                } else if (articleGroup.length > 1) {
                    const firstArticle = articleGroup[0];
                    const groupedArticle = {
                        ...firstArticle,
                        itemId: firstArticle.articleId,
                        quantity: articleGroup.length,
                        positionName: firstArticle.positionName
                    };
                    dispatch(reAddToCart(groupedArticle));
                }
            });

            dispatch(setOrderInfo(orderInfo));
            dispatch(setIsRevisedOrder(true));
            setShowEditOrderAgreement(false);
            navigate('/cart', {state: {orderId: orderId}});
        } catch (error) {
            console.error('Error fetching articles by order ID:', error);
        }
    };

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const [orderResponse, statusResponse, invoiceResponse] = await Promise.all([
                    axios.get(`${baseURL}/api/orders/${orderId}`, {
                        headers: {'Authorization': `Bearer ${token}`}
                    }),
                    axios.get(`${baseURL}/user/customer-portal/${orderId}/getStatus`, {
                        headers: {'Authorization': `Bearer ${token}`}
                    }),
                    axios.post(`${baseURL}/user/customer-portal/${orderId}`, {
                        includeHardware: order?.includeHardware,
                        includeAssembly: order?.includeAssembly,
                        includeFinishing: order?.includeFinishing,
                        includeDrawerboxes: order?.includeDrawerboxes
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                ]);
                const fetchedOrder = orderResponse.data;
                setOrder(fetchedOrder);
                setStatus(statusResponse.data);
                setInvoiceData(invoiceResponse.data);

                // Set the toggle states from the fetched order.
                setIncludeHardware(fetchedOrder.includeHardware && fetchedOrder.hardwarePaid);
                setIncludeAssembly(fetchedOrder.includeAssembly && fetchedOrder.assemblyPaid);
                setIncludeFinishing(fetchedOrder.includeFinishing && fetchedOrder.finishingPaid);
                setIncludeDrawerboxes(fetchedOrder.includeDrawerboxes && fetchedOrder.drawerBoxesPaid);

                // Save original toggleable options for later comparison.
                originalOptions.current = {
                    includeHardware: fetchedOrder.includeHardware,
                    includeAssembly: fetchedOrder.includeAssembly,
                    includeFinishing: fetchedOrder.includeFinishing,
                    includeDrawerboxes: fetchedOrder.includeDrawerboxes
                };

                const {
                    totalAssemblyCost,
                    totalDrawerCost,
                    totalFinishingCost,
                    totalHardwareCost,
                    remainingBalance
                } = invoiceResponse.data;
                const calcAdditionalPrice = (totalAssemblyCost + totalFinishingCost + totalDrawerCost + totalHardwareCost) + remainingBalance;
                setAdditionalPrice(calcAdditionalPrice);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 404) {
                    setErrorMessage("Sorry. Your order is still being processed. You will receive an email when your order has been processed.");
                } else {
                    setErrorMessage("An error occurred while fetching data. Please try again later.");
                }
            }
        };
        fetchOrderDetails();
    }, [orderId, token, baseURL]);

    // Recalculate totalsChanged whenever a toggleable state changes.
    useEffect(() => {
        if (originalOptions.current && order) {
            let changed = false;
            if (!order.hardwarePaid && includeHardware !== originalOptions.current.includeHardware) changed = true;
            if (!order.assemblyPaid && includeAssembly !== originalOptions.current.includeAssembly) changed = true;
            if (!order.finishingPaid && includeFinishing !== originalOptions.current.includeFinishing) changed = true;
            if (!order.drawerBoxesPaid && includeDrawerboxes !== originalOptions.current.includeDrawerboxes) changed = true;
            // setTotalsChanged(changed);
        }
    }, [includeHardware, includeAssembly, includeFinishing, includeDrawerboxes, order]);

    // When a checkbox is clicked, update the toggle state via API.
    const handleCheckboxChange = async (name, value) => {
        const updatedValue = String(value);
        // Prepare an updated order object with the new value.
        const updatedOrder = {...order, [name]: updatedValue};
        try {
            const response = await axios.post(`${baseURL}/user/customer-portal/${orderId}`, {
                includeHardware: name === 'includeHardware' ? updatedValue : order.includeHardware,
                includeAssembly: name === 'includeAssembly' ? updatedValue : order.includeAssembly,
                includeFinishing: name === 'includeFinishing' ? updatedValue : order.includeFinishing,
                includeDrawerboxes: name === 'includeDrawerboxes' ? updatedValue : order.includeDrawerboxes
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            // Update the order object if needed.
            setOrder(updatedOrder);
            setInvoiceData(response.data)
            // Update the corresponding toggle state.
            if (name === 'includeHardware') setIncludeHardware(updatedValue);
            if (name === 'includeAssembly') setIncludeAssembly(updatedValue);
            if (name === 'includeFinishing') setIncludeFinishing(updatedValue);
            if (name === 'includeDrawerboxes') setIncludeDrawerboxes(updatedValue);

            const {
                totalAssemblyCost,
                totalDrawerCost,
                totalFinishingCost,
                totalHardwareCost,
                remainingBalance
            } = response.data;
            const newAdditionalPrice = (totalAssemblyCost + totalFinishingCost + totalDrawerCost + totalHardwareCost) + (remainingBalance || 0);
            if (totalAssemblyCost > 0 || totalFinishingCost > 0 || totalDrawerCost > 0 || totalHardwareCost > 0) {
                setTotalsChanged(true);
            } else {
                setTotalsChanged(false);

            }
            setAdditionalPrice(newAdditionalPrice);
        } catch (error) {
            console.error('Error updating options:', error);
        }
    };

    const downloadInvoicePDF = async () => {
        const {jsPDF} = await import("jspdf");
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [210, 330]
        });

        // Company logo and info
        doc.addImage(sealabIcon, 'PNG', 10, 10, 30, 30);
        const pageWidth = doc.internal.pageSize.getWidth();
        const companyInfo = [
            'The Sealab',
            '63 Flushing Ave',
            'Building 3, Suite 1108',
            'Brooklyn, NY 11205',
            'info@thesealab.com'
        ];
        doc.setFontSize(10);
        let startY = 15;
        companyInfo.forEach((line) => {
            doc.text(line, pageWidth - 10, startY, {align: 'right'});
            startY += 5;
        });

        // Invoice title, order ID, and date
        const centerY = 25;
        doc.setFontSize(16);
        doc.text('Invoice', pageWidth / 2, 15, {align: 'center'});
        doc.setFontSize(12);
        doc.text(`Order ID: ${order?.orderId || ''}`, pageWidth / 2, centerY, {align: 'center'});
        doc.text(`Date: ${invoiceData?.date || ''}`, pageWidth / 2, centerY + 5, {align: 'center'});
        let currentY = startY + 10;

        // Bill To and Project Address info
        doc.setFontSize(14);
        doc.text('Bill To:', 10, currentY + 25);
        const projectAddressX = pageWidth - 50;
        doc.text('Project Address:', projectAddressX, currentY + 25);
        doc.setFontSize(12);
        currentY += 35;
        const addText = (text, x, y) => {
            if (text) {
                doc.text(text, x, y);
            }
        };
        addText(`${user?.firstName || ''} ${user?.lastName || ''}`.trim(), 10, currentY);
        addText(`${user?.address1 || ''} ${user?.address2 || ''} ${user?.apartment || ''}`.trim(), 10, currentY + 5);
        addText(`${user?.city ? `${user.city}, ` : ''}${user?.state || ''} ${user?.zipcode || ''}`.trim(), 10, currentY + 10);
        addText(order?.projectName || '', projectAddressX, currentY);
        addText(order?.purchaseOrder || '', projectAddressX, currentY + 5);
        addText(`${order?.projectAddress1 || ''} ${order?.projectAddress2 || ''}`.trim(), projectAddressX, currentY + 10);
        const projectLocation = [order?.projectCity || '', order?.projectState || '', order?.projectZipcode || ''].filter(Boolean).join(', ');
        addText(projectLocation, projectAddressX, currentY + 10);
        currentY += 20;
        const tableData = Object.entries(invoiceData?.posPrice || {}).map(([pos, posPrice]) => [
            pos,
            `Dimensions: ${invoiceData?.dimensions[pos] || ''} \nQuantity: ${invoiceData?.quantity[pos] || ''}`.trim(),
            `$${posPrice?.toFixed(2) || '0.00'}`
        ]);
        doc.autoTable({
            head: [['Item Name', 'Description', 'Item Price']],
            body: tableData,
            startY: currentY + 10,
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255], fontSize: 12},
            bodyStyles: {fontSize: 12},
        });
        const totalY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(14);
        doc.text(`Subtotal: $${invoiceData?.totalPrice?.toFixed(2) || '0.00'}${totalsChanged ? ' *' : ''}`, pageWidth - 15, totalY, {align: 'right'});
        doc.text(`Sales Tax: 8.875%`, pageWidth - 15, totalY + 7, {align: 'right'});
        doc.text(`Total: $${(invoiceData?.totalPrice * salesTax).toFixed(2)}${totalsChanged ? ' *' : ''}`, pageWidth - 15, totalY + 14, {align: 'right'});

        // (The PDF version also shows the included / not included options.)
        const includedItems = [];
        const notIncludedItems = [];
        if (order) {
            if (order.hardwarePaid) {
                includedItems.push({key: 'includeHardware', label: 'Hardware'});
            } else {
                notIncludedItems.push({key: 'includeHardware', label: 'Hardware'});
            }
            if (order.assemblyPaid) {
                includedItems.push({key: 'includeAssembly', label: 'Assembly'});
            } else {
                notIncludedItems.push({key: 'includeAssembly', label: 'Assembly'});
            }
            if (order.finishingPaid) {
                includedItems.push({key: 'includeFinishing', label: 'Finishing'});
            } else {
                notIncludedItems.push({key: 'includeFinishing', label: 'Finishing'});
            }
            if (order.drawerBoxesPaid) {
                includedItems.push({key: 'includeDrawerboxes', label: 'Drawerboxes'});
            } else {
                notIncludedItems.push({key: 'includeDrawerboxes', label: 'Drawerboxes'});
            }
        }
        let currentSectionY = totalY + 30;
        doc.setFontSize(12);
        if (includedItems.length > 0) {
            doc.text('*Included*', 10, currentSectionY);
            currentSectionY += 6;
            includedItems.forEach(item => {
                doc.text('[', 10, currentSectionY);
                doc.addImage(checkmarkImg, 'PNG', 11, currentSectionY - 3, 3.5, 3.5);
                doc.text(']', 14, currentSectionY);
                doc.text(item.label, 18, currentSectionY);
                currentSectionY += 6;
            });
        }
        if (notIncludedItems.length > 0) {
            currentSectionY += 10;
            doc.text('*Not Included*', 10, currentSectionY);
            currentSectionY += 5;
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(10);
            doc.text('To add these to your order, please apply additional payment', 10, currentSectionY);
            doc.setTextColor(0, 0, 0);
            currentSectionY += 6;
            doc.setFontSize(12);
            notIncludedItems.forEach(item => {
                doc.text('[ ]', 10, currentSectionY);
                doc.text(item.label, 18, currentSectionY);
                currentSectionY += 6;
            });
        }
        doc.setFontSize(12);
        doc.text('Thank you for your business!', 10, currentSectionY + 10, {align: 'left'});
        doc.save(`quote_${order?.orderId || 'quote'}.pdf`);
    };

    const handleDownloadDrawings = () => {
        const link = document.createElement('a');
        link.href = drawingsURL;
        link.setAttribute('download', 'Article_Report.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getStatusBars = () => {
        const filledBars = (status === ('Complete' || 'Delivered')) ? 3 :
            (status === ('In Production' || 'Shipped')) ? 2 :
                (status === 'Pending') ? 1 : 0;
        const totalBars = 3;
        return (
            <div className="status-label">
                <label>Order Status: {status}</label>
                <div className="status-bar">
                    {Array.from({length: totalBars}, (_, i) => (
                        <div key={i} className={`status-bar-segment ${i < filledBars ? 'filled' : ''}`}/>
                    ))}
                </div>
            </div>
        );
    };

    const handleAdditionalPayments = (e) => {
        const {totalAssemblyCost, totalDrawerCost, totalFinishingCost, totalHardwareCost} = invoiceData;
        e.preventDefault();
        e.stopPropagation();
        if ((includeHardware === "true" || includeAssembly === "true" || includeFinishing === "true" || includeDrawerboxes === "true") &&
            (!order?.hardwarePaid || !order?.assemblyPaid || !order?.finishingPaid || !order?.drawerBoxesPaid)) {
            setIncludeSalesTax(true);
        }
        if (additionalPrice > 0) {
            setAdditionalPaymentModal(true);
        }
    };

    return (
        <div className="min-vh-100">
            <div className="container my-5">
                {errorMessage && (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center font-weight-bold">
                            <strong>{errorMessage}</strong>
                        </div>
                    </div>
                )}
                {!errorMessage && invoiceData && (
                    <>
                        <h1 className="text-center mb-4">Order Details</h1>
                        <div className="invoice-header">
                            <div className="invoice-header-left">
                                <p>Order ID: {order?.orderId}</p>
                                <Link to={`/user/customer-portal/${orderId}/summary`}>View Item Details</Link>
                                <Link to={`/user/customer-portal/${orderId}/materials&hardware`}>View Order Component
                                    Lists</Link>
                            </div>
                            <div className="invoice-header-right">
                                {getStatusBars()}
                            </div>
                        </div>

                        {/* Invoice Template */}
                        <div className="invoice-container">
                            <div className="invoice-header">
                                <div className="company-info">
                                    <h2>The Sealab</h2>
                                    <p>63 Flushing Ave, Building 3 Suite 1108</p>
                                    <p>Brooklyn NY 11205</p>
                                    <p>info@thesealab.com</p>
                                </div>
                                <div className="invoice-details">
                                    <h3>Invoice</h3>
                                    <p><strong>Order ID:</strong> {order?.orderId}</p>
                                    <p>
                                        <strong>Date:</strong> {order?.date ? format(new Date(order?.date + 'T00:00:00'), 'MM/dd/yyyy') : ''}
                                    </p>
                                    <p><strong>Remaining Revisions:</strong> {invoiceData.numOfRevisions}</p>
                                </div>
                            </div>
                            <div className="invoice-body">
                                <div className="bill-to">
                                    <h4>Bill To:</h4>
                                    <p>{user?.firstName} {user?.lastName}</p>
                                    <p>{user?.address1} {user?.address2 === 'null' || null ? '' : user?.address2} {user?.apartment === 'null' || null ? '' : user?.apartment}</p>
                                    <p>{user?.city === 'null' || null || user?.city === '' ? '' : `${user?.city},`}{user?.state === 'null' || null ? '' : user?.state}{user?.zipcode === 'null' || null ? '' : user?.zipcode}</p>
                                </div>
                                <div className="ship-to">
                                    <h4>Project Address:</h4>
                                    <p>Project Name: {order?.projectName}</p>
                                    <p>Purchase Order: {order?.purchaseOrder}</p>
                                    <p>{order?.projectAddress1}{order?.projectAddress2 ? ', ' + order.projectAddress2 : ''}</p>
                                    <p>{order?.projectCity ? order.projectCity : ''}{order?.projectState ? ', ' + order.projectState : ''}{order?.projectZipcode ? ' ' + order.projectZipcode : ''}</p>
                                </div>
                            </div>
                            <div className="invoice-items">
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th className="text-right">Item Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(invoiceData?.posPrice || {})
                                        .sort(([posA], [posB]) => posA.localeCompare(posB))
                                        .map(([pos, posPrice]) => (
                                            <tr key={pos}>
                                                <td>
                                                    <img
                                                        src={`${thumbNail}${orderId}/${pos.includes('-') ? pos.split('-')[0] : pos}.png`}
                                                        alt={pos} className="invoice-img"/>
                                                </td>
                                                <td className="invoice-td-text">{pos}</td>
                                                <td className="invoice-td-text">
                                                    Dimensions: {invoiceData.dimensions[pos]}<br/>
                                                    Quantity: {invoiceData.quantity[pos]}
                                                </td>
                                                <td className="invoice-td-text text-right">${posPrice.toFixed(2)} {totalsChanged && (
                                                    <span className="changed-asterisk">*</span>)}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="invoice-total text-right">
                                <p>Sub Total: ${invoiceData?.totalPrice?.toFixed(2)} {totalsChanged &&
                                    <span className="changed-asterisk">*</span>}</p>
                                <p>Sales Tax: 8.875%</p>
                                <p>Total: ${(invoiceData?.totalPrice * salesTax)?.toFixed(2)} {totalsChanged &&
                                    <span className="changed-asterisk">*</span>}</p>
                                {additionalPrice !== 0 && order?.invoiceTxId !== null && (
                                    <>
                                        <p className="invoice-amt-paid">(Paid
                                            on {format(parseISO(invoiceData.date), 'MM/dd/yyyy')}):
                                            ${(invoiceData?.amountPaid * salesTax).toFixed(2)}</p>
                                        <p className="testing8">
                                            Due Prior to Shipping: ${(additionalPrice).toFixed(2)} {totalsChanged &&
                                            <span className="changed-asterisk">*</span>}
                                        </p>
                                        {totalsChanged && (
                                            <p className="offer-message">
                                                *Totals reflect new offer. To commit, please apply additional payment.*
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="invoice-footer">
                                {order && (
                                    <>
                                        {(order.hardwarePaid || order.assemblyPaid || order.finishingPaid || order.drawerBoxesPaid) && (
                                            <div className="included-options">
                                                <h4>Included</h4>
                                                {order.hardwarePaid && (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={order?.includeHardware === 'true'} disabled
                                                               readOnly/>
                                                        <label className="form-check-label">Hardware</label>
                                                    </div>
                                                )}
                                                {order.assemblyPaid && (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={order?.includeAssembly === 'true'} disabled
                                                               readOnly/>
                                                        <label className="form-check-label">Assembly</label>
                                                    </div>
                                                )}
                                                {order.finishingPaid && (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={order?.includeFinishing === 'true'} disabled
                                                               readOnly/>
                                                        <label className="form-check-label">Finishing</label>
                                                    </div>
                                                )}
                                                {order.drawerBoxesPaid && (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={order?.includeDrawerboxes === 'true'} disabled
                                                               readOnly/>
                                                        <label className="form-check-label">Drawerboxes</label>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {!(order.hardwarePaid && order.assemblyPaid && order.finishingPaid && order.drawerBoxesPaid) && (
                                            <div className="not-included-options">
                                                <h4>Not Included</h4>
                                                <p className="warning-text">To include these services to your order,
                                                    please apply
                                                    additional payment</p>
                                                <form>
                                                    {!order?.hardwarePaid && (
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={includeHardware === 'true'}
                                                                onChange={(e) => handleCheckboxChange('includeHardware', e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Hardware</label>
                                                        </div>
                                                    )}
                                                    {!order?.assemblyPaid && (
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={includeAssembly === 'true'}
                                                                onChange={(e) => handleCheckboxChange('includeAssembly', e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Assembly</label>
                                                        </div>
                                                    )}
                                                    {!order?.finishingPaid && (
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={includeFinishing === 'true'}
                                                                onChange={(e) => handleCheckboxChange('includeFinishing', e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Finishing</label>
                                                        </div>
                                                    )}
                                                    {!order?.drawerBoxesPaid && (
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={includeDrawerboxes === 'true'}
                                                                onChange={(e) => handleCheckboxChange('includeDrawerboxes', e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Drawerboxes</label>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            {status === "Pending" && (
                                <div className="pay-btn-div">
                                    <button className="btn btn-dark mb-4" id="pay-now-btn" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowPaymentModal(true);
                                    }} type="button">
                                        Pay Now
                                    </button>
                                    <button className="btn btn-dark mb-4" onClick={handleEditOrder}>
                                        Edit Order
                                    </button>
                                </div>
                            )}
                            {status !== "Pending" && additionalPrice > 0.0 &&
                                (order.includeAssembly === 'true' || order.includeDrawerboxes === 'true' || order.includeFinishing === 'true' || order.includeHardware === 'true') &&
                                (!order.hardwarePaid || !order.assemblyPaid || !order.finishingPaid || !order.drawerBoxesPaid) && (
                                    <div className="pay-btn-div">
                                        <button className="btn btn-dark mb-4" id="pay-now-btn"
                                                onClick={handleAdditionalPayments}>
                                            Pay Now
                                        </button>
                                    </div>
                                )}

                            <div className="terms-section text-right">
                                <p><strong>*TERMS*</strong></p>
                                <p>
                                    Payment due upon receipt. NYC sales tax applied where applicable. Local pickup only
                                    at this time.
                                    Custom orders are non-refundable. Order modifications may incur charges. We reserve
                                    the right to correct errors.
                                    Full terms at <a
                                    href="https://www.thesealab.com/the-sealab/legal/terms">www.thesealab.com/the-sealab/legal/terms</a>.
                                    By accepting delivery, customer agrees to all terms and conditions.
                                </p>
                            </div>
                        </div>
                        <button className="btn btn-dark mb-4" onClick={downloadInvoicePDF}>
                            Download Quote as PDF
                        </button>

                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <h2>Drawings</h2>
                                <button onClick={handleDownloadDrawings} className="btn btn-dark mb-4">
                                    Download Article_Report.zip
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <PaymentModal
                show={showPaymentModal}
                onHide={handleClosePaymentModal}
                orderId={orderId}
                price={(invoiceData?.totalPrice * salesTax).toFixed(2)}
                processingFee={false}
                invoicePayment={true}
                showOptions={true}
            />
            <PaymentModal
                show={additionalPaymentModal}
                onHide={handleClosePaymentModal}
                orderId={orderId}
                price={(additionalPrice).toFixed(2)}
                processingFee={false}
                invoicePayment={true}
            />
            <EditOrderAgreement
                show={showEditOrderAgreement}
                onHide={() => setShowEditOrderAgreement(false)}
                onAgree={handleAgreeEditOrder}
                orderId={orderId}
            />
            <EditOrderAgreement
                show={showRevisionsModal}
                onHide={() => setShowRevisionsModal(false)}
                orderId={orderId}
                outOfRevisions
            />
        </div>
    );
};

export default OrderDetailsComponent;

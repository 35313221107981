import React from 'react';
import {Loader2} from 'lucide-react';

// Adding custom animation styles
const spinnerStyle = {
    animation: 'loading-spin 1s linear infinite'
};

const LoadingPage = () => {
    return (
        <>
            <style>
                {`
          @keyframes loading-spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
            </style>
            <div id="loading-container"
                 className="min-vh-100 bg-white d-flex align-items-center justify-content-center">
                <div id="loading-content" className="text-center">
                    <div id="loading-spinner" className="mb-4">
                        <Loader2 style={spinnerStyle} size={48} className="text-dark"/>
                    </div>

                    <h2 id="loading-title" className="h4 mb-3 text-dark">
                        Loading your content
                    </h2>

                    <div id="loading-progress" className="position-relative mx-auto" style={{width: '200px'}}>
                        <div className="progress" style={{height: '4px'}}>
                            <div
                                id="loading-progress-bar"
                                className="progress-bar bg-dark"
                                role="progressbar"
                                style={{width: '75%'}}
                                aria-valuenow={75}
                                aria-valuemin={0}
                                aria-valuemax={100}>
                            </div>
                        </div>
                    </div>

                    <p id="loading-message" className="mt-3 text-secondary small">
                        Please wait while we prepare your experience
                    </p>
                </div>
            </div>
        </>
    );
};

export default LoadingPage;
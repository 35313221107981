import {useEffect, useState} from 'react';
import axios from 'axios';
import {URLS} from '../constants/urls';

export const useOrderData = (article, order, orderId, positionName, token) => {
    const [orderData, setOrderData] = useState({
        caseSqft: null,
        frontSqft: null,
        backSqft: null,
        ebPrfName: null,
        ebSqft: null,
        hardware: {},
        drawers: [],
        article: article,
        caseMaterial: '',
        frontMaterial: '',
        errorMessage: null
    });
    const [isLoading, setIsLoading] = useState(true);

    const fetchOrderSummary = async (url, body) => {
        try {
            const response = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const data = response.data;
                setOrderData(prev => ({
                    ...prev,
                    caseSqft: data.caseSqft,
                    frontSqft: data.frontSqft,
                    backSqft: data?.backSqft,
                    ebPrfName: data.ebPrfName,
                    ebSqft: data.edgeBanding,
                    hardware: data.hardware || {},
                    drawers: data.drawers,
                    article: data.article
                }));
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setOrderData(prev => ({
                    ...prev,
                    errorMessage: "Sorry. Your order is still being processed. You will receive an email when your order has been processed."
                }));
            }
            console.error('Error fetching order summary:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setIsLoading(true);

        const fetchData = async () => {
            if (article) {
                const body = {
                    caseMaterial: article.caseMaterial,
                    frontMaterial: article.frontMaterial,
                    backPanelMaterial: article.backPanelMaterial,
                };

                if (order) {
                    await fetchOrderSummary(`${URLS.BASE_URL}/api/orders/${order?.orderId}/summary/${article.positionName}`, body);
                }
            } else if (orderId && positionName) {
                try {
                    const response = await axios.get(
                        `${URLS.BASE_URL}/api/orders/${orderId}/summary/${positionName}/getMaterials`,
                        {signal: controller.signal}
                    );

                    if (response.status === 200 && isMounted) {
                        const {caseMaterial, frontMaterial, backPanelMaterial} = response.data;
                        setOrderData(prev => ({
                            ...prev,
                            caseMaterial,
                            frontMaterial
                        }));


                        const body = {caseMaterial, frontMaterial, backPanelMaterial};
                        await fetchOrderSummary(`${URLS.BASE_URL}/api/orders/${orderId}/summary/${positionName}`, body);
                    }
                } catch (error) {
                    if (error.name !== 'AbortError' && isMounted) {
                        console.error('Error fetching materials:', error);
                    }
                } finally {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [article, order, orderId, positionName, token]);

    return {...orderData, isLoading};
};
// src/utils/fileHandlers.js
export const handleDownloadDWG = async (finalOrderId, article) => {
    const dwgURL = process.env.REACT_APP_THMBNAIL + `${finalOrderId}/Article_Report/${article.positionName}.dwg`;

    try {
        const response = await fetch(dwgURL);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`);
        }

        const blob = await response.blob();
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `${article.positionName}.dwg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import './LandingStyles.css';
import {useNavigate} from "react-router-dom";
import {Helmet} from 'react-helmet-async'
import ShowRoomComponent from "../ShowRoomComponent/ShowRoomComponent";

const LandingPage = () => {
    const userLoggedIn = UserLoggedIn();
    const user = useSelector(state => state.user.user) || {};
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"})
    },);

    return (
        <div>
            <Helmet>
                <title>The Sealab - Premium Kitchen & Closet Cabinets</title>
                <meta name="description"
                      content="Transform your home with The Sealab's premium kitchen and closet cabinets. Explore our high-quality, customizable cabinetry solutions designed to meet your unique needs."/>
                <meta name="keywords"
                      content="kitchen cabinets, closet cabinets, premium cabinets, high-quality cabinets, customizable cabinetry, home transformation, The Sealab"/>
                <meta property="og:title" content="The Sealab - Premium Kitchen & Closet Cabinets"/>
                <meta property="og:description"
                      content="Transform your home with The Sealab's premium kitchen and closet cabinets. Explore our high-quality, customizable cabinetry solutions designed to meet your unique needs."/>
                <meta property="og:url" content="https://www.thesealab.com"/>
                <meta property="og:type" content="website"/>
            </Helmet>


            {userLoggedIn ? (
                <>
                    <ShowRoomComponent/>
                </>
            ) : (
                <>
                    <ShowRoomComponent/>
                </>
            )}

        </div>

    );
};

export default LandingPage;

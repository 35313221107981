import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    orderData: {
        materialSqft: null,
        hardwareQuantity: null,
        sppData: null,
        drawerData: null,
        drawerFronts: [],
        doorDimensions: [],
        blindFronts: [],
        prfData: []
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    lastFetchedOrder: null
};

export const fetchOrderSummary = createAsyncThunk(
    'order/fetchOrderSummary',
    async ({orderId, token, baseURL}, {rejectWithValue}) => {
        try {
            const config = {headers: {'Authorization': `Bearer ${token}`}};
            const response = await axios.post(
                `${baseURL}/user/customer-portal/${orderId}/getOrderSummary`,
                null,
                config
            );

            const roundedMaterialSqft = Object.fromEntries(
                Object.entries(response.data.materialSqft)
                    .map(([matId, sqft]) => [matId, parseFloat(sqft.toFixed(2))])
            );

            return {
                materialSqft: roundedMaterialSqft,
                hardwareQuantity: response.data.hardwareQuantity,
                sppData: response.data.spp,
                drawerData: response.data.drawers,
                drawerFronts: response.data.drawerFronts,
                doorDimensions: response.data.doorDimensions,
                blindFronts: response.data.blindFronts,
                prfData: response.data.prfFt,
                numOfCabinets: response.data.numOfCabinets,

            };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    },
    {
        // Only fetch if we haven't fetched this order before or if it's been more than 5 minutes
        condition: ({orderId}, {getState}) => {
            const {order} = getState();
            if (order.status === 'loading') return false;
            if (order.lastFetchedOrder?.id === orderId) {
                const timeSinceLastFetch = Date.now() - order.lastFetchedOrder.timestamp;
                if (timeSinceLastFetch < 300000) return false; // 5 minutes in milliseconds
            }
            return true;
        }
    }
);

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        clearOrderData: (state) => {
            state.orderData = initialState.orderData;
            state.status = 'idle';
            state.error = null;
            state.lastFetchedOrder = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderSummary.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrderSummary.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orderData = action.payload;
                state.lastFetchedOrder = {
                    id: action.meta.arg.orderId,
                    timestamp: Date.now()
                };
            })
            .addCase(fetchOrderSummary.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const {clearOrderData} = orderSlice.actions;
export default orderSlice.reducer;
// OrderStatusManagement.jsx
import React from 'react';
import {Card, Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';

/**
 * Component for managing vendor assignments to different roles within an order.
 *
 * Props:
 * - order: The current order object.
 * - handleStatusChange: Function to handle changes in order status fields.
 * - handleVendorAssignment: Function to handle vendor assignments to roles.
 * - vendorRelationships: Array of current vendor relationships.
 */
const OrderStatusManagement = ({
                                   order,
                                   handleStatusChange,
                                   handleVendorAssignment,
                                   vendorRelationships,
                               }) => {
    const vendors = useSelector((state) => state.vendors.vendors);
    const vendorsStatus = useSelector((state) => state.vendors.status);

    // Mapping of order status fields to vendor roles
    const statusToVendorType = {
        cncStatus: 'cncParts',
        dboxStatus: 'drawerBoxes',
        hwareStatus: 'hardware',
        assemblyStatus: 'assembly',
        finishingStatus: 'finishing',
        shippingStatus: 'shipping',
    };

    // Mapping of status fields to their corresponding include properties
    const statusToIncludeProperty = {
        dboxStatus: 'includeDrawerboxes',
        hwareStatus: 'includeHardware',
        assemblyStatus: 'includeAssembly',
        finishingStatus: 'includeFinishing',
        shippingStatus: 'includeShipping',
    };

    const capitalize = (s) => {
        switch (s) {
            case 'cnc':
                return 'CNC';
            case 'dbox':
                return 'Drawerbox';
            case 'hware':
                return 'Hardware';
            case 'assembly':
                return 'Assembly';
            case 'finishing':
                return 'Finishing';
            case 'shipping':
                return 'Shipping'
        }
    };

    const getStatusOptions = (statusKey) => {
        const optionsMap = {
            cncStatus: ['Ordered', 'Complete', 'Incomplete', 'Pending', 'Action Needed'],
            dboxStatus: ['Ordered', 'Shipped', 'Pending', 'Action Needed', 'N/A'],
            hwareStatus: ['Ordered', 'Shipped', 'Pending', 'Action Needed', 'N/A'],
            assemblyStatus: ['In Progress', 'Complete', 'Incomplete', 'Pending', 'Action Needed', 'N/A'],
            finishingStatus: ['In Progress', 'Complete', 'Incomplete', 'Pending', 'Action Needed', 'N/A'],
            shippingStatus: ['Delivered', 'Ready for Pickup', 'Shipped', 'Pending', 'Action Needed'],
        };
        return optionsMap[statusKey] || [];
    };

    const getCurrentVendorId = (role) => {
        const relationship = vendorRelationships?.find((rel) => rel.role === role);
        return relationship ? relationship.vendorId.toString() : '';
    };

    /**
     * Determines if a status field should be disabled based on the include property
     *
     * @param {string} statusKey - The status field key
     * @returns {boolean} - Whether the field should be disabled
     */
    const isStatusFieldDisabled = (statusKey) => {
        // If order is null/undefined, disable the field
        if (!order) return true;

        const includeProperty = statusToIncludeProperty[statusKey];

        // CNC status is never disabled based on include properties
        if (statusKey === 'cncStatus' || statusKey === 'shippingStatus') return false;

        // If there's a corresponding include property, check its value
        if (includeProperty) {
            // Disable if the property is explicitly false or null/undefined
            return order[includeProperty] === 'false' || order[includeProperty] == null;
        }

        return false;
    };

    return (
        <Card className="mb-4 om-status-card">
            <Card.Header className="om-card-header">
                <Card.Title className="om-card-title">Order Status Management</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="d-flex flex-column gap-3">
                    {Object.keys(statusToVendorType).map((statusKey) => {
                        const roleKey = statusToVendorType[statusKey];
                        const role = {
                            cncParts: 'CNC_VENDOR',
                            drawerBoxes: 'DRAWER_VENDOR',
                            hardware: 'HARDWARE_VENDOR',
                            assembly: 'ASSEMBLY_VENDOR',
                            finishing: 'FINISHING_VENDOR',
                            shipping: 'SHIPPING_VENDOR',
                        }[roleKey];

                        const isDisabled = isStatusFieldDisabled(statusKey);

                        return (
                            <div className="d-flex align-items-center" key={statusKey}>
                                <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                                    {`${capitalize(statusKey.replace('Status', ''))} Status`}
                                </Form.Label>
                                <div className="d-flex gap-3">
                                    <Form.Select
                                        className={`om-${statusKey}`}
                                        value={order?.[statusKey] || ''}
                                        onChange={(e) => handleStatusChange(statusKey, e.target.value)}
                                        style={{width: '200px'}}
                                        disabled={isDisabled}
                                    >
                                        <option value="">Select Status</option>
                                        {getStatusOptions(statusKey).map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Select
                                        style={{width: '200px'}}
                                        value={getCurrentVendorId(role)}
                                        onChange={(e) => handleVendorAssignment(roleKey, e.target.value)}
                                        disabled={isDisabled || vendorsStatus !== 'succeeded'}
                                    >
                                        <option value="">Select Vendor</option>
                                        {vendors?.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card.Body>
        </Card>
    );
};

export default OrderStatusManagement;
import {useCallback, useMemo, useState} from 'react';
import {standardizeText} from '../helpers';
import memoizedCategories from '../memoizedCategories';

export const useFilters = (allCabinets) => {
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);

    const handleFilterSelection = useCallback((filter) => {
        setSelectedFilters(prevFilters =>
            prevFilters.includes(filter)
                ? prevFilters.filter(item => item !== filter)
                : [...prevFilters, filter]
        );
    }, []);

    const handleCategoryClick = useCallback((category) => {
        setExpandedCategories(prevCategories =>
            prevCategories.includes(category)
                ? prevCategories.filter(item => item !== category)
                : [...prevCategories, category]
        );
    }, []);

    const hasCabinetsWithFilterTag = useCallback((filterTag) => {
        return allCabinets.some(cabinet => {
            const filterTags = cabinet.filterTags.split(',').map(tag => tag.trim());
            return filterTags.includes(filterTag);
        });
    }, [allCabinets]);

    const isFilterSelected = useCallback(
        (filter) => selectedFilters.includes(filter),
        [selectedFilters]
    );

    const isCategoryExpanded = useCallback(
        (category) => expandedCategories.includes(category),
        [expandedCategories]
    );

    return {
        selectedFilters,
        setSelectedFilters,
        handleFilterSelection,
        handleCategoryClick,
        hasCabinetsWithFilterTag,
        isFilterSelected,
        isCategoryExpanded
    };
};

export const useFilteredCabinets = (
    allCabinets,
    selectedFilters,
    searchQuery,
    isFirstLoad,
    randomizedCabinets
) => {
    return useMemo(() => {
        if (selectedFilters.length === 0 && !searchQuery) {
            return isFirstLoad ? randomizedCabinets : allCabinets;
        }

        const typeAndFunctionFilters = selectedFilters.filter(filter =>
            memoizedCategories[0].subcategories.some(sub => sub.tags.includes(filter))
        );

        const featureFilters = selectedFilters.filter(filter =>
            !typeAndFunctionFilters.includes(filter)
        );

        const featureCategories = memoizedCategories.slice(1);

        const standardizedSearchQuery = standardizeText(searchQuery.toLowerCase());
        const searchTerms = standardizedSearchQuery.split(/\s+/).filter(term => term.length > 0);

        return allCabinets.filter(cabinet => {
            const cabinetTags = cabinet.filterTags.split(',').map(tag => tag.trim().toLowerCase());

            const matchesTypeAndFunction = typeAndFunctionFilters.length === 0 ||
                typeAndFunctionFilters.some(filter => cabinetTags.includes(filter.toLowerCase()));

            const matchesFeatures = featureCategories.every(category => {
                const categoryFilters = featureFilters.filter(filter =>
                    category.subcategories.some(sub =>
                        sub.subcategories
                            ? sub.subcategories.some(subSub => subSub.tags.includes(filter))
                            : sub.tags.includes(filter)
                    )
                );

                return categoryFilters.length === 0 ||
                    categoryFilters.some(filter => cabinetTags.includes(filter.toLowerCase()));
            });

            if (!searchQuery) return matchesTypeAndFunction && matchesFeatures;

            const matchesSerialNumber = cabinet.serialNumber.toLowerCase().includes(searchQuery.toLowerCase());
            const standardizedDescription = standardizeText(cabinet.description.toLowerCase());
            const matchesDescription = searchTerms.every(term => standardizedDescription.includes(term));

            return matchesTypeAndFunction && matchesFeatures && (matchesSerialNumber || matchesDescription);
        });
    }, [selectedFilters, allCabinets, randomizedCabinets, isFirstLoad, searchQuery]);
};
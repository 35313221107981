// TutorialComponent.js
import React, {useEffect, useState} from 'react';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import VideoTutorials from './sections/VideoTutorials';
import CatalogGuide from './sections/CatalogGuide';
import DimensioningGuide from "./sections/DimensioningGuide";
import GlobalSettingsGuide from "./sections/GlobalSettingsGuide";
import ConfigurationGuide from "./sections/ConfigurationGuide";
import OrderManagementGuide from "./sections/OrderManagementGuide";
import './TutorialStyles.css';

const TutorialComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 80) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sections = [
        {id: "video-tutorials", title: "Video Tutorials", number: "01"},
        {id: "catalog-guide", title: "Using the Catalog", number: "02"},
        {id: "dimensioning", title: "Understanding Dimensioning", number: "03"},
        {id: "global-settings", title: "Global Settings", number: "04"},
        {id: "configuration", title: "Cabinet Configuration", number: "05"},
        {id: "order-management", title: "Order Management", number: "06"},
    ];

    return (
        <div className="sealab-tutorial min-vh-100">
            <div className="sealab-tutorial__container">
                <nav className={`sealab-tutorial__sidebar ${scrolled ? 'scrolled' : ''}`}>
                    <ul className="sealab-tutorial__nav-list">
                        {sections.map(section => (
                            <li key={section.id} className="sealab-tutorial__nav-item">
                                <a href={`#${section.id}`} className="sealab-tutorial__nav-link">
                                    <span className="sealab-tutorial__nav-number">{section.number}</span>
                                    <span className="sealab-tutorial__nav-text">{section.title}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>

                <main className="sealab-tutorial__content">
                    <VideoTutorials/>
                    <CatalogGuide/>
                    <DimensioningGuide/>
                    <GlobalSettingsGuide/>
                    <ConfigurationGuide/>
                    <OrderManagementGuide/>
                </main>
            </div>
        </div>
    );
};

export default TutorialComponent;
// components/Profile/EditProfileComponent.js

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editUser} from '../../features/user/userSlice';
import {Helmet} from 'react-helmet-async';
import ProfileService from '../../api/ProfileService';
import './EditProfileStyles.css';

const EditProfileComponent = () => {
    const {user, accessToken} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [userInfo, setUserInfo] = useState({
        email: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        apartment: '',
        city: '',
        state: '',
        zipcode: '',
    });

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    useEffect(() => {
        if (user) {
            const {email, phoneNumber, address1, address2, apartment, city, state, zipcode} = user;
            setUserInfo({
                email: email || '',
                phoneNumber: phoneNumber || '',
                address1: address1 || '',
                address2: address2 || '',
                apartment: apartment || '',
                city: city || '',
                state: state || '',
                zipcode: zipcode || '',
            });
        }
    }, [user]);

    const handleChange = e => {
        const {name, value} = e.target;

        if (name === 'zipcode') {
            const formattedZipcode = value.replace(/[^0-9]/g, '').slice(0, 5);
            setUserInfo(prevState => ({...prevState, [name]: formattedZipcode}));
            return;
        }

        if (name === 'phoneNumber') {
            const formattedPhoneNumber = value.replace(/[^0-9]/g, '').slice(0, 10);
            const phoneWithDashes = formattedPhoneNumber
                .replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3')
                .slice(0, 12);
            setUserInfo(prevState => ({...prevState, [name]: phoneWithDashes}));
            return;
        }

        setUserInfo(prevState => ({...prevState, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedUserInfo = {};

        Object.entries(userInfo).forEach(([key, value]) => {
            if (value !== user[key]) {
                updatedUserInfo[key] = value;
            }
        });

        try {
            const response = await ProfileService.editProfile({...user, ...updatedUserInfo}, accessToken);
            setAlertMessage(response.data);
            setAlertType('success');
            setShowAlert(true);
            dispatch(editUser({user: {...user, ...updatedUserInfo}, accessToken}));
        } catch (error) {
            console.error('Error updating profile:', error);
            setAlertMessage('An error occurred while updating the profile.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };


    const formatLabel = (label) => {
        switch (label) {
            case 'phoneNumber':
                return 'Phone Number';
            case 'address1':
                return 'Address 1';
            case 'address2':
                return 'Address 2';
            default:
                return label.charAt(0).toUpperCase() + label.slice(1);
        }
    };

    return (
        <>
            <div className="sealab-edit-profile">
                <Helmet>
                    <title>Edit Profile | The Sealab - Premium Kitchen & Closet Cabinets</title>
                    <meta
                        name="description"
                        content="Update your profile information on The Sealab. Ensure your details are accurate to receive the best service and personalized recommendations for premium kitchen and closet cabinets."
                    />
                    <meta
                        name="keywords"
                        content="edit profile, update profile, The Sealab, kitchen cabinets, closet cabinets, profile information, personalized recommendations"
                    />
                </Helmet>

                <div className="container py-5">
                    <div className="sealab-profile-card">
                        <div className="sealab-profile-header">
                            <h1 className="sealab-profile-title">Edit Your Profile</h1>
                            <p className="sealab-profile-subtitle">Update your information to enhance your shopping
                                experience</p>
                        </div>

                        <form onSubmit={handleSubmit} className="sealab-profile-form">
                            <div className="sealab-form-section">
                                <div className="sealab-section-header">
                                    <h2>Contact Information</h2>
                                    <div className="sealab-section-divider"></div>
                                </div>

                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <div className="sealab-form-group">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={userInfo?.email}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sealab-form-group">
                                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                            <input
                                                type="text"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                value={(userInfo?.phoneNumber !== 'null' && userInfo.phoneNumber !== null) ? userInfo?.phoneNumber : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                                maxLength={12}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sealab-form-section">
                                <div className="sealab-section-header">
                                    <h2>Address Information</h2>
                                    <div className="sealab-section-divider"></div>
                                </div>

                                <div className="row g-4">
                                    <div className="col-md-12">
                                        <div className="sealab-form-group">
                                            <label htmlFor="address1" className="form-label">Address Line 1</label>
                                            <input
                                                type="text"
                                                id="address1"
                                                name="address1"
                                                value={userInfo?.address1 !== 'null' && userInfo?.address1 !== null ? userInfo?.address1 : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="sealab-form-group">
                                            <label htmlFor="address2" className="form-label">Address Line 2</label>
                                            <input
                                                type="text"
                                                id="address2"
                                                name="address2"
                                                value={userInfo?.address2 !== 'null' && userInfo?.address2 !== null ? userInfo?.address2 : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="sealab-form-group">
                                            <label htmlFor="apartment" className="form-label">Apartment/Suite</label>
                                            <input
                                                type="text"
                                                id="apartment"
                                                name="apartment"
                                                value={userInfo?.apartment !== 'null' && userInfo?.apartment !== null ? userInfo?.apartment : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="sealab-form-group">
                                            <label htmlFor="city" className="form-label">City</label>
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={userInfo?.city !== 'null' && userInfo?.city !== null ? userInfo?.city : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="sealab-form-group">
                                            <label htmlFor="state" className="form-label">State</label>
                                            <input
                                                type="text"
                                                id="state"
                                                name="state"
                                                value={userInfo?.state !== 'null' && userInfo?.state !== null ? userInfo?.state : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                                maxLength={2}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="sealab-form-group">
                                            <label htmlFor="zipcode" className="form-label">ZIP Code</label>
                                            <input
                                                type="text"
                                                id="zipcode"
                                                name="zipcode"
                                                value={userInfo?.zipcode !== 'null' && userInfo?.zipcode !== null ? userInfo?.zipcode : ''}
                                                onChange={handleChange}
                                                className="form-control sealab-input"
                                                maxLength={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sealab-form-actions">
                                <button type="submit" className="sealab-submit-btn">
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                {showAlert && (
                    <div
                        className={`sealab-alert ${alertType === 'success' ? 'sealab-alert-success' : 'sealab-alert-error'}`}>
                        <p className="sealab-alert-message">{alertMessage}</p>
                        <button className="sealab-alert-close" onClick={closeAlert}>
                            <span>&times;</span>
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default EditProfileComponent;
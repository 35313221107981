import React, {useEffect, useState} from 'react';
import {Alert, Card, Col, Container, Form, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {UserLoggedIn} from '../../../hooks/isUserLoggedIn';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './OrderManagementStyles.css';

const OrderList = () => {
    const userLoggedIn = UserLoggedIn();
    const token = useSelector((state) => state.user.accessToken);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;

    const [orders, setOrders] = useState([]);
    const [statusFilter, setStatusFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [orderIdInput, setOrderIdInput] = useState('');
    const [searchError, setSearchError] = useState('');

    useEffect(() => {
        fetchAllOrders();
    }, []);

    const fetchAllOrders = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/internal/findAllOrders`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setOrders(response.data);
        } catch (error) {
            console.error('Error fetching all orders:', error);
        }
    };

    const getFilteredOrders = () => {
        return orders.filter((o) => {
            const matchesOrderId = orderIdInput
                ? o.orderId.toString().startsWith(orderIdInput)
                : true;
            const matchesStatus = statusFilter ? o.status === statusFilter : true;
            const matchesEmail = emailFilter
                ? o.email?.toLowerCase().includes(emailFilter.toLowerCase())
                : true;
            return matchesOrderId && matchesStatus && matchesEmail;
        });
    };

    const handleOrderIdChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setOrderIdInput(value);
            setSearchError('');
        } else {
            setSearchError('Order ID can only contain digits.');
        }
    };

    const handleOrderClick = (clickedOrderId) => {
        window.open(`/internal/order-management/${clickedOrderId}`, '_blank');
    };

    const getStatusClass = (status) => {
        const statusMap = {
            'Pending': 'status-pending',
            'In Production': 'status-in-production',
            'Delivered': 'status-delivered',
            'Shipped': 'status-shipped',
            'Complete': 'status-complete',
            'Ready for Pickup': 'status-rfp'
        };
        return statusMap[status] || 'status-default';
    };

    // if (!userLoggedIn) {
    //     return <NavbarComponent/>;
    // }

    const filteredOrders = getFilteredOrders();

    return (
        <>
            {/*<NavbarLoggedInComponent/>*/}
            <Container fluid className="om-container">
                <Row className="mb-4">
                    <Col>
                        <h2>Order Management</h2>
                    </Col>
                </Row>

                {searchError && (
                    <Row>
                        <Col>
                            <Alert variant="danger" className="om-alert">
                                {searchError}
                            </Alert>
                        </Col>
                    </Row>
                )}

                <Row className="om-header-row">
                    <Col md={3} className="mb-3">
                        <Form.Group className="om-status-filter">
                            <Form.Label className="om-filter-label">Filter by Status</Form.Label>
                            <Form.Select
                                className="om-select-status"
                                value={statusFilter}
                                onChange={(e) => {
                                    setStatusFilter(e.target.value);
                                    setOrderIdInput('');
                                    setSearchError('');
                                }}
                            >
                                <option value="">All</option>
                                <option value="Pending">Pending</option>
                                <option value="In Production">In Production</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Complete">Complete</option>
                                <option value="Ready for Pickup">Ready for Pickup</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Form.Group className="om-email-filter">
                            <Form.Label className="om-filter-label">Filter by Email</Form.Label>
                            <Form.Control
                                className="om-input-email-filter"
                                type="text"
                                placeholder="Enter email"
                                value={emailFilter}
                                onChange={(e) => {
                                    setEmailFilter(e.target.value);
                                    setOrderIdInput('');
                                    setSearchError('');
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Form.Group className="om-search-form">
                            <Form.Label className="om-filter-label">Search by Order ID</Form.Label>
                            <div className="om-search-wrapper">
                                <Form.Control
                                    className="om-input"
                                    type="text"
                                    value={orderIdInput}
                                    onChange={handleOrderIdChange}
                                    placeholder="Enter Order ID"
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="om-orders-row mt-4">
                    {filteredOrders.map((o, idx) => (
                        <Col key={idx} md={4} className="mb-3">
                            <Card className="om-order-card" onClick={() => handleOrderClick(o.orderId)}>
                                <Card.Body>
                                    <div className="order-status-wrapper">
                                        <span className={`order-status ${getStatusClass(o.status)}`}>
                                            {o.status}
                                        </span>
                                    </div>
                                    <div className="om-order-card-content">
                                        <p className="om-order-id">
                                            <strong>Order ID:</strong> {o.orderId}
                                        </p>
                                        <p className="om-order-projectName">
                                            <strong>Project Name:</strong> {o.projectName}
                                        </p>
                                        <p className="om-order-purchaseOrder">
                                            <strong>Purchase Order:</strong> {o.purchaseOrder}
                                        </p>
                                        <p className="om-order-email">
                                            <strong>Customer Email:</strong> {o.email}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default OrderList;

import React, {startTransition, Suspense, useCallback, useEffect, useMemo, useState} from 'react';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import './ShowRoomStyles.css';
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../../features/cart/cartSlice";
import {useNavigate} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";
import {Helmet} from 'react-helmet-async';
import FilterPanel from './FilterPanel';
import SearchBar from './SearchBar';
import CabinetCard from './CabinetCard';
import Pagination from './Pagination';
import {shuffleArray} from './helpers';
import {fetchCabinets} from '../../features/showroom/showroomSlice';
import {useFilteredCabinets, useFilters} from './hooks/showroomHooks';
import LoadingPage from "../Loading/LoadingPage";

// Lazy-load the ImageCarousel component for performance optimization
const ImageCarousel = React.lazy(() => import('../ImageCarouselComponent/ImageCarousel'));

// Constant for the number of cards to display per page
const CARDS_PER_PAGE = 28;

/**
 * ShowRoomComponent
 *
 * This component displays a showroom for kitchen and closet cabinets.
 * Users can browse cabinets, filter and search through them, and add items to the cart.
 * It includes features like filtering, pagination, and dynamic rendering based on screen size.
 */
const ShowRoomComponent = () => {
    const userLoggedIn = UserLoggedIn(); // Check if the user is logged in
    const dispatch = useDispatch(); // Redux dispatch hook
    const navigate = useNavigate(); // Navigation hook

    // Local state for managing cabinets, pagination, and UI interactions
    const [randomizedCabinets, setRandomizedCabinets] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [quantities, setQuantities] = useState({});
    const [cardWidth, setCardWidth] = useState(280);
    const [searchQuery, setSearchQuery] = useState('');

    // Redux state for fetching cabinets data
    const {cabinets: allCabinets, loading, error} = useSelector(state => state.showroom);
    const email = useSelector(state => state.user.user.email)
    const dataLoaded = !loading && !error; // Check if data is fully loaded

    // Custom hooks for handling filters and filtered cabinets
    const {
        selectedFilters,
        handleFilterSelection,
        handleCategoryClick,
        hasCabinetsWithFilterTag,
        isFilterSelected,
        isCategoryExpanded
    } = useFilters(allCabinets);

    const filteredCabinets = useFilteredCabinets(
        allCabinets,
        selectedFilters,
        searchQuery,
        isFirstLoad,
        randomizedCabinets
    );

    /**
     * Fetch cabinet data on the initial load.
     */
    // useEffect(() => {
    //     dispatch(fetchCabinets());
    // }, []); // Runs only once to avoid infinite loops

    useEffect(() => {
        dispatch(fetchCabinets(email || null)); // Pass `null` explicitly if email is undefined
    }, [email, dispatch]); // Runs when email changes


    /**
     * Shuffle and initialize cabinets for the first load.
     */
    useEffect(() => {
        if (dataLoaded && allCabinets.length > 0) {
            setRandomizedCabinets(shuffleArray([...allCabinets])); // Shuffle cabinets for random display
            setTotalPages(Math.ceil(allCabinets.length / CARDS_PER_PAGE)); // Calculate total pages
        }
    }, [dataLoaded, allCabinets]);

    /**
     * Handle window resizing to adjust the card width dynamically.
     */
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            setCardWidth(windowWidth <= 576 ? windowWidth - 70 : 350); // Adjust card width based on screen size
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
    }, []);

    /**
     * Handle quantity changes for a specific cabinet item.
     */
    const handleQuantityChange = useCallback((id, value) => {
        setQuantities(prev => ({...prev, [id]: value}));
    }, []);

    /**
     * Add a cabinet item to the cart with the specified quantity.
     */
    const handleAddToCart = useCallback((cabinet) => {
        const qty = quantities[cabinet.id] || 1; // Default quantity to 1
        dispatch(addToCart({
            ...cabinet,
            id: nanoid(), // Generate a unique ID for the cart item
            quantity: parseInt(qty, 10) // Ensure quantity is an integer
        }));
    }, [dispatch, quantities]);

    /**
     * Navigate to the configure page for a specific cabinet item.
     */
    const handleConfigure = useCallback((item) => {
        const qty = quantities[item.id] || 1;
        const uniqueId = nanoid(); // Generate a unique ID for the configuration
        navigate('/order', {
            state: {
                items: [{...item, quantity: qty, id: uniqueId}],
                fromConfigureButton: true,
                uniqueId
            }
        });
    }, [navigate, quantities]);

    /**
     * Render a filter item dynamically based on its presence in the cabinet tags.
     */
    const renderFilterItem = useCallback((filterTag, displayName) => {
        if (hasCabinetsWithFilterTag(filterTag)) {
            return (
                <div
                    key={filterTag}
                    className={`sr-dropdown-item ${isFilterSelected(filterTag) ? 'selected' : ''}`}
                    onClick={() => handleFilterSelection(filterTag)}
                >
                    {displayName}
                </div>
            );
        }
        return null;
    }, [hasCabinetsWithFilterTag, isFilterSelected, handleFilterSelection]);

    /**
     * Render subcategories for a specific category.
     */
    const renderSubcategory = useCallback((category, subcategories) => {
        const hasChildrenWithFilterTags = subcategories.some(subcategory =>
            subcategory.tags.some(tag => hasCabinetsWithFilterTag(tag))
        );

        if (hasChildrenWithFilterTags) {
            return (
                <div key={category} className="category-division">
                    <button
                        className="btn btn-dark sr-dropdown-toggle w-100 text-start"
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </button>
                    {isCategoryExpanded(category) && (
                        <div className="sr-dropdown-subcontent">
                            {subcategories.map(subcategory =>
                                subcategory.tags.map(tag => renderFilterItem(tag, subcategory.displayName))
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }, [hasCabinetsWithFilterTag, handleCategoryClick, isCategoryExpanded, renderFilterItem]);

    /**
     * Paginate the filtered cabinets for display on the current page.
     */
    const paginatedCabinets = useMemo(() => {
        const startIndex = (currentPage - 1) * CARDS_PER_PAGE;
        return filteredCabinets.slice(startIndex, startIndex + CARDS_PER_PAGE);
    }, [currentPage, filteredCabinets]);

    /**
     * Handle page changes for pagination.
     */
    const handlePageChange = useCallback((pageNumber) => {
        setCurrentPage(pageNumber); // Update current page
        window.scrollTo({top: 0, behavior: "instant"}); // Scroll to top of the page
    }, []);

    /**
     * Handle search input changes.
     */
    const handleSearchChange = useCallback((event) => {
        startTransition(() => {
            setSearchQuery(event.target.value); // Update the search query
            setCurrentPage(1); // Reset to the first page
        });
    }, []);

    /**
     * Update the total pages whenever the filtered cabinets change.
     */
    useEffect(() => {
        setTotalPages(Math.ceil(filteredCabinets.length / CARDS_PER_PAGE));
    }, [filteredCabinets]);

    // Display loading or error states
    // if (loading) return <div className="min-vh-100">Loading...</div>;
    if (loading) return <LoadingPage/>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="showroom-page min-vh-100">
            <Helmet>
                <title>Kitchen & Closet Cabinet Collection | The Sealab</title>
                <meta name="description"
                      content="Discover high-quality kitchen and closet cabinets at The Sealab. Shop custom CNC cabinets in NYC. Perfect for home renovations."/>
                <meta name="keywords"
                      content="kitchen cabinets, closet cabinets, CNC cabinets, high-quality cabinets, cabinets near me, cabinets in Brooklyn, The Sealab,
                      order cabinets online, cabinets in NYC"/>
                <meta property="og:title" content="Showroom | The Sealab - Kitchen & Closet Cabinets"/>
                <meta property="og:description"
                      content="Explore the finest collection of kitchen and closet cabinets at The Sealab. Find your ideal cabinet."/>
                <meta property="og:url" content="https://www.thesealab.com/showroom"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="The Sealab"/>
            </Helmet>

            <Suspense fallback={<div>Loading..</div>}>
                {/* Navbar would go here */}
            </Suspense>

            {dataLoaded && (
                <div className="showroom-container">
                    <FilterPanel
                        hasCabinetsWithFilterTag={hasCabinetsWithFilterTag}
                        handleCategoryClick={handleCategoryClick}
                        isCategoryExpanded={isCategoryExpanded}
                        renderFilterItem={renderFilterItem}
                        renderSubcategory={renderSubcategory}
                    />

                    <div className="showroom-content">
                        <Suspense fallback={<div>Loading images..</div>}>
                            <div className="upload-sketch-text">
                                Can't find what you're looking for? Contact us <a className="hyperlink"
                                                                                  href="/customer-support/contact">here</a> and
                                upload a sketch
                            </div>

                            <div className="showroom-quote-warning">
                                Please note, to receive a free quote you must complete the checkout process. Pricing
                                will be available in one business day.
                            </div>

                            <SearchBar
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />

                            {filteredCabinets.length === 0 ? (
                                <div className="showroom-no-results">
                                    No cabinets found matching your search criteria.
                                </div>
                            ) : (
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                                    {paginatedCabinets.map((cabinet, index) => (
                                        <div key={index} className="col">
                                            <CabinetCard
                                                cabinet={cabinet}
                                                cardWidth={cardWidth}
                                                onQuantityChange={handleQuantityChange}
                                                onConfigure={handleConfigure}
                                                onAddToCart={handleAddToCart}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {filteredCabinets.length > 0 && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            )}
                        </Suspense>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowRoomComponent;

// components/OrderMatHardware/MaterialTable.js

import React from 'react';

/**
 * MaterialTable Component
 *
 * This component renders a table displaying materials used in an order,
 * along with their total square footage (SQFT) and estimated sheet count.
 *
 * @param {Object} materialSqft - Object containing material IDs and their respective total SQFT.
 * @param {number} materialSqft[matId] - Total square footage for a given material ID.
 *
 * @returns {JSX.Element} - The rendered table showing material data.
 */
const MaterialTable = ({materialSqft = {}}) => {
    console.log(materialSqft)
    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Materials</h4>
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Material</th>
                    <th>Total SQFT</th>
                    <th>Estimated Sheets</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(materialSqft).map(([matId, sqft]) => (
                    <tr key={matId}>
                        <td>{matId}</td>
                        <td>{sqft.toFixed(2)}</td>
                        <td>
                            {Math.round((sqft / 32) * 1.2) || 1}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default MaterialTable;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchArticlesByOrderId} from "../../../api/OrderService";
import {useSelector} from "react-redux";
import {Search} from 'lucide-react';

const ChecklistComponent = () => {
    const {orderId} = useParams();
    const token = useSelector((state) => state.user.accessToken);
    const [articles, setArticles] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortBy, setSortBy] = useState("positionName");
    const [view, setView] = useState("list"); // list or grid

    useEffect(() => {
        fetchArticlesByOrderId(orderId, token)
            .then((res) => {
                const data = res.data.articles;
                const articleList = Array.isArray(data)
                    ? data
                    : Object.values(data).flat();
                setArticles(articleList);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [orderId, token]);

    const filteredArticles = articles.filter(article =>
        article.positionName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedArticles = [...filteredArticles].sort((a, b) => {
        if (sortBy === "positionName") {
            return (a.positionName || "").localeCompare(b.positionName || "");
        }
        return (a.serialNumber || "").localeCompare(b.serialNumber || "");
    });

    const ArticleDetails = ({article}) => (
        <div className="checklist-details">
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="checklist-detail-group">
                        <label className="text-black-50 small">Dimensions</label>
                        <p className="mb-0 fw-medium">
                            {article.width}W" × {article.height}H" × {article.depth}D"
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="checklist-detail-group">
                        <label className="text-black-50 small">Quantity</label>
                        <p className="mb-0 fw-medium">{article.quantity || "N/A"}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="checklist-detail-group">
                        <label className="text-black-50 small">Materials</label>
                        <p className="mb-0">
                            Case: <span className="fw-medium">{article.caseMaterial || "N/A"}</span><br/>
                            Front: <span className="fw-medium">{article.frontMaterial || "N/A"}</span><br/>
                            {article?.caseMaterial && !article?.serialNumber.startsWith("LP") && (
                                <>
                                    Back: <span className="fw-medium">{article.backPanelMaterial || "N/A"}</span>
                                </>
                            )}

                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="checklist-detail-group">
                        <label className="text-black-50 small">Edges</label>
                        <p className="mb-0">
                            Case: <span
                            className="fw-medium">{article?.caseEdge === '0' || article?.frontEdge === '1' ? 'N/A' : article?.caseEdge}</span><br/>
                            Front: <span
                            className="fw-medium">{article?.frontEdge === '0' || article?.frontEdge === '1' ? 'N/A' : article?.frontEdge}</span>
                        </p>
                    </div>
                </div>
                {!article.serialNumber.startsWith("LP") && (
                    <div className="col-md-6">
                        <div className="checklist-detail-group">
                            <label className="text-black-50 small">Back Panel Method</label>
                            <p className="mb-0">
                                <span className="fw-medium">{article.backPanel}</span>
                            </p>
                        </div>
                    </div>
                )}

                <div className="col-md-6">
                    <div className="checklist-detail-group">
                        <label className="text-black-50 small">Number of Shelves</label>
                        <p className="mb-0">
                            <span className="fw-medium">{article.numOfShelves}</span>
                        </p>
                    </div>
                </div>
                {article?.bottomPanelConnector && (
                    <div className="col-md-6">
                        <div className="checklist-detail-group">
                            <label className="text-black-50 small">Bottom Panel</label>
                            <p className="mb-0">
                                Bottom Panel: <span className="fw-medium">{article?.bottomPanelConnector}</span>
                            </p>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );

    return (
        <div className="container-fluid py-4 bg-light min-vh-100">
            <div className="container">
                {/* Header Section */}
                <header className="mb-4">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <h1 className="checklist-title h4 mb-0">Article Checklist</h1>
                            <p className="text-black-50 small mb-3 mb-lg-0">Order ID: {orderId}</p>
                        </div>
                        <div className="col-lg-8">
                            <div className="row g-2">
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <span className="input-group-text bg-white border-end-0">
                                            <Search size={18}/>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control border-start-0"
                                            placeholder="Search by name or serial number..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <select
                                        className="form-select"
                                        value={sortBy}
                                        onChange={(e) => setSortBy(e.target.value)}
                                    >
                                        <option value="positionName">Sort by Name</option>
                                        <option value="serialNumber">Sort by Serial Number</option>
                                    </select>
                                </div>
                                <div className="col-sm-3">
                                    <div className="btn-group w-100">
                                        <button
                                            className={`btn ${view === 'list' ? 'btn-dark' : 'btn-outline-dark'}`}
                                            onClick={() => setView('list')}
                                        >
                                            List
                                        </button>
                                        <button
                                            className={`btn ${view === 'grid' ? 'btn-dark' : 'btn-outline-dark'}`}
                                            onClick={() => setView('grid')}
                                        >
                                            Grid
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Articles List */}
                <div className="checklist-articles">
                    {sortedArticles.length === 0 ? (
                        <div className="text-center py-5 text-black-50">
                            <p className="mb-0">No articles found matching your search.</p>
                        </div>
                    ) : (
                        <div className={view === 'grid' ? 'row g-4' : ''}>
                            {sortedArticles.map((article, index) => (
                                <div
                                    key={index}
                                    className={view === 'grid' ? 'col-md-6 col-lg-4' : 'mb-3'}
                                >
                                    <div className="checklist-item bg-white p-3 rounded shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <div>
                                                <h3 className="h6 mb-1 fw-bold">
                                                    {article.positionName || "Untitled Position"}
                                                </h3>
                                                <span className="badge bg-dark">
                                                    SN: {article.serialNumber}
                                                </span>
                                            </div>

                                        </div>
                                        <ArticleDetails article={article}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChecklistComponent;
// import React from 'react';
// import {FaInfoCircle} from 'react-icons/fa';
// import './OrderSummaryStyles.css';
//
// const ArticleCard = ({article, imageExistsMap, thumbNail, imageURL, onClick}) => {
//     return (
//         <div className="card os-article-card" onClick={() => onClick(article.positionName)}>
//             <div className="os-card-header">
//                 <h3 className="os-card-title">{article.positionName}</h3>
//                 <p className="os-card-subtitle">SN: {article.serialNumber}</p>
//             </div>
//
//             <div className="os-card-body">
//                 <div className="os-card-section">
//                     <h4 className="os-section-label">Dimensions</h4>
//                     <div className="os-dimensions-row">
//                         <span>{article.width}" (W) x {article.height}" (H) x {article.depth}" (D)</span>
//                         <span className="os-quantity">Qty: {article.quantity || 'N/A'}</span>
//                     </div>
//                 </div>
//
//                 <div className="os-card-section">
//                     <h4 className="os-section-label">Materials</h4>
//                     <div className="os-spec-row">
//                         <span className="os-spec-label">Case:</span>
//                         <span className="os-spec-value">{article.caseMaterial || 'N/A'}</span>
//                     </div>
//                     <div className="os-spec-row">
//                         <span className="os-spec-label">Front:</span>
//                         <span className="os-spec-value">{article.frontMaterial || 'N/A'}</span>
//                     </div>
//                 </div>
//
//                 <div className="os-card-section">
//                     <h4 className="os-section-label">Edgebanding</h4>
//                     <div className="os-spec-row">
//                         <span className="os-spec-label">Case:</span>
//                         <span className="os-spec-value">{article.caseEdge || 'N/A'}</span>
//                     </div>
//                     <div className="os-spec-row">
//                         <span className="os-spec-label">Front:</span>
//                         <span className="os-spec-value">{article.frontEdge || 'N/A'}</span>
//                     </div>
//                 </div>
//
//                 {imageExistsMap && imageExistsMap[article.positionName] ? (
//                     <div className="os-image-container">
//                         <img
//                             src={`${thumbNail}${article.orderId}/${article.positionName}.png`}
//                             alt={`Article ${article.positionName}`}
//                             className="os-article-img"
//                         />
//                     </div>
//                 ) : (
//                     <div className="os-image-container os-no-image">
//                         <div className="os-tooltip">
//                             <FaInfoCircle className="os-tooltip-icon"/>
//                             <span className="os-tooltip-text">Image Processing</span>
//                         </div>
//                         <img
//                             src={`${imageURL}no_picture.jpg`}
//                             alt={`Article ${article.articleId}`}
//                             className="os-article-img"
//                         />
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default ArticleCard;


import React from 'react';
import {FaInfoCircle} from 'react-icons/fa';
import './OrderSummaryStyles.css';

const ArticleCard = ({article, imageExistsMap, thumbNail, imageURL, onClick}) => {
    return (
        <div className="card os-article-card" onClick={() => onClick(article.positionName)}>
            <div className="os-card-header">
                <div className="os-card-title-group">
                    <h3 className="os-card-title">{article.positionName || "Untitled Position"}</h3>
                    <span className="os-card-subtitle">SN: {article.serialNumber}</span>
                </div>
            </div>

            <div className="os-card-body">
                <div className="os-detail-section">
                    <label className="os-detail-label">Dimensions</label>
                    <div className="os-detail-row">
                        <span>{article.width}" (W) × {article.height}" (H) × {article.depth}" (D)</span>
                        <span className="os-quantity">Qty: {article.quantity || "N/A"}</span>
                    </div>
                </div>

                <div className="os-detail-section">
                    <label className="os-detail-label">Materials</label>
                    <div className="os-detail-content">
                        <div className="os-detail-row">
                            <span className="os-detail-key">Case:</span>
                            <span className="os-detail-value">{article.caseMaterial || "N/A"}</span>
                        </div>
                        <div className="os-detail-row">
                            <span className="os-detail-key">Front:</span>
                            <span className="os-detail-value">{article.frontMaterial || "N/A"}</span>
                        </div>
                    </div>
                </div>

                <div className="os-detail-section">
                    <label className="os-detail-label">Edges</label>
                    <div className="os-detail-content">
                        <div className="os-detail-row">
                            <span className="os-detail-key">Case:</span>
                            <span
                                className="os-detail-value">{article.caseEdge === '0' ? "N/A" : article.caseEdge}</span>
                        </div>
                        <div className="os-detail-row">
                            <span className="os-detail-key">Front:</span>
                            <span
                                className="os-detail-value">{article.frontEdge === '0' ? "N/A" : article.frontEdge}</span>
                        </div>
                    </div>
                </div>

                {imageExistsMap && imageExistsMap[article.positionName] ? (
                    <div className="os-image-container">
                        <img
                            src={`${thumbNail}${article.orderId}/${article.positionName}.png`}
                            alt={`Article ${article.positionName}`}
                            className="os-article-img"
                        />
                    </div>
                ) : (
                    <div className="os-image-container os-no-image">
                        <div className="os-tooltip">
                            <FaInfoCircle className="os-tooltip-icon"/>
                            <span className="os-tooltip-text">Image Processing</span>
                        </div>
                        <img
                            src={`${imageURL}no_picture.jpg`}
                            alt={`Article ${article.articleId}`}
                            className="os-article-img"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleCard;
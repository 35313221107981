import React from 'react';
import jsPDF from 'jspdf';
import sealabIcon from '../../images/sealab_icon.png';


const PdfWriterComponent = ({orderDetails, imageURL}) => {
    const generatePDF = async () => {
        // Create custom page size for wider format
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: [842, 1200] // Wider A4 format
        });

        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
                img.src = url;
            });
        };

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 40;

        // Increased header height
        const headerHeight = 100;

        // Add logo to the left
        const logo = await loadImage(sealabIcon);
        const logoSize = 80;
        pdf.addImage(logo, 'PNG', margin, 20, logoSize, logoSize);

        // Company info on the right
        pdf.setTextColor(0, 0, 0);
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(12);

        const companyInfo = [
            "The Sealab",
            "63 Flushing Ave",
            "Building 3, Suite 1108",
            "Brooklyn, NY 11205",
            "info@thesealab.com"
        ];

        pdf.text(companyInfo, pageWidth - margin, 30, {
            align: 'right',
            lineHeightFactor: 1.4
        });

        // Start content lower to account for taller header
        let currentY = headerHeight + 20;
        const contentWidth = pageWidth - (margin * 2);

        // Group orders by tempOrderId
        const groupedOrders = orderDetails.reduce((acc, order) => {
            const groupId = order.tempOrderId;
            if (!acc[groupId]) acc[groupId] = [];
            acc[groupId].push(order);
            return acc;
        }, {});

        for (const [tempOrderId, orders] of Object.entries(groupedOrders)) {
            // Main container border
            pdf.setDrawColor(220, 220, 220);
            pdf.setLineWidth(1);
            const containerHeight = Math.ceil(orders.length / 4) * 340 + 80; // Increased container height
            pdf.roundedRect(margin, currentY, contentWidth, containerHeight, 3, 3, 'S');

            // Order header bar
            pdf.setFillColor(0, 0, 0);
            pdf.rect(margin, currentY, contentWidth, 40, 'F');

            pdf.setTextColor(255, 255, 255);
            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(14);
            pdf.text(`Order ID: ${tempOrderId}`, margin + 20, currentY + 26);

            currentY += 60;

            // Calculate grid layout
            const itemsPerRow = 4;
            const itemWidth = (contentWidth - (40 * (itemsPerRow - 1))) / itemsPerRow;
            const itemHeight = 300; // Increased item height
            const itemsPerPage = 8; // 2 rows of 4

            for (let i = 0; i < orders.length; i++) {
                const order = orders[i];

                // Check if we need a new page
                if (i > 0 && i % itemsPerPage === 0) {
                    pdf.addPage();
                    currentY = 40;

                    // Recreate header on new page
                    const logo = await loadImage(sealabIcon);
                    const logoSize = 80;
                    pdf.addImage(logo, 'PNG', margin, 20, logoSize, logoSize);

                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('helvetica', 'normal');
                    pdf.setFontSize(12);

                    const companyInfo = [
                        "The Sealab",
                        "63 Flushing Ave",
                        "Building 3, Suite 1108",
                        "Brooklyn, NY 11205",
                        "info@thesealab.com"
                    ];

                    pdf.text(companyInfo, pageWidth - margin, 30, {
                        align: 'right',
                        lineHeightFactor: 1.4
                    });
                }

                // Calculate item position
                const row = Math.floor((i % itemsPerPage) / itemsPerRow);
                const col = i % itemsPerRow;
                const x = margin + (col * (itemWidth + 40));
                const y = currentY + (row * (itemHeight + 20));

                // Draw item card
                pdf.setDrawColor(238, 238, 238);
                pdf.setLineWidth(0.5);
                pdf.roundedRect(x, y, itemWidth, itemHeight, 3, 3, 'S');

                // Add image
                try {
                    const img = await loadImage(`${imageURL}${order.serialNumber}.webp`);
                    const imgSize = 140;
                    const imgX = x + (itemWidth - imgSize) / 2;
                    pdf.addImage(img, 'WEBP', imgX, y + 20, imgSize, imgSize);
                } catch (error) {
                    console.error('Error loading image:', error);
                }

                // Add details with increased spacing
                const details = [
                    {label: 'Item', value: order.positionName || 'N/A'},
                    {label: 'Serial', value: order.serialNumber},
                    {label: 'Dimensions', value: `${order.height} x ${order.width} x ${order.depth} in`},
                    {label: 'Front Material', value: order.frontMaterial},
                    {label: 'Case Material', value: order.caseMaterial},
                    {label: 'Quantity', value: order.quantity}
                ];

                let detailY = y + 180;
                details.forEach((detail, index) => {
                    const labelX = x + 15;
                    const valueX = x + itemWidth - 15;

                    // Label
                    pdf.setFont('helvetica', 'normal');
                    pdf.setFontSize(10);
                    pdf.setTextColor(102, 102, 102);
                    pdf.text(detail.label, labelX, detailY);

                    // Value
                    pdf.setTextColor(0, 0, 0);
                    const value = String(detail.value);

                    // Check text width and adjust if needed
                    if (pdf.getTextWidth(value) > itemWidth - 30) {
                        pdf.setFontSize(9);
                    }

                    pdf.text(value, valueX, detailY, {align: 'right'});

                    // Separator line with more space at bottom
                    if (index < details.length - 1) {
                        pdf.setDrawColor(240, 240, 240);
                        pdf.line(labelX, detailY + 7, valueX, detailY + 7);
                    }

                    detailY += 22; // Increased spacing between details
                });
            }

            // Move to next section
            currentY += containerHeight + 40;

            // Add new page if needed
            if (currentY > pageHeight - margin && Object.keys(groupedOrders).indexOf(tempOrderId) < Object.keys(groupedOrders).length - 1) {
                pdf.addPage();
                currentY = headerHeight + 20;
            }
        }

        pdf.save('order-details.pdf');
    };

    return (
        <button onClick={generatePDF} className="btn btn-light">
            Print Full Summary to PDF
        </button>
    );
};

export default PdfWriterComponent;
import {useEffect, useState} from 'react';
import axios from 'axios';

export const useOrderSummary = (orderId, token, baseURL) => {
    const [order, setOrder] = useState(null);
    const [articles, setArticles] = useState([]);
    const [imageExistsMap, setImageExistsMap] = useState({});

    useEffect(() => {
        const fetchOrderSummary = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/orders/${orderId}/summary`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOrder(response.data.order);
                setArticles(response.data.articles);
                checkImagesExistence(response.data.articles);
            } catch (error) {
                console.error('Error fetching order summary:', error);
            }
        };

        fetchOrderSummary();
    }, [orderId, token, baseURL]);

    const checkImagesExistence = async (articles) => {
        const map = {};
        const promises = articles.map(async article => {
            const url = `${process.env.REACT_APP_THMBNAIL}${orderId}/${article.positionName}.png`;
            try {
                const response = await fetch(url, {method: 'HEAD'});
                map[article.positionName] = response.ok;
            } catch (error) {
                console.error('Error checking image existence:', error);
                map[article.positionName] = false;
            }
        });

        await Promise.all(promises);
        setImageExistsMap(map);
    };

    return {order, articles, imageExistsMap};
};
import * as XLSX from 'xlsx';

export const generateExcel = (articles) => {
    if (!articles || !articles.length) return null;

    // Get all unique keys from all articles
    const allKeys = new Set();
    articles.forEach(article => {
        Object.keys(article).forEach(key => allKeys.add(key));
    });

    // Convert articles to rows with all possible properties
    const excelData = articles.map(article => {
        const row = {};
        allKeys.forEach(key => {
            row[key] = article[key] !== undefined ? article[key] : '';
        });
        return row;
    });

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);

    // Set column widths - default 15 for all columns
    const colWidths = Array.from(allKeys).map(() => ({wch: 15}));
    ws['!cols'] = colWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Articles');

    // Generate Excel file
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    return blob;
};
import React, {useMemo} from 'react';

/**
 * DrawersTable Component
 *
 * This component renders a table body (`<tbody>`) displaying a list of drawers.
 * It processes the drawer data to combine identical drawers into a single row
 * with an aggregated quantity, improving readability and efficiency.
 *
 * The component uses `React.memo` to prevent unnecessary re-renders,
 * and `useMemo` to optimize the processing of drawer data.
 *
 * @param {Object} props - Component properties.
 * @param {Array<Object>} props.drawers - List of drawer objects to be displayed in the table.
 * Each drawer object should include:
 *   - cpId: A unique identifier for the drawer.
 *   - mfgNum: The manufacturing number.
 *   - width: The width of the drawer.
 *   - depth: The depth of the drawer.
 *   - height: The height of the drawer.
 *   - quantity: (Optional) The number of identical drawers.
 */
export const DrawersTable = React.memo(({drawers}) => {
    /**
     * Processes the `drawers` array to group identical drawers based on specific properties.
     * The grouping is performed by constructing a unique key for each drawer,
     * which combines its cpId, mfgNum, width, depth, and height.
     *
     * The result is an array of drawer objects with aggregated quantities.
     *
     * @returns {Array<Object>} - Processed array of drawers with aggregated quantities.
     */
    const processedDrawers = useMemo(() => {
        const drawerMap = {}; // Object to store grouped drawers by unique key

        drawers.forEach(drawer => {
            // Construct a unique key for each drawer based on its properties
            const key = `${drawer.cpId}-${drawer.mfgNum}-${drawer.width}-${drawer.depth}-${drawer.height}`;
            if (!drawerMap[key]) {
                // Initialize a new entry if the key does not exist
                drawerMap[key] = {...drawer, quantity: 0};
            }
            // Increment the quantity for the matching key
            drawerMap[key].quantity += 1;
        });

        // Convert the grouped drawers into an array
        return Object.values(drawerMap);
    }, [drawers]); // Dependency array ensures recalculation only when `drawers` changes

    /**
     * Renders the processed drawer data into table rows.
     * Each row displays the drawer's properties and its aggregated quantity.
     */
    return (
        <tbody>
        {processedDrawers.map((drawer, index) => (
            <tr key={index}>
                <td>{drawer.cpId}</td>
                {/* Drawer unique identifier */}
                <td>{drawer.mfgNum}</td>
                {/* Manufacturing number */}
                <td>{drawer.width} in</td>
                {/* Width of the drawer in inches */}
                <td>{drawer.depth} in</td>
                {/* Depth of the drawer in inches */}
                <td>{drawer.height} in</td>
                {/* Height of the drawer in inches */}
                <td>{drawer.quantity}</td>
                {/* Aggregated quantity */}
            </tr>
        ))}
        </tbody>
    );
});

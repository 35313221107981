import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import {FaBox, FaBoxOpen, FaChevronLeft, FaCubes, FaLayerGroup, FaPaintBrush, FaTools, FaWrench} from "react-icons/fa";
import './TrendingStyles.css'

const TrendingComponent = () => {
    const [trendingData, setTrendingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_BASEURL;
    useEffect(() => {
        const fetchTrendingData = async () => {
            try {
                const response = await fetch(`${baseURL}/admin/internal/trending`);
                if (!response.ok) {
                    throw new Error("Failed to fetch trending statistics");
                }
                const data = await response.json();

                const transformData = (entries) =>
                    entries.map((entry) => {
                        const key = Object.keys(entry)[0];
                        const value = entry[key];
                        return {name: key, count: value};
                    });

                setTrendingData({
                    topTrendingArticles: transformData(data.topTrendingArticles),
                    topTrendingCaseMaterials: transformData(data.topTrendingCaseMaterials),
                    topTrendingFrontMaterials: transformData(data.topTrendingFrontMaterials),
                    topTrendingDrawerboxes: transformData(data.topTrendingDrawerboxes),
                    topTrendingBackMaterials: transformData(data.topTrendingBackMaterials),
                    numOfOrdersWithFinishing: transformData(data.numOfOrdersWithFinishing),
                    numOfOrdersWithAssembly: transformData(data.numOfOrdersWithAssembly),
                    numOfOrdersWithHardware: transformData(data.numOfOrdersWithHardware),
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTrendingData();
    }, []);

    const trendingCategories = [
        {
            title: "Top Selling Articles",
            icon: <FaBox className="trc-header-icon"/>,
            data: trendingData?.topTrendingArticles || [],
            category: "products"
        },
        {
            title: "Top Case Materials",
            icon: <FaLayerGroup className="trc-header-icon"/>,
            data: trendingData?.topTrendingCaseMaterials || [],
            category: "materials"
        },
        {
            title: "Top Front Materials",
            icon: <FaCubes className="trc-header-icon"/>,
            data: trendingData?.topTrendingFrontMaterials || [],
            category: "materials"
        },
        {
            title: "Top Drawer Boxes",
            icon: <FaBoxOpen className="trc-header-icon"/>,
            data: trendingData?.topTrendingDrawerboxes || [],
            category: "products"
        },
        {
            title: "Top Back Materials",
            icon: <FaChevronLeft className="trc-header-icon"/>,
            data: trendingData?.topTrendingBackMaterials || [],
            category: "materials"
        },
        {
            title: "Orders with Finishing",
            icon: <FaPaintBrush className="trc-header-icon"/>,
            data: trendingData?.numOfOrdersWithFinishing || [],
            category: "services"
        },
        {
            title: "Orders with Assembly",
            icon: <FaTools className="trc-header-icon"/>,
            data: trendingData?.numOfOrdersWithAssembly || [],
            category: "services"
        },
        {
            title: "Orders with Hardware",
            icon: <FaWrench className="trc-header-icon"/>,
            data: trendingData?.numOfOrdersWithHardware || [],
            category: "services"
        }
    ];

    if (loading) {
        return (
            <Container fluid className="trc-container bg-white py-5">
                <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
                    <Spinner animation="border" variant="dark" className="mb-3"/>
                    <p className="text-muted fs-5">Loading trending data...</p>
                </div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container fluid className="trc-container bg-white py-5">
                <div className="d-flex justify-content-center">
                    <Alert variant="danger" className="col-md-6">
                        <Alert.Heading>Error Loading Data</Alert.Heading>
                        <p className="mb-0">{error}</p>
                    </Alert>
                </div>
            </Container>
        );
    }

    return (
        <Container fluid className="trc-container bg-white py-4">
            <Row className="mb-5">
                <Col className="text-center">
                    <h1 className="trc-title display-4 fw-bold mb-2">Trending Statistics</h1>
                    <p className="trc-subtitle text-muted fs-5">
                        Real-time insights into product performance and customer preferences
                    </p>
                </Col>
            </Row>

            {["products", "materials", "services"].map((category) => (
                <div key={category} className="mb-5">
                    <h2 className="trc-section-title text-uppercase fs-5 fw-bold mb-4 border-bottom pb-2">
                        {category.charAt(0).toUpperCase() + category.slice(1)} Analytics
                    </h2>
                    <Row className="g-4">
                        {trendingCategories
                            .filter((item) => item.category === category)
                            .map((item, idx) => (
                                <Col key={idx} md={6} xl={3}>
                                    <div className="trc-panel card h-100 shadow-sm">
                                        <div className="card-header bg-dark text-white py-3">
                                            <div className="trc-header-wrapper d-flex align-items-center">
                                                <div className="trc-icon-wrapper">
                                                    {item.icon}
                                                </div>
                                                <h3 className="trc-panel-title fs-6 fw-bold mb-0 ms-2 d-flex align-items-center">
                                                    {item.title}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <ul className="list-group list-group-flush">
                                                {item.data.length > 0 ? (
                                                    item.data.map((dataItem, index) => (
                                                        <li
                                                            key={index}
                                                            className="list-group-item d-flex justify-content-between align-items-center py-3"
                                                        >
                                                            <span className="trc-item-name">
                                                                {dataItem.name === 'true' ? 'Orders' : dataItem.name}
                                                            </span>
                                                            <span className="trc-item-count badge bg-dark rounded-pill">
                                                                {dataItem.count}
                                                            </span>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li className="list-group-item text-center text-muted py-3">
                                                        No data available
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                    </Row>
                </div>
            ))}
        </Container>
    );
};

export default TrendingComponent;
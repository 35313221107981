// components/ShowRoom/CabinetCard/CardButtons.js

import React from 'react';

/**
 * The CardButtons component renders action buttons for a cabinet card.
 * These buttons allow users to configure a cabinet or add it to the cart.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onConfigure - Callback function invoked when the "Configure" button is clicked.
 * @param {Function} props.onAddToCart - Callback function invoked when the "Add" button is clicked.
 *
 * @returns {JSX.Element} - A group of action buttons for the cabinet card.
 */
const CardButtons = ({onConfigure, onAddToCart}) => (
    <div className="showroom-button-group">
        {/* Button to configure the cabinet */}
        <button
            className="btn btn-black showroom-btn-black"
            onClick={onConfigure}
        >
            Configure
        </button>

        {/* Button to add the cabinet to the cart */}
        <button
            className="btn btn-black showroom-btn-black"
            onClick={onAddToCart}
        >
            Add
        </button>
    </div>
);

export default CardButtons;

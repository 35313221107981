import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {selectGlobalSettings, updateGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
import {fetchMaterials, selectMaterialsStatus, selectVisibleMaterials} from '../../features/materials/materialsSlice';
import {
    fetchEdgebanding,
    selectEdgebandingStatus,
    selectVisibleEdgebanding
} from '../../features/edgebanding/edgebandingSlice';
import './GlobalSettingsStyles.css';
import {FaInfoCircle, FaSave} from 'react-icons/fa';
import {useFetchConfigOptions} from "../OrderComponent/hooks/useFetchConfigOptions";

function GlobalSettingsComponent() {
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux selectors
    const globalSettings = useSelector(selectGlobalSettings);
    const materials = useSelector(selectVisibleMaterials);
    const edgebandingOptions = useSelector(selectVisibleEdgebanding);
    const materialsStatus = useSelector(selectMaterialsStatus);
    const edgebandingStatus = useSelector(selectEdgebandingStatus);

    // Local state for config options
    const [jointOptions, setJointOptions] = useState([]);
    const [hingeOptions, setHingeOptions] = useState([]);
    const [backPanelOptions, setBackPanelOptions] = useState([]);
    const [drawerOptions, setDrawerOptions] = useState([]);
    const [configOptions, setConfigOptions] = useState([]);

    const baseURL = process.env.REACT_APP_BASEURL;

    // Use the config options hook
    useFetchConfigOptions(
        baseURL,
        setConfigOptions,
        setJointOptions,
        setDrawerOptions,
        setHingeOptions,
        setBackPanelOptions
    );

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});

        // Fetch materials and edgebanding using Redux actions
        dispatch(fetchMaterials());
        dispatch(fetchEdgebanding());
    }, [dispatch]);

    // Update global settings in the Redux store
    const handleInputChange = (field, value) => {
        dispatch(updateGlobalSettings({[field]: value}));
    };

    // Render dropdown options dynamically based on provided data
    const renderDropdown = (label, fieldName, options = []) => (
        <div className="global-settings__form-group">
            <label htmlFor={fieldName} className="global-settings__label">
                {label}
                <FaInfoCircle
                    className="global-settings__info-icon"
                    onClick={() => handleToolTipClick(fieldName)}
                />
            </label>
            <select
                id={fieldName}
                className="global-settings__select"
                value={globalSettings[fieldName] || options[0] || ''}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );

    // Navigate to a tooltip explanation page for the given field
    const handleToolTipClick = (field) => {
        navigate("/the-sealab/tool-tip/explain", {state: {field: field}});
    };

    // Navigate back to the previous page after saving settings
    const handleSaveSettings = () => {
        navigate(-1);
    };

    // Loading states
    if (materialsStatus === 'loading' || edgebandingStatus === 'loading') {
        return <div className="loading-spinner">Loading...</div>;
    }

    return (
        <>
            <div className="global-settings-page min-vh-100">
                <div className="global-settings__container">
                    <h1 className="global-settings__title">Global Settings</h1>
                    <div className="global-settings__content">
                        {/* Materials Section */}
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Materials</h2>
                            {renderDropdown('Case Material', 'caseMaterial', materials.map(m => m.description))}
                            {renderDropdown('Front Material', 'frontMaterial', materials.map(m => m.description))}
                            {renderDropdown('Back Panel Material', 'backPanelMaterial', materials.map(m => m.description))}
                        </section>

                        {/* Joint Methods Section */}
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Joint Methods</h2>
                            {renderDropdown('Joint Method', 'jointMethod', jointOptions)}
                            {renderDropdown('Back Panel Method', 'backPanel', backPanelOptions)}
                        </section>

                        {/* Edgebanding Section */}
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Edgebanding</h2>
                            {renderDropdown('Case Edge', 'caseEdge', edgebandingOptions.map(o => o.englishDescription))}
                            {renderDropdown('Front Edge', 'frontEdge', edgebandingOptions.map(o => o.englishDescription))}
                        </section>

                        {/* Door Settings Section */}
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Door Settings</h2>
                            {renderDropdown('Hinge Options', 'hingePlate', hingeOptions)}
                        </section>

                        {/* Drawer Settings Section */}
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Drawer Settings</h2>
                            {renderDropdown('Drawer Options', 'drawerType', drawerOptions)}
                        </section>

                        {/* Save Button */}
                        <button className="global-settings__save-button" onClick={handleSaveSettings}>
                            <FaSave/> Save and Return
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GlobalSettingsComponent;
// orderVendorSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASEURL + '/api/order-vendors';

// Async thunk to fetch order vendors by order ID
export const fetchOrderVendorsByOrderId = createAsyncThunk(
    'orderVendor/fetchByOrderId',
    async (orderId, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${BASE_URL}/order/${orderId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch order vendors');
        }
    }
);

// Async thunk to fetch order vendors by vendor ID
export const fetchOrderVendorsByVendorId = createAsyncThunk(
    'orderVendor/fetchByVendorId',
    async (vendorId, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${BASE_URL}/vendor/${vendorId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch order vendors');
        }
    }
);

const orderVendorSlice = createSlice({
    name: 'orderVendor',
    initialState: {
        orderVendors: [],
        loading: false,
        error: null,
    },
    reducers: {
        clearOrderVendors: (state) => {
            state.orderVendors = [];
            state.loading = false;
            state.error = null;
        },
        // Optional: You can add reducers here to handle updates if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderVendorsByOrderId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderVendorsByOrderId.fulfilled, (state, action) => {
                state.loading = false;
                state.orderVendors = action.payload;
            })
            .addCase(fetchOrderVendorsByOrderId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchOrderVendorsByVendorId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderVendorsByVendorId.fulfilled, (state, action) => {
                state.loading = false;
                state.orderVendors = action.payload;
            })
            .addCase(fetchOrderVendorsByVendorId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const {clearOrderVendors} = orderVendorSlice.actions;
export default orderVendorSlice.reducer;

// import React, {Suspense, useState} from 'react';
// import {Button} from 'react-bootstrap';
// import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
//
//
// // const PaymentElement = React.lazy(() => import('@stripe/react-stripe-js').then(module => ({default: module.PaymentElement})));
//
//
// const PaymentForm = ({price, onPaymentSuccess, onPaymentError, clientSecret}) => {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [processing, setProcessing] = useState(false);
//
//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setProcessing(true);
//
//         if (!stripe || !elements || !clientSecret) {
//             setProcessing(false);
//             return;
//         }
//
//         const {error, paymentIntent} = await stripe.confirmPayment({
//             elements,
//             confirmParams: {
//                 return_url: `${window.location.origin}/payment-complete`,
//             },
//             redirect: 'if_required',
//         });
//
//         if (error) {
//             onPaymentError(error.message);
//         } else if (paymentIntent) {
//             if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
//                 onPaymentSuccess(paymentIntent);
//             } else if (paymentIntent.status === 'requires_action' && paymentIntent.next_action.type === 'verify_with_microdeposits') {
//                 onPaymentError('Payment requires additional verification. Please check your email for further instructions.');
//             } else {
//                 onPaymentError('Payment failed. Please try again.');
//             }
//         } else {
//             onPaymentError('Payment failed. Please try again.');
//         }
//         setProcessing(false);
//     };
//
//     return (
//         <form onSubmit={handleSubmit}>
//             <Suspense fallback={<div>Loading Payment Options...</div>}>
//                 <PaymentElement
//                     options={{
//                         paymentMethodOrder: ['card', 'us_bank_account'],
//                         fields: {
//                             billingDetails: {
//                                 name: 'auto',
//                                 email: 'auto',
//                             }
//                         },
//                         wallets: {
//                             applePay: 'never',
//                             googlePay: 'never',
//                             cashApp: 'never',
//                             amazonPay: 'never'
//                         },
//                         us_bank_account: {
//                             verification_method: 'automatic', // Ensures only automatic (instant) verification is allowed
//                         }
//                     }}
//                 />
//             </Suspense>
//             <div className="d-flex justify-content-between align-items-center mt-3">
//                 <span className="processing-fee">Due Now: ${Number(price)}</span>
//                 <Button type="submit" variant="dark" disabled={!stripe || processing}>
//                     {processing ? 'Processing...' : 'Pay Now'}
//                 </Button>
//             </div>
//             <div className="disclaimer mt-3">
//                 <small>
//                     By clicking "Pay Now", you agree to The Sealab's <a href="/the-sealab/legal/terms" target="_blank"
//                                                                         className="hyperlink">Terms of Service</a>.
//                 </small>
//             </div>
//         </form>
//     );
// };
//
// export default PaymentForm;


import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

const PaymentForm = ({
                         fullAmount,
                         depositAmount,
                         selectedAmount,
                         onAmountChange,
                         onPaymentSuccess,
                         onPaymentError,
                         clientSecret,
                         showOptions,
                     }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    // Payment options: 'full', 'deposit', or 'custom'
    const [paymentOption, setPaymentOption] = useState('full');
    const [customAmount, setCustomAmount] = useState(depositAmount.toFixed(2));

    const handleOptionChange = (e) => {
        const option = e.target.value;
        setPaymentOption(option);
        if (option === 'full') {
            onAmountChange(fullAmount);
        } else if (option === 'deposit') {
            onAmountChange(depositAmount);
        } else if (option === 'custom') {
            // When switching to custom, ensure the current customAmount is at least the deposit amount.
            let amt = parseFloat(customAmount);
            if (isNaN(amt) || amt < depositAmount) {
                amt = depositAmount;
                setCustomAmount(amt.toFixed(2));
            }
            onAmountChange(amt);
        }
    };

    const handleCustomAmountChange = (e) => {
        let value = e.target.value;
        // Allow only numeric input with up to two decimal places.
        if (!/^\d*\.?\d{0,2}$/.test(value)) {
            return;
        }
        let amt = parseFloat(value);
        // If entered value is not a number or is less than the deposit amount,
        // auto-convert it to the deposit amount.
        if (isNaN(amt) || amt < depositAmount) {
            amt = depositAmount;
            value = depositAmount.toFixed(2);
            setCustomAmount(value);
            onAmountChange(amt);
            return;
        }
        // If the amount exceeds the full amount, auto-convert it to the full amount.
        if (amt > fullAmount) {
            amt = fullAmount;
            value = fullAmount.toFixed(2);
            setCustomAmount(value);
            onAmountChange(amt);
            return;
        }
        setCustomAmount(value);
        onAmountChange(amt);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (paymentOption === 'custom') {
            let amt = parseFloat(customAmount);
            if (isNaN(amt) || amt < depositAmount) {
                onPaymentError(`Custom amount must be at least $${depositAmount.toFixed(2)}.`);
                return;
            }
        }
        setProcessing(true);
        if (!stripe || !elements || !clientSecret) {
            setProcessing(false);
            return;
        }
        const {error, paymentIntent} = await stripe.confirmPayment({
            elements,
            confirmParams: {return_url: `${window.location.origin}/payment-complete`},
            redirect: 'if_required',
        });
        if (error) {
            onPaymentError(error.message);
        } else if (paymentIntent) {
            if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
                // Pass both paymentIntent and paymentOption upward.
                onPaymentSuccess({paymentIntent, paymentOption});
            } else if (
                paymentIntent.status === 'requires_action' &&
                paymentIntent.next_action &&
                paymentIntent.next_action.type === 'verify_with_microdeposits'
            ) {
                onPaymentError('Payment requires additional verification. Please check your email for further instructions.');
            } else {
                onPaymentError('Payment failed. Please try again.');
            }
        } else {
            onPaymentError('Payment failed. Please try again.');
        }
        setProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Render the PaymentElement at the top */}
            <PaymentElement
                options={{
                    paymentMethodOrder: ['card', 'us_bank_account'],
                    fields: {billingDetails: {name: 'auto', email: 'auto'}},
                    wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                        cashApp: 'never',
                        amazonPay: 'never',
                    },
                    us_bank_account: {verification_method: 'automatic'},
                }}
            />


            {showOptions && (
                <>
                    {/* Render the stacked options below the PaymentElement */}
                    <Form.Group className="mb-3 mt-3">
                        <Form.Check
                            type="radio"
                            label="Full Payment"
                            name="paymentOption"
                            value="full"
                            checked={paymentOption === 'full'}
                            onChange={handleOptionChange}
                            className="mb-2"
                        />
                        <Form.Check
                            type="radio"
                            label="Deposit (25%)"
                            name="paymentOption"
                            value="deposit"
                            checked={paymentOption === 'deposit'}
                            onChange={handleOptionChange}
                            className="mb-2"
                        />
                        <Form.Check
                            type="radio"
                            label="Custom Amount"
                            name="paymentOption"
                            value="custom"
                            checked={paymentOption === 'custom'}
                            onChange={handleOptionChange}
                            className="mb-2"
                        />
                    </Form.Group>
                    {paymentOption === 'custom' && (
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="number"
                                step="0.01"
                                min={depositAmount}
                                max={fullAmount}
                                value={customAmount}
                                onChange={handleCustomAmountChange}
                                placeholder="Enter custom amount"
                            />
                        </Form.Group>
                    )}
                </>

            )}


            {/* Render the Due Now summary and Pay Now button */}
            <div className="d-flex justify-content-between align-items-center mt-3">
                <span className="fw-bold">Due Now: ${parseFloat(selectedAmount).toFixed(2)}</span>
                <Button type="submit" variant="dark" disabled={!stripe || processing}>
                    {processing ? 'Processing...' : 'Pay Now'}
                </Button>
            </div>
            <div className="mt-3 text-muted" style={{fontSize: '0.9em'}}>
                By clicking "Pay Now", you agree to The Sealab's{' '}
                <a href="/the-sealab/legal/terms" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </a>.
            </div>
        </form>
    );
};

export default PaymentForm;

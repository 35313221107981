import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {URLS} from './constants/urls';
import {useOrderData} from './hooks/useOrderData';
import {MaterialsTable} from './tables/MaterialsTable';
import {HardwareTable} from './tables/HardwareTable';
import {DrawersTable} from './tables/DrawersTable';
import {handleDownloadDWG} from './utils/fileHandlers';
import DocImageCarousel from "../../ImageCarouselComponent/DocImageCarousel";
import './CabinetQCStyles.css';

/**
 * LoadingSpinner Component
 *
 * A simple spinner component to indicate loading state.
 *
 * @returns {JSX.Element} - Spinner with centered alignment.
 */
const LoadingSpinner = () => (
    <div className="d-flex justify-content-center align-items-center p-4 min-vh-100">
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
);

/**
 * CabinetQCComponent
 *
 * This component displays quality control details for a cabinet article, including:
 * - Dimensions, materials, hardware, and drawers.
 * - Links to download DWG files and view PDFs.
 * - Article summary and parts production details.
 *
 * @returns {JSX.Element} - The rendered quality control view for a cabinet.
 */
const CabinetQCComponent = () => {
    const location = useLocation(); // React Router hook to access location state
    const token = useSelector(state => state.user.accessToken); // Access user token from Redux store
    const {article: initialArticle, order} = location.state || {}; // Extract initial article and order from location state
    const {orderId, positionName} = useParams(); // Extract route parameters

    // Determine the final order ID, either from the order or the route params
    const finalOrderId = order?.orderId || orderId;

    // Fetch article data and related details using a custom hook
    const {
        article,
        caseSqft,
        frontSqft,
        backSqft,
        ebPrfName,
        ebSqft,
        hardware,
        drawers,
        caseMaterial,
        frontMaterial,
        backPanelMaterial,
        errorMessage,
        isLoading
    } = useOrderData(initialArticle, order, orderId, positionName, token);

    // Display a loading spinner if data is still loading
    if (isLoading) {
        return <LoadingSpinner/>;
    }

    // Construct the URL for the article's PDF
    const pdfURL = URLS.THUMBNAIL + `${finalOrderId}/Article_Report/${article?.positionName}.pdf`;

    return (
        <div className="container my-4 min-vh-100">
            {errorMessage ? (
                // Display an error message if there was an issue fetching data
                <div className="d-flex justify-content-center" role="alert">
                    <span><strong>{errorMessage}</strong></span>
                </div>
            ) : (
                <>
                    <div className="row mb-4" id="image-dimensions-container">
                        <div className="col-md-4">
                            {article && (
                                <>
                                    <p className="pos-txt">{article.positionName}</p>
                                    <div className="qc-links">
                                        <a
                                            href="#"
                                            className="qc-download-dwg"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDownloadDWG(finalOrderId, article);
                                            }}
                                        >
                                            {article.positionName}.dwg
                                        </a>

                                        <a
                                            href={`/pdf-viewer?pdfUrl=${encodeURIComponent(pdfURL)}`}
                                            className="qc-view-pdf"
                                        >
                                            View {article.positionName}.pdf
                                        </a>
                                    </div>

                                    <div className="doc__image-wrapper">
                                        <DocImageCarousel
                                            orderId={orderId}
                                            positionName={article.positionName}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-md-4">
                            <div className="card cbt-qc-card" id="dimensions-container">
                                <div className="dim-card d-flex flex-column" id="dimensions-card">
                                    {article?.width !== 0 && (
                                        <div className="d-flex justify-content-between">
                                            <p className="card-text dimensions-item"><strong>Width:</strong></p>
                                            <p className="card-text dimensions-item">{article?.width} in</p>
                                        </div>
                                    )}

                                    {article?.depth !== 0 && (
                                        <div className="d-flex justify-content-between">
                                            <p className="card-text dimensions-item"><strong>Depth:</strong></p>
                                            <p className="card-text dimensions-item">{article?.depth} in</p>
                                        </div>
                                    )}

                                    {article?.height !== 0 && (
                                        <div className="d-flex justify-content-between">
                                            <p className="card-text dimensions-item"><strong>Height:</strong></p>
                                            <p className="card-text dimensions-item">{article?.height} in</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-4 summary-title">Article Summary</h3>

                    {(caseSqft || frontSqft || ebSqft || article?.swEdgeSqft || article?.mEdgeSqft) && (
                        <div className="row production">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                                <div className="vertical-text" id="parts-vert-text">
                                    <span>PARTS PRODUCTION</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>MFG #</th>
                                                <th>QNTY</th>
                                            </tr>
                                            </thead>
                                            <MaterialsTable
                                                article={article}
                                                caseMaterial={caseMaterial}
                                                frontMaterial={frontMaterial}
                                                backPanelMaterial={backPanelMaterial}
                                                caseSqft={caseSqft}
                                                frontSqft={frontSqft}
                                                backSqft={backSqft}
                                                ebPrfName={ebPrfName}
                                                ebSqft={ebSqft}
                                            />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {Object.keys(hardware).length > 0 && (
                        <div className="row mt-4 hardware">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                                <div className="vertical-text" id="hardware-vert-text">
                                    <span>HARDWARE</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>MFG #</th>
                                                <th>QNTY</th>
                                            </tr>
                                            </thead>
                                            <HardwareTable hardware={hardware}/>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {drawers.length > 0 && (
                        <div className="row mt-4 drawers">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                                <div className="vertical-text" id="drawers-vert-text">
                                    <span>DRAWERS</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-body table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>MFG #</th>
                                                <th>Width</th>
                                                <th>Depth</th>
                                                <th>Height</th>
                                                <th>QNTY</th>
                                            </tr>
                                            </thead>
                                            <DrawersTable drawers={drawers}/>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CabinetQCComponent;

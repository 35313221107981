import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './OrderStyles.css';
import {
    addToCart,
    decrementQuantity,
    incrementQuantity,
    selectCart,
    updateItemsInCart
} from '../../features/cart/cartSlice';
import {selectGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {fetchMaterials, selectVisibleMaterials} from '../../features/materials/materialsSlice';
import {fetchEdgebanding, selectVisibleEdgebanding} from '../../features/edgebanding/edgebandingSlice';
import SerialNumberDisplay from './SerialNumberDisplay';
import QuantityControl from './QuantityControl';
import OrderForm from './OrderForm';

import {useFetchConfigOptions} from './hooks/useFetchConfigOptions';
import {useInitializeItemProperties} from './hooks/useInitializeItemProperties';
import {usePositionNameErrorHandling} from './hooks/usePositionNameErrorHandling';
import {useGlobalSettingsEffect} from './hooks/useGlobalSettingsEffect';
import {useEdgeBandingSync} from './hooks/useEdgeBandingSync';
import ImageCarousel from "../ImageCarouselComponent/ImageCarousel";

function OrderPageContainer() {
    const location = useLocation();
    const selectedItems = location.state?.items || [];
    const fromConfigureButton = location.state?.fromConfigureButton || false;
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const positionNameRef = useRef(null);
    const baseURL = process.env.REACT_APP_BASEURL;

    // Redux selectors for materials and edgebanding
    const materials = useSelector(selectVisibleMaterials);
    const edgebandingOptions = useSelector(selectVisibleEdgebanding);
    const globalSettings = useSelector(selectGlobalSettings);
    const cartItems = useSelector(selectCart);

    const uniqueId = location.state?.uniqueId;
    const foundItem = location.state?.items;

    // State declarations
    const [itemProperties, setItemProperties] = useState({});
    const [includeAssembly, setIncludeAssembly] = useState(false);
    const [includeLegLevelers, setIncludeLegLevelers] = useState(false);
    const [excludeFronts, setExcludeFronts] = useState(false);
    const [configOptions, setConfigOptions] = useState([]);
    const [jointOptions, setJointOptions] = useState([]);
    const [drawerOptions, setDrawerOptions] = useState([]);
    const [hingeOptions, setHingeOptions] = useState([]);
    const [backPanelOptions, setBackPanelOptions] = useState([]);
    const [applyGlobalSettings, setApplyGlobalSettings] = useState(false);
    const [positionNameError, setPositionNameError] = useState('');
    const [edgeBanding, setEdgeBanding] = useState({
        top: false,
        right: false,
        bottom: false,
        left: false,
    });

    const [errors, setErrors] = useState({
        height: false,
        width: false,
        depth: false,
        gapLeft: false,
        gapRight: false,
        gapTop: false,
        gapBottom: false,
        gapCenter: false,
        topDrwrHeightValue: false,
        leftCornerWidth: false,
        rightCornerDepth: false,
    });

    // Initial properties
    const initialProperties = {
        caseMaterial: selectedItems[0]?.caseMaterial,
        frontMaterial: selectedItems[0]?.frontMaterial,
        backPanelMaterial: selectedItems[0]?.backPanelMaterial,
        caseEdge: selectedItems[0]?.caseEdge,
        frontEdge: selectedItems[0]?.frontEdge,
    };

    // Custom hooks
    useFetchConfigOptions(baseURL, setConfigOptions, setJointOptions, setDrawerOptions, setHingeOptions, setBackPanelOptions);
    useInitializeItemProperties(fromConfigureButton, foundItem, selectedItems, includeLegLevelers, setIncludeLegLevelers, setItemProperties, itemProperties);
    usePositionNameErrorHandling(positionNameError, positionNameRef, itemProperties);
    useGlobalSettingsEffect(applyGlobalSettings, globalSettings, itemProperties, setItemProperties);
    useEdgeBandingSync(itemProperties, setEdgeBanding);

    // Fetch materials and edgebanding on component mount
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'instant'})
        dispatch(fetchMaterials());
        dispatch(fetchEdgebanding());
    }, [dispatch]);

    const parseRange = (range) => {
        if (!range) return [0, 100];
        const parts = range.split('-');
        const min = parts[0] ? Number(parts[0]) : 0;
        const max = parts[1] ? Number(parts[1]) : Number(parts[0]);
        if (isNaN(min) || isNaN(max)) {
            return [0, 100];
        }
        return [min, max];
    };

    const handleInputChange = (e, field) => {
        const {value} = e.target;
        let transformedValue = value;
        let isError = false;

        const limitDecimalPlaces = (val) => {
            const parts = val.split('.');
            if (parts.length > 1) {
                return `${parts[0]}.${parts[1].slice(0, 3)}`;
            }
            return val;
        };

        if (['gapTop', 'gapBottom', 'gapLeft', 'gapRight', 'gapCenter'].includes(field)) {
            transformedValue = parseFloat(value);
        } else if (['height', 'width', 'depth', 'leftCornerWidth', 'rightCornerDepth', 'topDrwrHeightValue'].includes(field)) {
            let min, max;
            if (field === 'topDrwrHeightValue') {
                [min, max] = [4, 28];
            } else {
                [min, max] = parseRange(itemProperties[`${field}Range`]);
            }
            const numValue = parseFloat(value);
            if (isNaN(numValue) || numValue < min || numValue > max) {
                isError = true;
            }
            transformedValue = limitDecimalPlaces(value.replace(/[^0-9.]/g, ''));
        } else if (field === 'positionName') {
            transformedValue = value.replace(/-/g, '_');
            const pattern = /(.*?)(_(\d+)|(\d+))?$/;
            const match = pattern.exec(transformedValue);

            let positionNamePrefix = transformedValue;
            let positionNameNumber = 1;
            let hasLeadingZeros = false;

            if (match) {
                positionNamePrefix = match[1];
                if (match[3]) {
                    positionNameNumber = parseInt(match[3], 10);
                    hasLeadingZeros = match[3].startsWith('0');
                } else if (match[4]) {
                    positionNameNumber = parseInt(match[4], 10);
                }
            }

            const relatedItems = cartItems.cart.filter(item =>
                item.positionName?.startsWith(positionNamePrefix)
            );

            const reservedNames = new Set();
            let highestPositionNumber = 1;

            relatedItems.forEach(item => {
                let itemQuantity = item.quantity || 1;
                let baseNumber = 1;

                const itemMatch = pattern.exec(item.positionName);
                if (itemMatch && itemMatch[3]) {
                    baseNumber = parseInt(itemMatch[3], 10);
                } else if (itemMatch && itemMatch[4]) {
                    baseNumber = parseInt(itemMatch[4], 10);
                }

                for (let i = 0; i < itemQuantity; i++) {
                    const reservedName = `${positionNamePrefix}_${String(baseNumber + i).padStart(3, '0')}`;
                    reservedNames.add(reservedName);
                    highestPositionNumber = Math.max(highestPositionNumber, baseNumber + i);
                }
            });

            let nextAvailableName;
            let nextAvailableNumber = highestPositionNumber + 1;
            do {
                nextAvailableName = `${positionNamePrefix}_${String(nextAvailableNumber).padStart(3, '0')}`;
                nextAvailableNumber++;
            } while (reservedNames.has(nextAvailableName));

            if (reservedNames.has(transformedValue)) {
                setPositionNameError(`The item name "${transformedValue}" is reserved or already in use. The next available name is "${nextAvailableName}".`);
            } else {
                setPositionNameError('');
            }
        }

        setItemProperties((prevProps) => ({
            ...prevProps,
            [field]: transformedValue,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: isError,
        }));

    };

    useEffect(() => {
        if (positionNameError && positionNameRef.current) {
            setTimeout(() => {
                positionNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                positionNameRef.current.focus();
            }, 100);
        }
    }, [itemProperties.positionName, positionNameError]);

    const handleUpdateItems = async (e) => {
        e.preventDefault();
        if (positionNameError && positionNameRef.current) {
            setTimeout(() => {
                positionNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                positionNameRef.current.focus();
            }, 100);
        }

        if (!positionNameError) {
            if (fromConfigureButton) {
                const updatedItem = {
                    ...itemProperties,
                    id: itemProperties.itemId,
                    positionName: itemProperties.positionName,
                    legLevelers: includeLegLevelers,
                    includeAssembly: includeAssembly ? 'true' : 'false',
                    includeLegLevelers: includeLegLevelers ? 'true' : 'false',
                    bottomPanelConnector: includeLegLevelers ? 'Leg Levelers' : null,
                    edgeBandingType: itemProperties.edgeBandingType || 'No Edgebanding',
                    edge1: edgeBanding.bottom ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge2: edgeBanding.right ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge3: edgeBanding.top ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge4: edgeBanding.left ? itemProperties.edgeBandingType : 'No Edgebanding',
                    gapTop: itemProperties?.gapTop,
                    gapBottom: itemProperties?.gapBottom,
                    gapLeft: itemProperties?.gapLeft,
                    gapRight: itemProperties?.gapRight,
                    quantity: foundItem[0].quantity,
                    edgeBandingTop: edgeBanding.top ? 'true' : 'false',
                    edgeBandingRight: edgeBanding.right ? 'true' : 'false',
                    edgeBandingBottom: edgeBanding.bottom ? 'true' : 'false',
                    edgeBandingLeft: edgeBanding.left ? 'true' : 'false',
                    excludeFronts: excludeFronts,
                    frontMaterial: excludeFronts ? 'Fronts By Others' : itemProperties.frontMaterial,
                };
                await dispatch(addToCart(updatedItem));
                navigate(-1);
            } else if (Object.values(errors).every((error) => !error)) {
                const updatedItems = selectedItems.map((item) => ({
                    ...item,
                    ...itemProperties,
                    itemId: item.itemId,
                    // positionName: itemProperties.positionName,
                    includeLegLevelers: includeLegLevelers ? 'true' : 'false',
                    bottomPanelConnector: includeLegLevelers ? 'Leg Levelers' : null,
                    excludeFronts: itemProperties?.excludeFronts,
                    frontMaterial: excludeFronts ? 'Fronts By Others' : itemProperties.frontMaterial,
                    edgeBandingType: itemProperties.edgeBandingType || 'No Edgebanding',
                    edgeBandingTop: edgeBanding.top ? 'true' : 'false',
                    edgeBandingRight: edgeBanding.right ? 'true' : 'false',
                    edgeBandingBottom: edgeBanding.bottom ? 'true' : 'false',
                    edgeBandingLeft: edgeBanding.left ? 'true' : 'false',
                    edge1: edgeBanding.bottom ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge2: edgeBanding.right ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge3: edgeBanding.top ? itemProperties.edgeBandingType : 'No Edgebanding',
                    edge4: edgeBanding.left ? itemProperties.edgeBandingType : 'No Edgebanding',
                }));
                if (!applyGlobalSettings && (initialProperties?.caseMaterial !== itemProperties?.caseMaterial ||
                    initialProperties?.frontMaterial !== itemProperties?.frontMaterial ||
                    initialProperties?.backPanelMaterial !== itemProperties?.backPanelMaterial ||
                    initialProperties?.caseEdge !== itemProperties?.caseEdge ||
                    initialProperties?.frontEdge !== itemProperties?.frontEdge)) {

                    const itemsWithGlobalSettings = updatedItems.map((item) => ({
                        ...item,
                        globalSettingsApplied: false
                    }))

                    await dispatch(updateItemsInCart(itemsWithGlobalSettings))
                } else {
                    await dispatch(updateItemsInCart(updatedItems));
                }
                navigate(-1);
            }
        }
    };

    const handleToolTipClick = (field) => {
        navigate("/learning/tool-tip/explain", {state: {field: field}})
    }

    const handleIncrement = (itemId) => {
        if (itemId && itemProperties.serialNumber) {
            dispatch(incrementQuantity({id: itemId}));
            setItemProperties({...itemProperties, quantity: itemProperties.quantity += 1})
        }
    }

    const handleDecrement = (itemId) => {
        if (itemId && itemProperties.serialNumber && itemProperties.quantity > 1) {
            dispatch(decrementQuantity({id: itemId}));
            setItemProperties({...itemProperties, quantity: itemProperties.quantity -= 1});
        }
    }

    const itemsToRender = fromConfigureButton ? [foundItem[0]] : selectedItems;
    const uniqueSerialNumbers = [...new Set(itemsToRender.map(item => item.serialNumber))];

    return (
        <div className="order-page">
            <div className="order-page__container">
                <SerialNumberDisplay uniqueSerialNumbers={uniqueSerialNumbers}/>
                <div className="order-page__layout">
                    <div className="order-page__image-section">
                        {uniqueSerialNumbers.map((serialNumber) => (
                            <div key={serialNumber} className="order-page__image-wrapper">
                                <ImageCarousel
                                    cabinet={itemsToRender.find(item => item.serialNumber === serialNumber)}/>
                            </div>
                        ))}
                        <div className="order-page__description">{itemProperties.description}</div>
                        {itemProperties.serialNumber && (
                            <QuantityControl
                                itemProperties={itemProperties}
                                handleDecrement={handleDecrement}
                                handleIncrement={handleIncrement}
                            />
                        )}
                    </div>
                    <div className="order-page__form-section">
                        <OrderForm
                            fromConfigureButton={fromConfigureButton}
                            handleUpdateItems={handleUpdateItems}
                            applyGlobalSettings={applyGlobalSettings}
                            setApplyGlobalSettings={setApplyGlobalSettings}
                            itemProperties={itemProperties}
                            setItemProperties={setItemProperties}
                            errors={errors}
                            handleInputChange={handleInputChange}
                            handleToolTipClick={handleToolTipClick}
                            parseRange={parseRange}
                            selectedItems={selectedItems}
                            positionNameRef={positionNameRef}
                            positionNameError={positionNameError}
                            materials={materials}
                            excludeFronts={excludeFronts}
                            setExcludeFronts={setExcludeFronts}
                            backPanelOptions={backPanelOptions}
                            jointOptions={jointOptions}
                            drawerOptions={drawerOptions}
                            hingeOptions={hingeOptions}
                            edgebandingOptions={edgebandingOptions}
                            edgeBanding={edgeBanding}
                            setEdgeBanding={setEdgeBanding}
                            includeLegLevelers={includeLegLevelers}
                            setIncludeLegLevelers={setIncludeLegLevelers}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderPageContainer;
import React from "react";

/**
 * MaterialsTable Component
 *
 * This component renders a table body (`<tbody>`) displaying details about materials used,
 * including case material, front material, edge band, solid wood edge, and miter edge.
 * It uses `React.memo` to prevent unnecessary re-renders, ensuring better performance
 * when the props do not change.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.article - An object containing article-specific material details.
 * @param {string} props.caseMaterial - The case material name (fallback if `article.caseMaterial` is unavailable).
 * @param {string} props.frontMaterial - The front material name (fallback if `article.frontMaterial` is unavailable).
 * @param {number} props.caseSqft - The square footage of the case material.
 * @param {number} props.frontSqft - The square footage of the front material.
 * @param {string} props.ebPrfName - The name of the edge band profile.
 * @param {number} props.ebSqft - The footage of the edge band material.
 * @returns {JSX.Element} - A table body (`<tbody>`) rendering rows for each material type and quantity.
 */
export const MaterialsTable = React.memo(({
                                              article,
                                              caseMaterial,
                                              frontMaterial,
                                              backPanelMaterial,
                                              caseSqft,
                                              frontSqft,
                                              backSqft,
                                              ebPrfName,
                                              ebSqft
                                          }) => (
    <tbody>
    {caseSqft && (
        <tr>
            <td>Case Material</td>
            {/* Label for case material */}
            <td>{article?.caseMaterial || caseMaterial}</td>
            {/* Case material name (from article or fallback) */}
            <td>{caseSqft} sqft</td>
            {/* Square footage of the case material */}
        </tr>
    )}
    {frontSqft && (
        <tr>
            <td>Front Material</td>
            {/* Label for front material */}
            <td>{article?.frontMaterial || frontMaterial}</td>
            {/* Front material name (from article or fallback) */}
            <td>{frontSqft} sqft</td>
            {/* Square footage of the front material */}
        </tr>
    )}
    {backSqft && article.backPanelMaterial !== article.caseMaterial && (
        <tr>
            <td>Back Panel Material</td>
            <td>{article?.backPanelMaterial || backPanelMaterial}</td>
            <td>{backSqft} sqft</td>
        </tr>
    )}
    {}
    {ebSqft && (
        <tr>
            <td>Edge Band</td>
            {/* Label for edge band */}
            <td>{ebPrfName}</td>
            {/* Edge band profile name */}
            <td>{ebSqft} ft</td>
            {/* Footage of the edge band material */}
        </tr>
    )}
    {article?.swEdgeSqft && (
        <tr>
            <td>Solid Wood Edge</td>
            {/* Label for solid wood edge */}
            <td>{article?.swEdge}</td>
            {/* Solid wood edge material name */}
            <td>{article?.swEdgeSqft}</td>
            {/* Square footage of the solid wood edge */}
        </tr>
    )}
    {article?.mEdgeSqft && (
        <tr>
            <td>Miter Edge</td>
            {/* Label for miter edge */}
            <td>{article?.miterEdge}</td>
            {/* Miter edge material name */}
            <td>{article?.mEdgeSqft} ft</td>
            {/* Footage of the miter edge material */}
        </tr>
    )}
    </tbody>
));

import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const AdminRoute = ({element}) => {
    const user = useSelector(state => state.user);

    // Check if the user is an admin, otherwise redirect
    return user.isAdmin ? element : <Navigate to="/"/>;
};

export default AdminRoute;

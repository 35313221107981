import jsPDF from 'jspdf';
import QRCode from 'qrcode';

export const generatePDF = async (articles, order, imageExistsMap, imageURL, thumbNail) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 5;
    const cardWidth = (pageWidth - 4 * margin) / 3;
    const cardHeight = (pageHeight - 6 * margin) / 5;

    for (let i = 0; i < articles.length; i++) {
        if (i > 0 && i % 15 === 0) {
            pdf.addPage();
        }

        const article = articles[i];
        const col = i % 3;
        const row = Math.floor((i % 15) / 3);

        const x = margin + col * (cardWidth + margin);
        const y = margin + row * (cardHeight + margin);

        const qrCodeDataUrl = await QRCode.toDataURL(
            `https://www.thesealab.com/api/cabinet/quality-control/${order.orderId}/${article.positionName}`
        );

        pdf.setFontSize(6);
        pdf.text(`Serial Number: ${article.serialNumber}`, x + 2, y + 5);
        pdf.text(`Position #: ${article.positionName}`, x + 2, y + 9);
        pdf.text(`Dimensions: ${article.width}in x ${article.height}in x ${article.depth}in`, x + 2, y + 13);
        pdf.text(`Materials: ${article.caseMaterial}, ${article.frontMaterial}`, x + 2, y + 17);

        pdf.addImage(qrCodeDataUrl, 'PNG', x + 2, y + 20, 20, 20);

        const imgUrl = imageExistsMap[article.positionName]
            ? `${thumbNail}${order.orderId}/${article.positionName}.png`
            : `${imageURL}no_picture.jpg`;
        const img = await loadImage(imgUrl);
        pdf.addImage(img, 'PNG', x + 24, y + 20, cardWidth - 26, cardHeight - 22);

        pdf.rect(x, y, cardWidth, cardHeight);
    }

    return pdf;
};

export const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
    });
};
// import React, {useEffect, useState} from 'react';
// import {useNavigate, useParams} from 'react-router-dom';
// import {useDispatch, useSelector} from 'react-redux';
// import {BoxSeam, Clipboard, ListCheck, Rulers, Stack, Tools} from 'react-bootstrap-icons';
// import {fetchOrderSummary} from "../../../features/orders/orderSlice";
// import axios from 'axios';
// import './VendorPOStyles.css';
//
// const VendorPOComponent = () => {
//     const {vendorId} = useParams();
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [orderVendor, setOrderVendor] = useState(null);
//     const [editedAmount, setEditedAmount] = useState(0);
//     const [editedStatus, setEditedStatus] = useState('');
//     const [editedNotes, setEditedNotes] = useState('');
//     const [editedInvoiceId, setEditedInvoiceId] = useState(''); // NEW STATE FOR INVOICE ID
//     const [updateError, setUpdateError] = useState('');
//     const [isSaving, setIsSaving] = useState(false);
//
//     const {orderData, status, error} = useSelector(state => state.order);
//     const token = useSelector((state) => state.user.accessToken);
//     const baseURL = process.env.REACT_APP_BASEURL;
//
//     useEffect(() => {
//         const storedData = sessionStorage.getItem("selectedOrderVendor");
//
//         if (storedData) {
//             try {
//                 const data = JSON.parse(storedData);
//                 setOrderVendor(data);
//                 setEditedAmount(data.amountOwed);
//                 setEditedStatus(data.status);
//                 setEditedNotes(data.notes || '');
//                 setEditedInvoiceId(data.invoiceId || ''); // initialize invoice id
//                 if (data?.orderId) {
//                     dispatch(fetchOrderSummary({
//                         orderId: data.orderId,
//                         token,
//                         baseURL
//                     }));
//                 }
//             } catch (error) {
//                 console.error('Error parsing order data:', error);
//                 navigate(`/internal/vendors/${vendorId}`);
//             }
//         } else {
//             navigate(`/internal/vendors/${vendorId}`);
//         }
//     }, [vendorId, navigate, dispatch, token, baseURL]);
//
//     const handleUpdateVendor = async () => {
//         setIsSaving(true);
//         try {
//             const updatedVendor = {
//                 ...orderVendor,
//                 amountOwed: parseFloat(editedAmount),
//                 status: editedStatus,
//                 notes: editedNotes,
//                 invoiceId: editedInvoiceId // include the invoice id
//             };
//
//             const response = await axios.post(
//                 `${baseURL}/api/order-vendors/vendor/update-vendor`,
//                 updatedVendor,
//                 {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json'
//                     }
//                 }
//             );
//
//             if (response.status === 200) {
//                 setOrderVendor(updatedVendor);
//                 sessionStorage.setItem("selectedOrderVendor", JSON.stringify(updatedVendor));
//                 setUpdateError('');
//                 dispatch(fetchOrderSummary({
//                     orderId: updatedVendor.orderId,
//                     token,
//                     baseURL
//                 }));
//             }
//         } catch (error) {
//             setUpdateError('Failed to update vendor details.');
//         } finally {
//             setIsSaving(false);
//         }
//     };
//
//     console.log(orderData);
//
//     const renderMaterialRequirements = (data) => {
//         if (!data) return null;
//         return Object.entries(data).map(([key, value]) => (
//             <div key={key} className="vpo-data-item">
//                 <div className="vpo-data-main">
//                     <span className="vpo-data-key">{key}</span>
//                     <span className="vpo-data-value">{Number(value).toFixed(2)} sqft</span>
//                 </div>
//                 <div className="vpo-data-sub">
//                     <span>Estimated Sheets</span>
//                     <span>{Math.round((Number(value) / 32) * 1.2) || 1}</span>
//                 </div>
//             </div>
//         ));
//     };
//
//     const renderProfileRequirements = (data) => {
//         if (!data) return null;
//         return Object.entries(data).map(([key, value]) => (
//             <div key={key} className="vpo-data-item">
//                 <div className="vpo-data-main">
//                     <span className="vpo-data-key">{key}</span>
//                     <span className="vpo-data-value">{Number(value).toFixed(2)} ft</span>
//                 </div>
//             </div>
//         ));
//     };
//
//     const renderGeneralRequirements = (data) => {
//         if (!data) return null;
//         return Object.entries(data).map(([key, value]) => (
//             <div key={key} className="vpo-data-item">
//                 <div className="vpo-data-main">
//                     <span className="vpo-data-key">{key}</span>
//                     <span className="vpo-data-value">
//                         {typeof value === 'number' ? value.toFixed(2) : value.toString()}
//                     </span>
//                 </div>
//             </div>
//         ));
//     };
//
//     const renderHardwareRequirements = (data) => {
//         if (!data) return null;
//         return Object.entries(data)
//             .filter(([key]) => key !== 'Placeholder')
//             .map(([key, value]) => (
//                 <div key={key} className="vpo-data-item">
//                     <div className="vpo-data-main">
//                         <span className="vpo-data-key">{key}</span>
//                         <span className="vpo-data-value">
//                             {typeof value === 'number' ? value : value.toString()}
//                         </span>
//                     </div>
//                 </div>
//             ));
//     };
//
//     // Redesigned renderDrawerData for professional display.
//     // Groups drawers by identical width, height, and depth.
//     // Each dimension is shown with an inch (") appended.
//     const renderDrawerData = (data) => {
//         if (!data) return null;
//         const drawers = Array.isArray(data) ? data : Object.values(data);
//         const grouped = {};
//         drawers.forEach(drawer => {
//             const width = drawer.width || 'N/A';
//             const height = drawer.height || 'N/A';
//             const depth = drawer.depth || 'N/A';
//             const key = `${width}|${height}|${depth}`;
//             if (grouped[key]) {
//                 grouped[key].quantity += 1;
//             } else {
//                 grouped[key] = {width, height, depth, quantity: 1};
//             }
//         });
//         return (
//             <div className="drawer-grid">
//                 {Object.entries(grouped).map(([groupKey, {width, height, depth, quantity}]) => (
//                     <div key={groupKey} className="drawer-card">
//                         <div className="drawer-dimension">
//                             <span className="drawer-label">Width:</span>
//                             <span className="drawer-value">{width}"</span>
//                         </div>
//                         <div className="drawer-dimension">
//                             <span className="drawer-label">Height:</span>
//                             <span className="drawer-value">{height}"</span>
//                         </div>
//                         <div className="drawer-dimension">
//                             <span className="drawer-label">Depth:</span>
//                             <span className="drawer-value">{depth}"</span>
//                         </div>
//                         <div className="drawer-quantity">
//                             <span className="drawer-label">Quantity:</span>
//                             <span className="drawer-value">{quantity}</span>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         );
//     };
//
//     const renderDataSection = (title, Icon, data, renderFunction) => {
//         if (!data || Object.keys(data).length === 0) return null;
//         return (
//             <div className="vpo-card">
//                 <div className="vpo-card-header">
//                     <Icon size={20} className="vpo-icon"/>
//                     <h3>{title}</h3>
//                 </div>
//                 <div className="vpo-card-body">
//                     {renderFunction(data)}
//                 </div>
//             </div>
//         );
//     };
//
//     const renderVendorSpecificContent = () => {
//         switch (orderVendor?.role) {
//             case 'CNC_VENDOR':
//                 return (
//                     <>
//                         {renderDataSection(
//                             "Material Requirements",
//                             BoxSeam,
//                             orderData?.materialSqft,
//                             renderMaterialRequirements
//                         )}
//                         {renderDataSection(
//                             "Profile Requirements",
//                             Rulers,
//                             orderData?.prfData,
//                             renderProfileRequirements
//                         )}
//                         {renderDataSection(
//                             "Stretchable Purchase Parts",
//                             Stack,
//                             orderData?.sppData,
//                             renderGeneralRequirements
//                         )}
//                     </>
//                 );
//             case 'HARDWARE_VENDOR':
//                 return (
//                     <>
//                         {renderDataSection(
//                             "Hardware Requirements",
//                             Tools,
//                             orderData?.hardwareQuantity,
//                             renderHardwareRequirements
//                         )}
//                     </>
//                 );
//             // For DRAWER_VENDOR or DRAWERBOX_VENDOR, use the redesigned drawer render.
//             case 'DRAWER_VENDOR':
//             case 'DRAWERBOX_VENDOR':
//                 return (
//                     <div className="vpo-card">
//                         <div className="vpo-card-header">
//                             <ListCheck size={20} className="vpo-icon"/>
//                             <h3>Drawer Components</h3>
//                         </div>
//                         <div className="vpo-card-body">
//                             {renderDrawerData(orderData?.drawerData)}
//                         </div>
//                     </div>
//                 );
//             // New case for ASSEMBLY_VENDOR: render Assembly Details.
//             case 'ASSEMBLY_VENDOR':
//             case 'FINISHING_VENDOR': {
//                 const totalSqft = orderData?.materialSqft
//                     ? Object.values(orderData.materialSqft).reduce((acc, val) => acc + (parseFloat(val) || 0), 0)
//                     : 0;
//                 return (
//                     <div className="vpo-card">
//                         <div className="vpo-card-header">
//                             <Clipboard size={20} className="vpo-icon"/>
//                             <h3>Assembly Details</h3>
//                         </div>
//                         <div className="vpo-card-body">
//                             <div className="assembly-detail">
//                                 <span className="assembly-label">Total Material Sqft:</span>
//                                 <span className="assembly-value">{totalSqft.toFixed(2)} sqft</span>
//                             </div>
//                             <div className="assembly-detail">
//                                 <span className="assembly-label">Number of Cabinets:</span>
//                                 <span className="assembly-value">
//                                     {orderData?.numOfCabinets ? orderData.numOfCabinets : 'N/A'}
//                                 </span>
//                             </div>
//                         </div>
//                     </div>
//                 );
//             }
//             default:
//                 return null;
//         }
//     };
//
//     if (status === 'loading') {
//         return (
//             <div className="vpo-loading">
//                 <div className="spinner-border" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }
//
//     return (
//         <div className="vpo-container min-vh-100">
//             <header className="vpo-header">
//                 <div className="vpo-header-info">
//                     <h1>
//                         <Clipboard size={28} className="vpo-header-icon"/>
//                         Purchase Order
//                     </h1>
//                     <div className="vpo-meta">
//                         <div className="vpo-meta-item">
//                             <span className="vpo-meta-label">Vendor ID</span>
//                             <strong>{vendorId}</strong>
//                         </div>
//                         {orderVendor && (
//                             <div className="vpo-meta-item">
//                                 <span className="vpo-meta-label">Order ID</span>
//                                 <strong>{orderVendor.orderId}</strong>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//                 {orderVendor && (
//                     <div className="vpo-header-vendor">
//                         <h2>{orderVendor.vendorName}</h2>
//                         <span className="vpo-role">{orderVendor.role.replace('_', ' ')}</span>
//                     </div>
//                 )}
//             </header>
//
//             {updateError && (
//                 <div className="vpo-alert">{updateError}</div>
//             )}
//
//             {orderVendor && (
//                 <section className="vpo-edit-panel">
//                     <div className="vpo-edit-group full-width">
//                         <label className="vpo-label">Invoice ID</label>
//                         <input
//                             type="text"
//                             value={editedInvoiceId}
//                             onChange={(e) => setEditedInvoiceId(e.target.value)}
//                             className="vpo-input"
//                         />
//                     </div>
//                     <div className="vpo-edit-row">
//                         <div className="vpo-edit-group">
//                             <label className="vpo-label">Status</label>
//                             <div className="vpo-status-buttons">
//                                 {['paid', 'pending', 'overdue'].map(status => (
//                                     <button
//                                         key={status}
//                                         className={`vpo-status-btn ${editedStatus === status ? 'active' : ''} ${status}`}
//                                         onClick={() => setEditedStatus(status)}
//                                     >
//                                         {status.charAt(0).toUpperCase() + status.slice(1)}
//                                     </button>
//                                 ))}
//                             </div>
//                         </div>
//                         <div className="vpo-edit-group">
//                             <label className="vpo-label">Amount Owed</label>
//                             <div className="vpo-amount-field">
//                                 <span className="vpo-currency">$</span>
//                                 <input
//                                     type="number"
//                                     value={editedAmount}
//                                     onChange={(e) => setEditedAmount(e.target.value)}
//                                     step="0.01"
//                                     min="0"
//                                     className="vpo-input"
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="vpo-edit-group full-width">
//                         <label className="vpo-label">Notes</label>
//                         <textarea
//                             className="vpo-notes-field"
//                             value={editedNotes}
//                             onChange={(e) => setEditedNotes(e.target.value)}
//                             placeholder="Add notes about this vendor order..."
//                             rows={4}
//                         />
//                     </div>
//                     <div className="vpo-edit-group save-btn-group">
//                         <button
//                             className="vpo-save-btn"
//                             onClick={handleUpdateVendor}
//                             disabled={isSaving}
//                         >
//                             {isSaving ? 'Saving...' : 'Save Changes'}
//                         </button>
//                     </div>
//                 </section>
//             )}
//
//             <main className="vpo-content">
//                 <div className="vpo-grid">
//                     {renderVendorSpecificContent()}
//                 </div>
//             </main>
//         </div>
//     );
// };
//
// export default VendorPOComponent;


import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BoxSeam, Clipboard, ListCheck, Rulers, Stack, Tools} from 'react-bootstrap-icons';
import {fetchOrderSummary} from "../../../features/orders/orderSlice";
import axios from 'axios';
import {exportPOToPDF} from './util'; // Import the export function from util.js
import './VendorPOStyles.css';

const VendorPOComponent = () => {
    const {vendorId} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orderVendor, setOrderVendor] = useState(null);
    const [editedAmount, setEditedAmount] = useState(0);
    const [editedStatus, setEditedStatus] = useState('');
    const [editedNotes, setEditedNotes] = useState('');
    const [editedInvoiceId, setEditedInvoiceId] = useState(''); // NEW STATE FOR INVOICE ID
    const [updateError, setUpdateError] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const {orderData, status, error} = useSelector(state => state.order);
    const token = useSelector((state) => state.user.accessToken);
    const baseURL = process.env.REACT_APP_BASEURL;

    console.log('test')
    useEffect(() => {
        const storedData = sessionStorage.getItem("selectedOrderVendor");

        if (storedData) {
            try {
                const data = JSON.parse(storedData);
                setOrderVendor(data);
                setEditedAmount(data.amountOwed);
                setEditedStatus(data.status);
                setEditedNotes(data.notes || '');
                setEditedInvoiceId(data.invoiceId || ''); // initialize invoice id
                if (data?.orderId) {
                    dispatch(fetchOrderSummary({
                        orderId: data.orderId,
                        token,
                        baseURL
                    }));
                }
            } catch (error) {
                console.error('Error parsing order data:', error);
                navigate(`/internal/vendors/${vendorId}`);
            }
        } else {
            navigate(`/internal/vendors/${vendorId}`);
        }
    }, [vendorId, navigate, dispatch, token, baseURL]);

    const handleUpdateVendor = async () => {
        setIsSaving(true);
        try {
            const updatedVendor = {
                ...orderVendor,
                amountOwed: parseFloat(editedAmount),
                status: editedStatus,
                notes: editedNotes,
                invoiceId: editedInvoiceId // include the invoice id
            };

            const response = await axios.post(
                `${baseURL}/api/order-vendors/vendor/update-vendor`,
                updatedVendor,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                setOrderVendor(updatedVendor);
                sessionStorage.setItem("selectedOrderVendor", JSON.stringify(updatedVendor));
                setUpdateError('');
                dispatch(fetchOrderSummary({
                    orderId: updatedVendor.orderId,
                    token,
                    baseURL
                }));
            }
        } catch (error) {
            setUpdateError('Failed to update vendor details.');
        } finally {
            setIsSaving(false);
        }
    };

    console.log(orderData);

    const renderMaterialRequirements = (data) => {
        if (!data) return null;
        return Object.entries(data).map(([key, value]) => (
            <div key={key} className="vpo-data-item">
                <div className="vpo-data-main">
                    <span className="vpo-data-key">{key}</span>
                    <span className="vpo-data-value">{Number(value).toFixed(2)} sqft</span>
                </div>
                <div className="vpo-data-sub">
                    <span>Estimated Sheets</span>
                    <span>{Math.round((Number(value) / 32) * 1.2) || 1}</span>
                </div>
            </div>
        ));
    };

    const renderProfileRequirements = (data) => {
        if (!data) return null;
        return Object.entries(data).map(([key, value]) => (
            <div key={key} className="vpo-data-item">
                <div className="vpo-data-main">
                    <span className="vpo-data-key">{key}</span>
                    <span className="vpo-data-value">{Number(value).toFixed(2)} ft</span>
                </div>
            </div>
        ));
    };

    const renderGeneralRequirements = (data) => {
        if (!data) return null;
        return Object.entries(data).map(([key, value]) => (
            <div key={key} className="vpo-data-item">
                <div className="vpo-data-main">
                    <span className="vpo-data-key">{key}</span>
                    <span className="vpo-data-value">
                        {typeof value === 'number' ? value.toFixed(2) : value.toString()}
                    </span>
                </div>
            </div>
        ));
    };

    const renderHardwareRequirements = (data) => {
        if (!data) return null;
        return Object.entries(data)
            .filter(([key]) => key !== 'Placeholder')
            .map(([key, value]) => (
                <div key={key} className="vpo-data-item">
                    <div className="vpo-data-main">
                        <span className="vpo-data-key">{key}</span>
                        <span className="vpo-data-value">
                            {typeof value === 'number' ? value : value.toString()}
                        </span>
                    </div>
                </div>
            ));
    };

    // Redesigned renderDrawerData for professional display.
    // Groups drawers by identical width, height, and depth.
    // Each dimension is shown with an inch (") appended.
    const renderDrawerData = (data) => {
        if (!data) return null;
        const drawers = Array.isArray(data) ? data : Object.values(data);
        const grouped = {};
        drawers.forEach(drawer => {
            const width = drawer.width || 'N/A';
            const height = drawer.height || 'N/A';
            const depth = drawer.depth || 'N/A';
            const key = `${width}|${height}|${depth}`;
            if (grouped[key]) {
                grouped[key].quantity += 1;
            } else {
                grouped[key] = {width, height, depth, quantity: 1};
            }
        });
        return (
            <div className="drawer-grid">
                {Object.entries(grouped).map(([groupKey, {width, height, depth, quantity}]) => (
                    <div key={groupKey} className="drawer-card">
                        <div className="drawer-dimension">
                            <span className="drawer-label">Width:</span>
                            <span className="drawer-value">{width}"</span>
                        </div>
                        <div className="drawer-dimension">
                            <span className="drawer-label">Height:</span>
                            <span className="drawer-value">{height}"</span>
                        </div>
                        <div className="drawer-dimension">
                            <span className="drawer-label">Depth:</span>
                            <span className="drawer-value">{depth}"</span>
                        </div>
                        <div className="drawer-quantity">
                            <span className="drawer-label">Quantity:</span>
                            <span className="drawer-value">{quantity}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderDataSection = (title, Icon, data, renderFunction) => {
        if (!data || Object.keys(data).length === 0) return null;
        return (
            <div className="vpo-card">
                <div className="vpo-card-header">
                    <Icon size={20} className="vpo-icon"/>
                    <h3>{title}</h3>
                </div>
                <div className="vpo-card-body">
                    {renderFunction(data)}
                </div>
            </div>
        );
    };

    const renderVendorSpecificContent = () => {
        switch (orderVendor?.role) {
            case 'CNC_VENDOR':
                return (
                    <>
                        {renderDataSection(
                            "Material Requirements",
                            BoxSeam,
                            orderData?.materialSqft,
                            renderMaterialRequirements
                        )}
                        {renderDataSection(
                            "Profile Requirements",
                            Rulers,
                            orderData?.prfData,
                            renderProfileRequirements
                        )}
                        {renderDataSection(
                            "Stretchable Purchase Parts",
                            Stack,
                            orderData?.sppData,
                            renderGeneralRequirements
                        )}
                    </>
                );
            case 'HARDWARE_VENDOR':
                return (
                    <>
                        {renderDataSection(
                            "Hardware Requirements",
                            Tools,
                            orderData?.hardwareQuantity,
                            renderHardwareRequirements
                        )}
                    </>
                );
            // For DRAWER_VENDOR or DRAWERBOX_VENDOR, use the redesigned drawer render.
            case 'DRAWER_VENDOR':
            case 'DRAWERBOX_VENDOR':
                return (
                    <div className="vpo-card">
                        <div className="vpo-card-header">
                            <ListCheck size={20} className="vpo-icon"/>
                            <h3>Drawer Components</h3>
                        </div>
                        <div className="vpo-card-body">
                            {renderDrawerData(orderData?.drawerData)}
                        </div>
                    </div>
                );
            // New case for ASSEMBLY_VENDOR: render Assembly Details.
            case 'ASSEMBLY_VENDOR':
            case 'FINISHING_VENDOR': {
                const totalSqft = orderData?.materialSqft
                    ? Object.values(orderData.materialSqft).reduce((acc, val) => acc + (parseFloat(val) || 0), 0)
                    : 0;
                return (
                    <div className="vpo-card">
                        <div className="vpo-card-header">
                            <Clipboard size={20} className="vpo-icon"/>
                            <h3>Assembly Details</h3>
                        </div>
                        <div className="vpo-card-body">
                            <div className="assembly-detail">
                                <span className="assembly-label">Total Material Sqft:</span>
                                <span className="assembly-value">{totalSqft.toFixed(2)} sqft</span>
                            </div>
                            <div className="assembly-detail">
                                <span className="assembly-label">Number of Cabinets:</span>
                                <span className="assembly-value">
                                    {orderData?.numOfCabinets ? orderData.numOfCabinets : 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            }
            default:
                return null;
        }
    };

    // New handler for exporting the PO as a PDF.
    const handleExportPDF = async () => {
        await exportPOToPDF('vpo-container');
    };

    if (status === 'loading') {
        return (
            <div className="vpo-loading">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        // Added an id for the container so our PDF util can capture it.
        <div className="vpo-container min-vh-100">
            <header className="vpo-header no-export">
                <div className="vpo-header-info">
                    <h1>
                        <Clipboard size={28} className="vpo-header-icon"/>
                        Purchase Order
                    </h1>
                    <div className="vpo-meta">
                        <div className="vpo-meta-item">
                            <span className="vpo-meta-label">Vendor ID</span>
                            <strong>{vendorId}</strong>
                        </div>
                        {orderVendor && (
                            <div className="vpo-meta-item">
                                <span className="vpo-meta-label">Order ID</span>
                                <strong>{orderVendor.orderId}</strong>
                            </div>
                        )}
                    </div>
                </div>
                {orderVendor && (
                    <div className="vpo-header-vendor">
                        <h2>{orderVendor.vendorName}</h2>
                        <span className="vpo-role">{orderVendor.role.replace('_', ' ')}</span>
                    </div>
                )}
                {/* New Export to PDF button with bootstrap classes and no-export class */}
                <div className="vpo-export-btn-container no-export">
                    <button className="btn btn-dark" onClick={handleExportPDF}>
                        Export to PDF
                    </button>
                </div>
            </header>

            {updateError && (
                <div className="vpo-alert">{updateError}</div>
            )}

            {orderVendor && (
                <section className="vpo-edit-panel">
                    <div className="vpo-edit-group full-width">
                        <label className="vpo-label">Invoice ID</label>
                        <input
                            type="text"
                            value={editedInvoiceId}
                            onChange={(e) => setEditedInvoiceId(e.target.value)}
                            className="vpo-input"
                        />
                    </div>
                    <div className="vpo-edit-row">
                        <div className="vpo-edit-group">
                            <label className="vpo-label">Status</label>
                            <div className="vpo-status-buttons">
                                {['paid', 'pending', 'overdue'].map(status => (
                                    <button
                                        key={status}
                                        className={`vpo-status-btn ${editedStatus === status ? 'active' : ''} ${status}`}
                                        onClick={() => setEditedStatus(status)}
                                    >
                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="vpo-edit-group">
                            <label className="vpo-label">Amount Owed</label>
                            <div className="vpo-amount-field">
                                <span className="vpo-currency">$</span>
                                <input
                                    type="number"
                                    value={editedAmount}
                                    onChange={(e) => setEditedAmount(e.target.value)}
                                    step="0.01"
                                    min="0"
                                    className="vpo-input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="vpo-edit-group full-width">
                        <label className="vpo-label">Notes</label>
                        <textarea
                            className="vpo-notes-field"
                            value={editedNotes}
                            onChange={(e) => setEditedNotes(e.target.value)}
                            placeholder="Add notes about this vendor order..."
                            rows={4}
                        />
                    </div>
                    <div className="vpo-edit-group save-btn-group no-export">
                        <button
                            className="vpo-save-btn no-export"
                            onClick={handleUpdateVendor}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </section>
            )}

            <main className="vpo-content">
                <div className="vpo-grid">
                    {renderVendorSpecificContent()}
                </div>
            </main>
        </div>
    );
};

export default VendorPOComponent;

// import React, {useEffect} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import {useNavigate, useParams} from 'react-router-dom';
// import './VendorStyles.css';
// import {clearOrderVendors, fetchOrderVendorsByVendorId} from "../../../features/orderVendor/orderVendorSlice";
//
// const VendorComponent = () => {
//     const {vendorId} = useParams();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const {orderVendors, loading, error} = useSelector((state) => state.orderVendor);
//
//     useEffect(() => {
//         if (vendorId) {
//             dispatch(fetchOrderVendorsByVendorId(vendorId));
//         }
//         return () => {
//             dispatch(clearOrderVendors());
//         };
//     }, [vendorId, dispatch]);
//
//     const handleRowClick = (orderVendor) => {
//         if (orderVendor) {
//             sessionStorage.setItem("selectedOrderVendor", JSON.stringify(orderVendor));
//             navigate(`/internal/vendors/${orderVendor.vendorId}/purchase-order`);
//         }
//     };
//
//     return (
//         <div className="vc-container min-vh-100">
//             <h2 className="vc-title">Vendor Orders</h2>
//
//             {loading && <p className="vc-loading">Loading...</p>}
//             {error && <p className="vc-error">Error: {error}</p>}
//             {!loading && !error && orderVendors.length === 0 && (
//                 <p className="vc-no-orders">No orders found for this vendor.</p>
//             )}
//
//             {!loading && !error && orderVendors.length > 0 && (
//                 <div className="vc-table-responsive">
//                     <table className="table vc-table">
//                         <thead>
//                         <tr>
//                             <th scope="col">Order ID</th>
//                             <th scope="col">Vendor Name</th>
//                             <th scope="col">Role</th>
//                             <th scope="col">Amount Owed</th>
//                             <th scope="col">Status</th>
//                             <th scope="col">Created At</th>
//                             <th scope="col">Updated At</th>
//                         </tr>
//                         </thead>
//                         <tbody>
//                         {orderVendors.map((vendor) => (
//                             <tr
//                                 key={vendor.id}
//                                 className="vc-table-row"
//                                 onClick={() => handleRowClick(vendor)}
//                             >
//                                 <td>{vendor.orderId}</td>
//                                 <td>{vendor.vendorName || 'N/A'}</td>
//                                 <td>{vendor.role.replace('_', ' ')}</td>
//                                 <td>${vendor.amountOwed.toFixed(2)}</td>
//                                 <td>{vendor.status}</td>
//                                 <td>{new Date(vendor.createdAt).toLocaleDateString()}</td>
//                                 <td>{new Date(vendor.updatedAt).toLocaleDateString()}</td>
//                             </tr>
//                         ))}
//                         </tbody>
//                     </table>
//                 </div>
//             )}
//         </div>
//     );
// };
//
// export default VendorComponent;


import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import './VendorStyles.css';
import {clearOrderVendors, fetchOrderVendorsByVendorId} from "../../../features/orderVendor/orderVendorSlice";

const VendorComponent = () => {
    const {vendorId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {orderVendors, loading, error} = useSelector((state) => state.orderVendor);

    // Search states
    const [searchOrderId, setSearchOrderId] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [filteredVendors, setFilteredVendors] = useState([]);

    useEffect(() => {
        if (vendorId) {
            dispatch(fetchOrderVendorsByVendorId(vendorId));
        }
        return () => {
            dispatch(clearOrderVendors());
        };
    }, [vendorId, dispatch]);

    // Filter effect
    useEffect(() => {
        const filtered = orderVendors.filter(vendor => {
            const matchOrderId = vendor.orderId.toString().toLowerCase().includes(searchOrderId.toLowerCase());
            const matchStatus = vendor.status.toLowerCase().includes(searchStatus.toLowerCase());
            return matchOrderId && matchStatus;
        });
        setFilteredVendors(filtered);
    }, [orderVendors, searchOrderId, searchStatus]);

    const handleRowClick = (orderVendor) => {
        if (orderVendor) {
            sessionStorage.setItem("selectedOrderVendor", JSON.stringify(orderVendor));
            navigate(`/internal/vendors/${orderVendor.vendorId}/purchase-order`);
        }
    };

    const getStatusClass = (status) => {
        const baseClass = 'vc-table-row';
        const normalizedStatus = status.toLowerCase().trim();
        return `${baseClass} status-${normalizedStatus}`;
    };

    return (
        <div className="vc-container min-vh-100">
            <h2 className="vc-title">Vendor Orders</h2>

            {/* Search filters */}
            <div className="vc-filters">
                <div className="vc-filter-group">
                    <input
                        type="text"
                        placeholder="Search Order ID..."
                        value={searchOrderId}
                        onChange={(e) => setSearchOrderId(e.target.value)}
                        className="vc-search-input"
                    />
                    <input
                        type="text"
                        placeholder="Search Status..."
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                        className="vc-search-input"
                    />
                </div>
            </div>

            {loading && <p className="vc-loading">Loading...</p>}
            {error && <p className="vc-error">Error: {error}</p>}
            {!loading && !error && filteredVendors.length === 0 && (
                <p className="vc-no-orders">No orders found matching your criteria.</p>
            )}

            {!loading && !error && filteredVendors.length > 0 && (
                <div className="vc-table-responsive">
                    <table className="table vc-table">
                        <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Vendor Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Amount Owed</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Updated At</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredVendors.map((vendor) => (
                            <tr
                                key={vendor.id}
                                className={getStatusClass(vendor.status)}
                                onClick={() => handleRowClick(vendor)}
                            >
                                <td>{vendor.orderId}</td>
                                <td>{vendor.vendorName || 'N/A'}</td>
                                <td>{vendor.role.replace('_', ' ')}</td>
                                <td>${vendor.amountOwed.toFixed(2)}</td>
                                <td>{vendor.status.toUpperCase()}</td>
                                <td>{new Date(vendor.createdAt).toLocaleDateString()}</td>
                                <td>{new Date(vendor.updatedAt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default VendorComponent;
// features/edgebanding/edgebandingSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

// Async thunk for fetching edgebanding data
export const fetchEdgebanding = createAsyncThunk(
    'edgebanding/fetchEdgebanding',
    async (_, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${baseURL}/api/cabinet-features/getAllEdgebanding`);
            return response.data || []; // Ensure it returns an array
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch edgebanding');
        }
    }
);

// Async thunk for creating new edgebanding
export const createEdgebanding = createAsyncThunk(
    'edgebanding/createEdgebanding',
    async ({edgebanding, token}, {rejectWithValue}) => {
        try {
            const response = await axios.post(
                `${baseURL}/api/internal/create-edgebanding`,
                {
                    imosName: edgebanding.imosName,
                    englishDescription: edgebanding.englishDescription,
                    visible: parseInt(edgebanding.visible, 10),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create edgebanding');
        }
    }
);

// Slice
const edgebandingSlice = createSlice({
    name: 'edgebanding',
    initialState: {
        items: [], // All edgebanding items
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null, // Error message, if any
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEdgebanding.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEdgebanding.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload || []; // Ensure items is always an array
                state.error = null;
            })
            .addCase(fetchEdgebanding.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.items = []; // Reset items to avoid undefined
            })
            .addCase(createEdgebanding.fulfilled, (state, action) => {
                state.items.push(action.payload); // Add the new item
            });
    },
});

// **Memoized Selectors**
export const selectAllEdgebanding = (state) => state.edgebanding.items;

// Memoized selector for visible edgebanding items
export const selectVisibleEdgebanding = createSelector(
    [selectAllEdgebanding],
    (items) => items.filter((item) => item.visible === 1) // Filter visible items
);

export const selectEdgebandingStatus = (state) => state.edgebanding.status;
export const selectEdgebandingError = (state) => state.edgebanding.error;

export default edgebandingSlice.reducer;

import React, {useEffect, useState} from "react";
import axios from "axios";
import {Alert} from "react-bootstrap";
import "./ArticleManagementStyles.css";

const baseURL = process.env.REACT_APP_BASEURL;

const AssignArticles = ({articles, users, refreshData}) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [userSearchTerm, setUserSearchTerm] = useState("");
    const [articleSearchTerm, setArticleSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [statusVariant, setStatusVariant] = useState("");

    // Filter users based on search term
    const filteredUsers = users.filter((user) =>
        `${user.email} ${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes(userSearchTerm.toLowerCase())
    );

    // Filter articles based on search term
    const filteredArticles = articles.filter((article) =>
        `${article.serialNumber} ${article.description}`
            .toLowerCase()
            .includes(articleSearchTerm.toLowerCase())
    );

    const handleAssignArticle = async () => {
        if (!selectedUser || !selectedArticle) {
            setStatusMessage("Please select both a user and an article.");
            setStatusVariant("danger");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                `${baseURL}/api/internal/articles/${selectedArticle.serialNumber}/assign`,
                null,
                {params: {userId: selectedUser.customerId}}
            );

            // Set success message with specific details
            setStatusMessage(
                `Successfully assigned article ${selectedArticle.serialNumber} to ${selectedUser.firstName} ${selectedUser.lastName}`
            );
            setStatusVariant("success");

            // Clear selections but don't refresh
            setSelectedUser(null);
            setSelectedArticle(null);
            setUserSearchTerm("");
            setArticleSearchTerm("");

        } catch (error) {
            console.error("Error assigning article:", error);
            setStatusMessage(error.response?.data?.message || "Failed to assign article. Please try again.");
            setStatusVariant("danger");
        } finally {
            setLoading(false);
        }
    };

    // Clear status message after 5 seconds for error messages only
    useEffect(() => {
        if (statusMessage && statusVariant === "danger") {
            const timer = setTimeout(() => {
                setStatusMessage("");
                setStatusVariant("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [statusMessage, statusVariant]);

    return (
        <>
            {/* Status Message Alert */}
            {statusMessage && (
                <Alert
                    variant={statusVariant}
                    onClose={() => setStatusMessage("")}
                    dismissible
                    className="mb-4"
                >
                    {statusMessage}
                </Alert>
            )}

            <div className="row">
                {/* User Selection Section */}
                <div className="col-md-6 mb-4">
                    <div className="amc-card">
                        <h2 className="amc-section-title">
                            <span className="amc-step-number">1</span> Select User
                        </h2>
                        <div className="amc-search-box">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search users by name or email..."
                                value={userSearchTerm}
                                onChange={(e) => setUserSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="amc-user-grid">
                            {filteredUsers.map((user) => (
                                <div
                                    key={user.customerId}
                                    className={`amc-user-card ${
                                        selectedUser?.customerId === user.customerId ? "amc-selected" : ""
                                    }`}
                                    onClick={() => setSelectedUser(user)}
                                >
                                    <div className="amc-user-initial">{user.firstName[0]}</div>
                                    <div className="amc-user-info">
                                        <div className="amc-user-name">
                                            {user.firstName} {user.lastName}
                                        </div>
                                        <div className="amc-user-email">{user.email}</div>
                                    </div>
                                    {selectedUser?.customerId === user.customerId && (
                                        <div className="amc-selected-check">✓</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Article Selection Section */}
                <div className="col-md-6 mb-4">
                    <div className="amc-card">
                        <h2 className="amc-section-title">
                            <span className="amc-step-number">2</span> Select Article
                        </h2>
                        <div className="amc-search-box">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search articles by serial number or description..."
                                value={articleSearchTerm}
                                onChange={(e) => setArticleSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="amc-article-grid">
                            {filteredArticles.map((article) => (
                                <div
                                    key={article.serialNumber}
                                    className={`amc-article-card ${
                                        selectedArticle?.serialNumber === article.serialNumber ? "amc-selected" : ""
                                    }`}
                                    onClick={() => setSelectedArticle(article)}
                                >
                                    <div className="amc-article-serial">{article.serialNumber}</div>
                                    <div className="amc-article-description">{article.description}</div>
                                    {selectedArticle?.serialNumber === article.serialNumber && (
                                        <div className="amc-selected-check">✓</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Assignment Section */}
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="amc-card amc-text-center">
                        <div className="amc-assignment-summary">
                            {selectedUser && selectedArticle ? (
                                <div>
                                    Assign article <strong>{selectedArticle.serialNumber}</strong> to{" "}
                                    <strong>
                                        {selectedUser.firstName} {selectedUser.lastName}
                                    </strong>
                                </div>
                            ) : (
                                <div className="text-muted">
                                    Please select both a user and an article to proceed
                                </div>
                            )}
                        </div>

                        <button
                            type="button"
                            className="amc-submit-button"
                            onClick={handleAssignArticle}
                            disabled={loading || !selectedUser || !selectedArticle}
                        >
                            {loading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                "Assign Article"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssignArticles;
import React, {useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./CatalogInfoStyles.css";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import ImageCarousel from "../ImageCarouselComponent/ImageCarousel";
import {selectArticleBySerial, selectDimensions} from "../../features/suggestedArticles/suggestedArticlesSlice";
import {addToCart} from "../../features/cart/cartSlice";
import {nanoid} from "@reduxjs/toolkit";
import {Minus, Plus} from 'lucide-react';

const CatalogInfo = () => {
    const {serialNumber} = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);

    // Get article data from Redux store
    const articleData = useSelector((state) =>
        selectArticleBySerial(state, serialNumber)
    );
    const storedDimensions = useSelector(selectDimensions);

    // Create the cabinet object
    const cabinet = {
        id: nanoid(),
        serialNumber: serialNumber,
        description:
            articleData?.description ||
            searchParams.get("description") ||
            "No description available.",
        width:
            Number(searchParams.get("width")) ||
            storedDimensions.width ||
            0,
        height:
            Number(searchParams.get("height")) ||
            storedDimensions.height ||
            0,
        depth:
            Number(searchParams.get("depth")) ||
            storedDimensions.depth ||
            0,
        quantity: quantity,
        ...articleData,
    };

    const handleQuantityChange = (action) => {
        if (action === 'increase') {
            setQuantity(prev => prev + 1);
        } else if (action === 'decrease' && quantity > 1) {
            setQuantity(prev => prev - 1);
        }
    };

    const handleAddToCart = () => {
        const {width, height, depth} = cabinet;
        if (!width || !height || !depth) {
            alert("Please provide valid width, height, and depth dimensions before adding to the cart.");
            return;
        }
        dispatch(addToCart({...cabinet, quantity}));
    };

    return (
        <div className="ci-wrapper">
            <div className="ci-main container">
                <h1 className="ci-title">Product Details</h1>
                <p className="ci-serial">
                    Serial Number: {cabinet.serialNumber}
                </p>

                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="ci-image-container">
                            <ImageCarousel cabinet={cabinet}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="ci-info-section">
                            <h2 className="ci-section-heading">Description</h2>
                            <div className="ci-divider"></div>
                            <p className="ci-description">{cabinet.description}</p>
                        </div>

                        <div className="ci-info-section">
                            <h2 className="ci-section-heading">Dimensions</h2>
                            <div className="ci-divider"></div>
                            <div className="ci-dimensions">
                                <div className="ci-dimension-row">
                                    <span>Width:</span>
                                    <span className="ci-dimension-value">{cabinet.width}"</span>
                                </div>
                                <div className="ci-dimension-row">
                                    <span>Height:</span>
                                    <span className="ci-dimension-value">{cabinet.height}"</span>
                                </div>
                                <div className="ci-dimension-row">
                                    <span>Depth:</span>
                                    <span className="ci-dimension-value">{cabinet.depth}"</span>
                                </div>
                            </div>
                        </div>

                        <div className="ci-info-section">
                            <h2 className="ci-section-heading">Quantity</h2>
                            <div className="ci-divider"></div>
                            <div className="ci-quantity-control">
                                <button
                                    className="ci-quantity-btn"
                                    onClick={() => handleQuantityChange('decrease')}
                                    disabled={quantity <= 1}
                                >
                                    <Minus size={18}/>
                                </button>
                                <span className="ci-quantity-display">{quantity}</span>
                                <button
                                    className="ci-quantity-btn"
                                    onClick={() => handleQuantityChange('increase')}
                                >
                                    <Plus size={18}/>
                                </button>
                            </div>
                        </div>

                        <button
                            onClick={handleAddToCart}
                            className="ci-add-cart-btn"
                        >
                            Add to Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogInfo;
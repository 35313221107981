// // CustomerPortal.jsx
// import React, {useCallback, useEffect, useState} from 'react';
// import {useSelector} from 'react-redux';
// import {Link, useNavigate} from 'react-router-dom';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import {getAllPastOrdersByEmail} from '../../api/OrderService';
// import './CustomerPortalStyles.css';
// import {Helmet} from "react-helmet-async";
//
// const CustomerPortal = () => {
//     const userLoggedIn = UserLoggedIn();
//     const user = useSelector(state => state.user.user || {});
//     const token = useSelector(state => state.user.accessToken);
//     const [orders, setOrders] = useState(null);
//     const [filteredOrders, setFilteredOrders] = useState(null);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [isLoading, setIsLoading] = useState(true);
//     const navigate = useNavigate();
//
//     useEffect(() => {
//         window.scrollTo({top: 0, behavior: "smooth"});
//
//         const fetchOrders = async () => {
//             if (!userLoggedIn || !user.email || !token) return;
//
//             try {
//                 setIsLoading(true);
//                 const response = await getAllPastOrdersByEmail(user.email, token);
//                 setOrders(response.data);
//                 setFilteredOrders(response.data);
//             } catch (error) {
//                 console.error('Error fetching orders:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };
//
//         fetchOrders();
//     }, [userLoggedIn, user.email, token]);
//
//
//     const handleEditOrder = useCallback((order) => {
//         navigate(`/user/customer-portal/${order.orderId}/edit-order`, {
//             state: {order}
//         });
//
//     }, [navigate]);
//
//     const handleSearchChange = (e) => {
//         const query = e.target.value.trim().toLowerCase();
//         setSearchQuery(query);
//
//         if (!orders) return;
//
//         const filtered = orders.filter(order =>
//             order?.orderId?.toString().includes(query) ||
//             order?.status?.toLowerCase().includes(query) ||
//             order?.projectName?.toLowerCase().includes(query) ||
//             order?.purchaseOrder?.toLowerCase().includes(query)
//         );
//         setFilteredOrders(filtered);
//     };
//
//     const getStatusClass = (status) => {
//         const statusMap = {
//             'pending': 'status-pending',
//             'in production': 'status-in-production',
//             'ready for pickup': 'status-ready',
//             'shipped': 'status-shipped',
//             'delivered': 'status-delivered',
//             'complete': 'status-complete'
//         };
//         return statusMap[status?.toLowerCase()] || 'status-default';
//     };
//
//     const renderOrderCard = useCallback((order) => (
//         <div key={`order-${order?.orderId}`} className="col-lg-4 col-md-6 mb-4">
//             <div className="order-card">
//                 <div className="order-card-body">
//                     <div className="order-card-header">
//                         <h5 className="order-number">Order #{order?.orderId}</h5>
//                         <span className={`order-status ${getStatusClass(order?.status)}`}>
//                             {order?.status}
//                         </span>
//                     </div>
//                     <div className="order-details">
//                         <div className="detail-row">
//                             <span className="detail-label">Project Name:</span>
//                             <span className="detail-value">{order?.projectName}</span>
//                         </div>
//                         <div className="detail-row">
//                             <span className="detail-label">Purchase Order:</span>
//                             <span className="detail-value">{order?.purchaseOrder}</span>
//                         </div>
//                         <div className="detail-row">
//                             <span className="detail-label">Date:</span>
//                             <span className="detail-value">{order?.date}</span>
//                         </div>
//                     </div>
//                     <div className="order-card-actions">
//                         <Link
//                             to={`/user/customer-portal/${order.orderId}`}
//                             className="btn view-details-btn"
//                         >
//                             View Details
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     ), []);
//
//     return (
//         <div className="portal-wrapper">
//             <Helmet>
//                 <title>Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders</title>
//                 <meta
//                     name="description"
//                     content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
//                 />
//                 <meta
//                     name="keywords"
//                     content="customer portal, manage orders, order history, update account, The Sealab, kitchen cabinets, closet cabinets, cabinet orders"
//                 />
//                 <meta
//                     property="og:title"
//                     content="Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders"
//                 />
//                 <meta
//                     property="og:description"
//                     content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
//                 />
//                 <meta property="og:url" content="https://www.thesealab.com/user/customer-portal"/>
//                 <meta property="og:type" content="website"/>
//             </Helmet>
//
//             {/*{userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}*/}
//
//             <div className="portal-container">
//                 <h1 className="portal-title">Customer Portal</h1>
//
//                 {orders?.length > 1 && (
//                     <div className="search-container">
//                         <input
//                             type="text"
//                             placeholder="Search by Order ID, Status, Project Name, or Purchase Order"
//                             value={searchQuery}
//                             onChange={handleSearchChange}
//                             className="search-input"
//                             aria-label="Search orders"
//                         />
//                     </div>
//                 )}
//
//                 {isLoading ? (
//                     <div className="loading-state">
//                         <div className="spinner-border text-primary" role="status">
//                             <span className="visually-hidden">Loading...</span>
//                         </div>
//                     </div>
//                 ) : (
//                     <>
//                         {filteredOrders?.length > 0 ? (
//                             <div className="row g-4">
//                                 {filteredOrders.map(renderOrderCard)}
//                             </div>
//                         ) : filteredOrders !== null && orders?.length > 1 ? (
//                             <div className="no-results">
//                                 <h2>No orders match your search criteria</h2>
//                                 <p>Try adjusting your search terms</p>
//                             </div>
//                         ) : filteredOrders !== null && orders?.length <= 0 && (
//                             <div className="empty-state">
//                                 <h2>Your Customer Portal is Empty</h2>
//                                 <p>Please check back after you complete your first order.</p>
//                             </div>
//                         )}
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default CustomerPortal;


// CustomerPortal.jsx
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {getAllPastOrdersByEmail} from '../../api/OrderService';
import './CustomerPortalStyles.css';
import {Helmet} from "react-helmet-async";

/**
 * CustomerPortal Component
 *
 * Displays a portal for customers to view and manage their orders.
 * Features include:
 * - Fetching past orders
 * - Searching and filtering orders by various criteria
 * - Navigating to order details or edit pages
 *
 * @returns {JSX.Element} Rendered Customer Portal
 */
const CustomerPortal = () => {
    // Check if the user is logged in
    const userLoggedIn = UserLoggedIn();

    // Redux state selectors
    const user = useSelector(state => state.user.user || {}); // Current user information
    const token = useSelector(state => state.user.accessToken); // Authentication token

    // Local state for orders and UI interaction
    const [orders, setOrders] = useState(null); // All fetched orders
    const [filteredOrders, setFilteredOrders] = useState(null); // Orders after search filtering
    const [searchQuery, setSearchQuery] = useState(''); // Current search query
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const navigate = useNavigate(); // For programmatic navigation

    // Fetch all past orders when the component mounts or when dependencies change
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});

        const fetchOrders = async () => {
            if (!userLoggedIn || !user.email || !token) return;

            try {
                setIsLoading(true);
                const response = await getAllPastOrdersByEmail(user.email, token); // Fetch orders from API
                setOrders(response.data); // Save fetched orders to state
                setFilteredOrders(response.data); // Initialize filtered orders
            } catch (error) {
                console.error('Error fetching orders:', error); // Log any errors
            } finally {
                setIsLoading(false); // Stop the loading spinner
            }
        };

        fetchOrders();
    }, [userLoggedIn, user.email, token]);

    /**
     * Handles navigation to the edit order page.
     *
     * @param {Object} order The order object to edit
     */
    const handleEditOrder = useCallback((order) => {
        navigate(`/user/customer-portal/${order.orderId}/edit-order`, {
            state: {order}
        });
    }, [navigate]);

    /**
     * Handles changes to the search input field.
     * Filters orders based on the search query.
     *
     * @param {Event} e Input change event
     */
    const handleSearchChange = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);

        if (!orders) return;

        const filtered = orders.filter(order =>
            order?.orderId?.toString().includes(query) ||
            order?.status?.toLowerCase().includes(query) ||
            order?.projectName?.toLowerCase().includes(query) ||
            order?.purchaseOrder?.toLowerCase().includes(query)
        );
        setFilteredOrders(filtered);
    };

    /**
     * Returns a CSS class based on the order status.
     *
     * @param {string} status The status of the order
     * @returns {string} The CSS class for the status
     */
    const getStatusClass = (status) => {
        const statusMap = {
            'pending': 'status-pending',
            'in production': 'status-in-production',
            'ready for pickup': 'status-ready',
            'shipped': 'status-shipped',
            'delivered': 'status-delivered',
            'complete': 'status-complete'
        };
        return statusMap[status?.toLowerCase()] || 'status-default';
    };

    /**
     * Renders an individual order card.
     *
     * @param {Object} order The order object to render
     * @returns {JSX.Element} Rendered order card
     */
    const renderOrderCard = useCallback((order) => (
        <div key={`order-${order?.orderId}`} className="col-lg-4 col-md-6 mb-4">
            <div className="order-card">
                <div className="order-card-body">
                    <div className="order-card-header">
                        <h5 className="order-number">Order #{order?.orderId}</h5>
                        <span className={`order-status ${getStatusClass(order?.status)}`}>
                            {order?.status}
                        </span>
                    </div>
                    <div className="order-details">
                        <div className="detail-row">
                            <span className="detail-label">Project Name:</span>
                            <span className="detail-value">{order?.projectName}</span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Purchase Order:</span>
                            <span className="detail-value">{order?.purchaseOrder}</span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Date:</span>
                            <span className="detail-value">{order?.date}</span>
                        </div>
                    </div>
                    <div className="order-card-actions">
                        <Link
                            to={`/user/customer-portal/${order.orderId}`}
                            className="btn view-details-btn"
                        >
                            View Details
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    ), []);

    return (
        <div className="portal-wrapper">
            {/* SEO Metadata using Helmet */}
            <Helmet>
                <title>Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders</title>
                <meta
                    name="description"
                    content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
                />
                <meta
                    name="keywords"
                    content="customer portal, manage orders, order history, update account, The Sealab, kitchen cabinets, closet cabinets, cabinet orders"
                />
                <meta
                    property="og:title"
                    content="Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders"
                />
                <meta
                    property="og:description"
                    content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
                />
                <meta property="og:url" content="https://www.thesealab.com/user/customer-portal"/>
                <meta property="og:type" content="website"/>
            </Helmet>

            <div className="portal-container">
                <h1 className="portal-title">Customer Portal</h1>

                {/* Search bar for filtering orders */}
                {orders?.length > 1 && (
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search by Order ID, Status, Project Name, or Purchase Order"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-input"
                            aria-label="Search orders"
                        />
                    </div>
                )}

                {/* Display loading spinner while fetching data */}
                {isLoading ? (
                    <div className="loading-state">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* Render filtered orders */}
                        {filteredOrders?.length > 0 ? (
                            <div className="row g-4">
                                {filteredOrders.map(renderOrderCard)}
                            </div>
                        ) : filteredOrders !== null && orders?.length > 1 ? (
                            <div className="no-results">
                                <h2>No orders match your search criteria</h2>
                                <p>Try adjusting your search terms</p>
                            </div>
                        ) : filteredOrders !== null && orders?.length <= 0 && (
                            <div className="empty-state">
                                <h2>Your Customer Portal is Empty</h2>
                                <p>Please check back after you complete your first order.</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CustomerPortal;

import {useSelector} from "react-redux";

const UseOrderProcessing = () => {
    const cartItems = useSelector((state) => state.cart.cart);
    const userInfo = useSelector((state) => state.user.user);
    const specialInstructions = useSelector((state) => state.cart.specialInstructions);

    const orderProcessing = (orderInfo) => {
        const orderList = {
            address1: userInfo?.address1 === '' ? null : userInfo?.address1,
            address2: userInfo?.address2,
            apartment: userInfo?.apartment,
            city: userInfo?.city,
            state: userInfo?.state,
            zipcode: userInfo?.zipcode,
            email: userInfo.email,
            orderId: orderInfo?.orderId,
            projectAddress1: orderInfo?.projectAddress1,
            projectAddress2: orderInfo?.projectAddress2,
            projectCity: orderInfo?.projectCity,
            projectState: orderInfo?.projectState,
            projectZipcode: orderInfo?.projectZipcode,
            projectName: orderInfo?.projectName,
            purchaseOrder: orderInfo?.purchaseOrder,
            includeDrawerboxes: orderInfo?.includeDrawerboxes,
            includeHardware: orderInfo?.includeHardware,
            includeAssembly: orderInfo?.includeAssembly,
            includeFinishing: orderInfo?.includeFinishing,
            processingTxId: '',
            articles: [],
            specialInstructions: specialInstructions,
        };

        cartItems.forEach((item) => {
            if (item?.excludeFronts === 'true')
                item = {...item, frontMaterial: "Fronts By Others"}

            // switch (item?.caseEdge) {
            //     case '0':
            //         item = {...item, caseEdge: 'No Edgebanding'}
            //         break;
            //     case '1':
            //         item = {...item, caseEdge: '0.5 millimeter Edgebanding'}
            //         break;
            //     default:
            //         item = {...item, caseEdge: item?.caseEdge}
            //
            // }
            //
            // switch (item?.frontEdge) {
            //     case '0':
            //         item = {...item, frontEdge: 'No Edgebanding'}
            //         break;
            //     case '1':
            //         item = {...item, frontEdge: '0.5 millimeter Edgebanding'}
            //         break;
            //     default:
            //         item = {...item, frontEdge: item?.frontEdge}
            //
            // }

            orderList.articles.push(item);
        });

        return orderList;
    };

    return {orderProcessing};
};

export default UseOrderProcessing;

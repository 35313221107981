import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Modal} from "react-bootstrap";
import {Building, Mail, Pencil, Plus, Search, Trash2} from 'lucide-react';
import {
    createVendor,
    deleteVendor,
    fetchVendors,
    searchVendorByEmail,
    searchVendorsByName,
    updateVendor,
} from "../../../features/vendors/vendorSlice";

const VendorManagementComponent = () => {
    const dispatch = useDispatch();
    const {vendors, status, error} = useSelector((state) => state.vendors);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [vendorForm, setVendorForm] = useState({
        id: null,
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        phone: "",
        email: "",
    });

    useEffect(() => {
        dispatch(fetchVendors());
    }, [dispatch]);

    const handleFormChange = (e) => {
        setVendorForm({...vendorForm, [e.target.name]: e.target.value});
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const action = vendorForm.id
            ? updateVendor({id: vendorForm.id, vendor: vendorForm})
            : createVendor(vendorForm);

        dispatch(action)
            .unwrap()
            .then(() => {
                setShowModal(false);
                resetForm();
            });
    };

    const handleEdit = (vendor) => {
        setVendorForm(vendor);
        setShowModal(true);
    };

    const handleDelete = (vendor) => {
        setSelectedVendor(vendor);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        if (selectedVendor) {
            dispatch(deleteVendor(selectedVendor.id)).then(() => {
                setShowDeleteModal(false);
                setSelectedVendor(null);
            });
        }
    };

    const handleVendorClick = (vendorId) => {
        window.open(`/internal/vendors/${vendorId}`, '_blank');
    };

    const resetForm = () => {
        setVendorForm({
            id: null,
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            phone: "",
            email: "",
        });
    };

    return (
        <div className="vm-container container-lg py-4 min-vh-100">
            {/* Header Section */}
            <div className="vm-header d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h1 className="vm-title m-0">Vendor Directory</h1>
                    <p className="vm-subtitle text-muted mb-0">Manage and track your vendor partnerships</p>
                </div>
                <Button
                    variant="dark"
                    onClick={() => {
                        resetForm();
                        setShowModal(true);
                    }}
                    className="vm-add-btn"
                >
                    <Plus size={18} className="me-2"/>
                    New Vendor
                </Button>
            </div>

            {/* Search Controls */}
            <div className="vm-search bg-light p-4 rounded-3 mb-4 shadow-sm">
                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="input-group">
              <span className="input-group-text bg-white border-end-0">
                <Building size={18} className="text-muted"/>
              </span>
                            <input
                                type="text"
                                className="vm-search-input form-control border-start-0"
                                placeholder="Search by vendor name"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                            <Button
                                variant="dark"
                                onClick={() => dispatch(searchVendorsByName(searchName))}
                            >
                                <Search size={18}/>
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group">
              <span className="input-group-text bg-white border-end-0">
                <Mail size={18} className="text-muted"/>
              </span>
                            <input
                                type="email"
                                className="vm-search-input form-control border-start-0"
                                placeholder="Search by vendor email"
                                value={searchEmail}
                                onChange={(e) => setSearchEmail(e.target.value)}
                            />
                            <Button
                                variant="dark"
                                onClick={() => dispatch(searchVendorByEmail(searchEmail))}
                            >
                                <Search size={18}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor List */}
            <div className="vm-list">
                {status === "loading" ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : status === "failed" ? (
                    <div className="alert alert-danger" role="alert">
                        Error: {error}
                    </div>
                ) : (
                    <div className="row g-4">
                        {vendors.map((vendor) => (
                            <div key={vendor.id} className="col-md-6 col-xl-4">
                                <div
                                    className="vm-card card h-100 shadow-sm cursor-pointer"
                                    onClick={() => handleVendorClick(vendor.id)}
                                >
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <h5 className="vm-card-title mb-0">{vendor.name}</h5>
                                            <Badge bg="dark" className="vm-status">
                                                Active
                                            </Badge>
                                        </div>
                                        <div className="vm-contact mb-3">
                                            <div className="text-muted small">{vendor.email}</div>
                                            <div className="text-muted small">{vendor.phone}</div>
                                        </div>
                                        <div className="vm-address mb-3">
                                            <div className="small">{vendor.address1}</div>
                                            {vendor.address2 && <div className="small">{vendor.address2}</div>}
                                            <div className="small">
                                                {vendor.city}, {vendor.state} {vendor.zipcode}
                                            </div>
                                        </div>
                                        <div className="vm-actions d-flex justify-content-end gap-2">
                                            <Button
                                                variant="outline-dark"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(vendor);
                                                }}
                                                className="vm-edit-btn"
                                            >
                                                <Pencil size={16}/>
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(vendor);
                                                }}
                                                className="vm-delete-btn"
                                            >
                                                <Trash2 size={16}/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Edit/Create Modal */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                className="vm-modal"
            >
                <Modal.Header closeButton className="vm-modal-header bg-light">
                    <Modal.Title className="vm-modal-title">
                        {vendorForm.id ? "Edit Vendor" : "New Vendor"}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFormSubmit}>
                    <Modal.Body className="vm-modal-body">
                        <div className="row g-3">
                            <div className="col-12">
                                <label className="form-label">Vendor Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={vendorForm.name}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={vendorForm.email}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Phone</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    className="form-control"
                                    value={vendorForm.phone}
                                    onChange={handleFormChange}
                                />
                            </div>
                            <div className="col-12">
                                <label className="form-label">Address Line 1</label>
                                <input
                                    type="text"
                                    name="address1"
                                    className="form-control"
                                    value={vendorForm.address1}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label className="form-label">Address Line 2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    className="form-control"
                                    value={vendorForm.address2}
                                    onChange={handleFormChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    value={vendorForm.city}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">State</label>
                                <input
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    value={vendorForm.state}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Zip Code</label>
                                <input
                                    type="text"
                                    name="zipcode"
                                    className="form-control"
                                    value={vendorForm.zipcode}
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="vm-modal-footer bg-light">
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="dark" type="submit">
                            {vendorForm.id ? "Save Changes" : "Create Vendor"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
                className="vm-delete-modal"
            >
                <Modal.Header closeButton className="vm-delete-modal-header">
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete vendor "{selectedVendor?.name}"? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Delete Vendor
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default VendorManagementComponent;
import axios from "axios";
import {addSpecialInstructions, emptyCart, updateCabinetsFromDrawingTool, updateItemsInCart} from './cartSlice';

const baseURL = process.env.REACT_APP_BASEURL;

// Action to fetch all saved orders
export const fetchSavedOrders = (email, token) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseURL}/getAllSavedOrders`, {email}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching saved orders:", error);
        throw error;
    }
};

// Action to save an order for later
export const saveOrderForLater = (order, token) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseURL}/saveOrderForLater`, order, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error saving order for later:", error);
        throw error;
    }
};

// Action to delete a saved order
export const deleteSavedOrder = (tempOrderId, token) => async (dispatch) => {
    try {
        await axios.get(`${baseURL}/user/customer-portal/${tempOrderId}/deleteSavedOrder`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    } catch (error) {
        console.error("Error deleting saved order:", error);
        throw error;
    }
};

// Action to submit the cart for processing
export const submitCart = (userLoggedIn, cartDetails, token) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseURL}/submitting-cart`, {userLoggedIn, cartDetails}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error submitting cart:", error);
        throw error;
    }
};

// Action to update items in the cart
export const updateCartItems = (updatedItems) => (dispatch) => {
    try {
        dispatch(updateItemsInCart(updatedItems));
    } catch (error) {
        console.error("Error updating cart items:", error);
    }
};

// Action to apply global settings to selected items
export const applyGlobalSettings = (cartItems, selectedItems, globalSettings) => (dispatch) => {
    console.log(cartItems)
    try {
        const updatedItems = cartItems.map(item => {
            if (selectedItems.includes(item.itemId)) {
                return {
                    ...item,
                    caseMaterial: item?.caseEdge !== '0' ? globalSettings.caseMaterial : '',
                    frontMaterial: item?.frontEdge !== '0' ? globalSettings.frontMaterial : '',
                    backPanelMaterial: globalSettings.backPanelMaterial,
                    caseEdge: item?.caseEdge !== '0' ? globalSettings.caseEdge : '0',
                    frontEdge: item?.frontEdge !== '0' ? globalSettings.frontEdge : '0',
                    backPanel: globalSettings?.backPanel,
                    jointMethod: globalSettings?.jointMethod,
                    hingePlate: globalSettings?.hingePlate,
                    drawerType: globalSettings?.drawerType,
                    globalSettingsApplied: true,
                };
            }
            // console.log(item)
            return item;
        });
        console.log(updatedItems)
        dispatch(updateItemsInCart(updatedItems));
    } catch (error) {
        console.error("Error applying global settings:", error);
    }
};

// Action to add special instructions to the order
export const addOrderSpecialInstructions = (instructions) => (dispatch) => {
    try {
        dispatch(addSpecialInstructions(instructions));
    } catch (error) {
        console.error("Error adding special instructions:", error);
    }
};

// Action to handle cabinets with coordinates
export const updateCabinetCoordinates = (cabinets) => (dispatch) => {
    try {
        dispatch(updateCabinetsFromDrawingTool(cabinets));
    } catch (error) {
        console.error("Error updating cabinet coordinates:", error);
    }
};

// Action to clear the cart
export const clearCart = () => (dispatch) => {
    try {
        dispatch(emptyCart());
    } catch (error) {
        console.error("Error clearing cart:", error);
    }
};

// import React from 'react';
// import {Form, Table} from 'react-bootstrap';
//
// const DoorsTable = ({doors, handleDoorFieldChange}) => {
//     return (
//         <>
//             <Table responsive className="om-table">
//                 <thead>
//                 <tr>
//                     <th>Type</th>
//                     <th>Width</th>
//                     <th>Height</th>
//                     <th>Slab?</th>
//                     <th>Rails</th>
//                     <th>Stiles</th>
//                     <th>Notes</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {doors.map((door, index) => (
//                     <tr key={index}>
//                         <td>Door</td>
//                         <td>{door.width}</td>
//                         <td>{door.height}</td>
//                         <td>
//                             <Form.Group>
//                                 <Form.Select
//                                     // value={door.isSlab || (door.constructionType === 'Slab Door' ? 'Yes' : 'No')}
//                                     value={'Yes'}
//                                     onChange={(e) => handleDoorFieldChange(index, 'isSlab', e.target.value)}
//                                 >
//                                     <option value="Yes">Yes</option>
//                                     <option value="No">No</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group>
//                                 <Form.Select
//                                     value={door.rails || 'Slab'}
//                                     onChange={(e) => handleDoorFieldChange(index, 'rails', e.target.value)}
//                                 >
//                                     <option value="Slab">Slab</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group>
//                                 <Form.Select
//                                     value={door.stiles || 'Slab'}
//                                     onChange={(e) => handleDoorFieldChange(index, 'stiles', e.target.value)}
//                                 >
//                                     <option value="Slab">Slab</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Control
//                                 type="text"
//                                 value={door.notes || ''}
//                                 onChange={(e) => handleDoorFieldChange(index, 'notes', e.target.value)}
//                             />
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </Table>
//         </>
//     );
// };
//
// export default DoorsTable;


import React from 'react';
import {Button, Form, Table} from 'react-bootstrap';

const DoorsTable = ({doors, handleDoorFieldChange}) => {
    const handleExportCSV = () => {
        // Define column headers for CSV
        const headers = [
            'Type',
            'Width',
            'Height',
            'Slab',
            'Rails',
            'Stiles',
            'Notes'
        ];

        // Transform data for CSV
        const csvData = doors.map(door => [
            door.name1,
            door.width,
            door.height,
            'Yes', // Since it's hardcoded in the table
            door.rails || 'Slab',
            door.stiles || 'Slab',
            door.notes || ''
        ]);

        // Create CSV content
        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.map(cell => {
                // Handle cells that might contain commas or special characters
                if (typeof cell === 'string' && (cell.includes(',') || cell.includes('\n'))) {
                    return `"${cell.replace(/"/g, '""')}"`;
                }
                return cell;
            }).join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `doors_table_${new Date().toISOString().split('T')[0]}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div>
            <div className="mb-3">
                <Button
                    variant="outline-primary"
                    onClick={handleExportCSV}
                    className="mb-2"
                >
                    Export to CSV
                </Button>
            </div>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>Type</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Slab?</th>
                    <th>Rails</th>
                    <th>Stiles</th>
                    <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                {doors.map((door, index) => (
                    <tr key={index}>
                        <td>{door.name1}</td>
                        <td>{door.width}</td>
                        <td>{door.height}</td>
                        <td>
                            <Form.Group>
                                <Form.Select
                                    value={'Yes'}
                                    onChange={(e) => handleDoorFieldChange(index, 'isSlab', e.target.value)}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Select
                                    value={door.rails || 'Slab'}
                                    onChange={(e) => handleDoorFieldChange(index, 'rails', e.target.value)}
                                >
                                    <option value="Slab">Slab</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Select
                                    value={door.stiles || 'Slab'}
                                    onChange={(e) => handleDoorFieldChange(index, 'stiles', e.target.value)}
                                >
                                    <option value="Slab">Slab</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Control
                                type="text"
                                value={door.notes || ''}
                                onChange={(e) => handleDoorFieldChange(index, 'notes', e.target.value)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DoorsTable;
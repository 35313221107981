import React from 'react';
import {Button, Form, Table} from 'react-bootstrap';
import * as XLSX from 'xlsx';

const DrawersTable = ({drawers, order, handleDrawerFieldChange}) => {
    const drawerOptionsList = [
        'A - Cutlery Insert',
        'B - Utensil Insert',
        'C - Cutlery/Utensil',
        'D - Knife Insert',
        'E - Spice Inser',
        'F - Custom Dividers',
        'G - Double Drawer',
        'H1 - File Drawer - Front to Back',
        'H2 - File Drawer - Side to Side',
        'J - Shaped Sides',
        'L - U-Shaped Drawer',
        'M - Corner Drawer',
        'N - Trash Pull-Out, Drawer',
        'O - Trash Pull-Out Drawer',
        'P - Bread Drawer'
    ];

    const yesNoOptions = [
        {value: 'Yes', label: 'Yes'},
        {value: 'No', label: 'No'}
    ];

    const handleExportExcel = () => {
        const headers = [
            'Quantity',
            'Width',
            'Height',
            'Depth',
            'Bottom',
            'Notch & Bore',
            'Finish',
            'Drawer Options',
            'Finish Front',
            'Scoop',
            'Include Glides',
            
        ];

        const data = drawers.map(drawer => [
            drawer.quantity,
            drawer.width,
            drawer.height,
            drawer.depth,
            drawer.bottom || '1/4"',
            drawer.notchAndBore || 'Yes',
            drawer.finish || 'Yes',
            drawer.drawerOptions || '',
            drawer.finishFront || 'Yes',
            drawer.scoop || 'No',
            drawer.includeGlides || 'Yes',

        ]);

        // Create worksheet
        const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Set column widths
        const colWidths = headers.map(header => ({wch: Math.max(header.length, 12)}));
        ws['!cols'] = colWidths;

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Drawers');

        // Generate Excel file
        XLSX.writeFile(wb, `drawers_table_${new Date().toISOString().split('T')[0]}.xlsx`);
    };

    return (
        <div>
            <div className="mb-3">
                <Button
                    variant="outline-primary"
                    onClick={handleExportExcel}
                    className="mb-2"
                >
                    Export to Excel
                </Button>
            </div>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>Qty</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Depth</th>
                    <th>Bottom</th>
                    <th>Notch &amp; Bore</th>
                    <th>Finish</th>
                    <th>Drawer Options</th>
                    <th>Finish Front</th>
                    <th>Scoop</th>
                    <th>Include Glides</th>
                    <th>Gross</th>
                    <th>Net Cost</th>
                    <th>Net Profit</th>
                </tr>
                </thead>
                <tbody>
                {drawers.map((drawer, index) => (
                    <tr key={index}>
                        <td>{drawer.quantity}</td>
                        <td>{drawer.width}</td>
                        <td>{drawer.height}</td>
                        <td>{drawer.depth}</td>
                        <td>{drawer.bottom || '1/4"'}</td>
                        <td>
                            <Form.Select
                                value={drawer.notchAndBore || 'Yes'}
                                onChange={(e) => handleDrawerFieldChange(index, 'notchAndBore', e.target.value)}
                            >
                                {yesNoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                        <td>
                            <Form.Select
                                value={drawer.finish || 'Yes'}
                                onChange={(e) => handleDrawerFieldChange(index, 'finish', e.target.value)}
                            >
                                {yesNoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                        <td>
                            <Form.Select
                                value={drawer.drawerOptions || ''}
                                onChange={(e) => {
                                    const selectedText = e.target.value;
                                    handleDrawerFieldChange(index, 'drawerOptions', selectedText);
                                }}
                            >
                                <option value="">Select</option>
                                {drawerOptionsList.map((option, idx) => {
                                    const value = option.split('-')[0].trim();
                                    return (
                                        <option key={idx} value={value}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </td>
                        <td>
                            <Form.Select
                                value={drawer.finishFront || 'Yes'}
                                onChange={(e) => handleDrawerFieldChange(index, 'finishFront', e.target.value)}
                            >
                                {yesNoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                        <td>
                            <Form.Select
                                value={drawer.scoop || 'No'}
                                onChange={(e) => handleDrawerFieldChange(index, 'scoop', e.target.value)}
                            >
                                {yesNoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                        <td>
                            <Form.Select
                                value={drawer.includeGlides || 'Yes'}
                                onChange={(e) => handleDrawerFieldChange(index, 'includeGlides', e.target.value)}
                            >
                                {yesNoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                        <td>{`$${drawer.totalPrice}` || ''}</td>
                        <td>{`$${drawer.netCost.toFixed(2)}` || ''}</td>
                        <td>{`$${drawer.netProfit.toFixed(2)}` || ''}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DrawersTable;
import {createAsyncThunk, createSlice, nanoid} from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

// Define the fetch cabinets thunk
// export const fetchCabinets = createAsyncThunk(
//     'showroom/fetchCabinets',
//     async (_, {rejectWithValue}) => {
//         try {
//             const response = await axios.get(`${baseURL}/getAllCabinets`);
//             return response.data.map((cabinet) => ({
//                 ...cabinet,
//                 id: nanoid(),
//             }));
//         } catch (error) {
//             console.error('Error fetching cabinets:', error);
//             return rejectWithValue('Failed to fetch cabinets');
//         }
//     },
//     {
//         condition: (_, {getState}) => {
//             const {showroom} = getState();
//             // Only fetch if we don't have cabinets or there was an error
//             if (showroom.loading) return false;
//             if (showroom.cabinets.length > 0 && !showroom.error) return false;
//             return true;
//         }
//     }
// );


export const fetchCabinets = createAsyncThunk(
    'showroom/fetchCabinets',
    async (email, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${baseURL}/getAllCabinets`, {
                params: email ? {email} : {},
                // headers: {'Cache-Control': 'no-cache'} // Prevents cached responses
            });

            return response.data.map((cabinet) => ({
                ...cabinet,
                id: nanoid(),
            }));
        } catch (error) {
            console.error('Error fetching cabinets:', error);
            return rejectWithValue('Failed to fetch cabinets');
        }
    }
);


const initialState = {
    cabinets: [],
    loading: false,
    error: null,
    lastFetched: null
};

const showroomSlice = createSlice({
    name: 'showroom',
    initialState,
    reducers: {
        resetShowroomState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCabinets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCabinets.fulfilled, (state, action) => {
                state.loading = false;
                state.cabinets = action.payload;
                state.lastFetched = Date.now();
            })
            .addCase(fetchCabinets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const {resetShowroomState} = showroomSlice.actions;
export default showroomSlice.reducer;
import React, {useEffect, useState} from "react";
import axios from "axios";
import ArticleCreation from "./ArticleCreation";
import AssignArticles from "./AssignArticles";
import "./ArticleManagementStyles.css";

const baseURL = process.env.REACT_APP_BASEURL;

const ArticleManagementComponent = () => {
    const [articles, setArticles] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${baseURL}/api/internal/articles/user-specific`
            );
            setArticles(response.data.userSpecificArticles);
            setUsers(response.data.users);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid py-4" id="amc-container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1 className="text-center mb-4" id="amc-main-title">
                        Article Management Portal
                    </h1>
                    {/* Article Creation appears above the assignment section */}
                    <ArticleCreation refreshData={fetchData}/>
                    {loading ? (
                        <div className="text-center">Loading...</div>
                    ) : (
                        <AssignArticles
                            articles={articles}
                            users={users}
                            refreshData={fetchData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ArticleManagementComponent;

// import React, {useState} from 'react';
// import axios from 'axios';
// import {Alert, Button, Card, Col, Container, Form, Row} from 'react-bootstrap';
// import Select from 'react-select';
// import './ArticleCreationStyles.css';
// import memoizedCategories from "../../ShowRoomComponent/memoizedCategories";
//
// // Extract tags function
// const extractTags = (categories) => {
//     const allTags = new Set();
//
//     const traverse = (item) => {
//         if (item.tags) {
//             item.tags.forEach(tag => allTags.add(tag));
//         }
//         if (item.subcategories) {
//             item.subcategories.forEach(traverse);
//         }
//     };
//
//     categories.forEach(traverse);
//     return Array.from(allTags).sort();
// };
//
// const allTags = extractTags(memoizedCategories);
// const tagOptions = allTags.map(tag => ({
//     value: tag,
//     label: tag.replace(/_/g, ' ')
// }));
//
// const baseURL = process.env.REACT_APP_BASEURL;
//
// const ArticleCreationComponent = () => {
//     const [formData, setFormData] = useState({
//         serialNumber: '',
//         gapControl: '0',
//         doors: '0',
//         drawers: '0',
//         jointControl: '0',
//         heightRange: '',
//         widthRange: '',
//         depthRange: '',
//         caseEdge: '0',
//         frontEdge: '0',
//         filterTags: [],
//         topDrwrHeight: '0',
//         description: '',
//         isCreated: '0',
//         adjShelves: '0',
//         matBack: '0',
//         userSpecific: '0',
//         catalogNo: '',
//         users: []
//     });
//
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [imagePreview, setImagePreview] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [errorMsg, setErrorMsg] = useState('');
//     const [successMsg, setSuccessMsg] = useState('');
//
//     const handleInputChange = (e) => {
//         const {name, value} = e.target;
//         setFormData((prev) => ({...prev, [name]: value}));
//     };
//
//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             if (file.type !== 'image/webp') {
//                 setErrorMsg('Please upload only WebP format images.');
//                 e.target.value = null;
//                 return;
//             }
//             setSelectedImage(file);
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setImagePreview(reader.result);
//             };
//             reader.readAsDataURL(file);
//         }
//     };
//
//     const handleTagsChange = (selectedOptions) => {
//         const selectedTags = selectedOptions ? selectedOptions.map(option => option.value) : [];
//         setFormData(prev => ({...prev, filterTags: selectedTags}));
//     };
//
//     const handleBinaryChange = (e) => {
//         const {name, value} = e.target;
//         if (value === '0' || value === '1') {
//             setFormData((prev) => ({...prev, [name]: value}));
//         }
//     };
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setErrorMsg('');
//         setSuccessMsg('');
//
//         if (!formData.serialNumber) {
//             setErrorMsg('Please provide a serial number.');
//             setLoading(false);
//             return;
//         }
//
//         try {
//             const submissionData = {
//                 ...formData,
//                 filterTags: formData.filterTags.join(',')
//             };
//
//             const formDataToSend = new FormData();
//             formDataToSend.append('articleData', JSON.stringify(submissionData));
//
//             if (selectedImage) {
//                 formDataToSend.append('image', selectedImage);
//             }
//
//             const response = await axios.post(
//                 `${baseURL}/api/internal/articles/create`,
//                 formDataToSend,
//                 {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 }
//             );
//
//             setSuccessMsg(response.data || 'Article created successfully!');
//             setFormData({
//                 serialNumber: '',
//                 gapControl: '0',
//                 doors: '0',
//                 drawers: '0',
//                 jointControl: '0',
//                 heightRange: '',
//                 widthRange: '',
//                 depthRange: '',
//                 caseEdge: '0',
//                 frontEdge: '0',
//                 filterTags: [],
//                 topDrwrHeight: '0',
//                 description: '',
//                 isCreated: '0',
//                 adjShelves: '0',
//                 matBack: '0',
//                 userSpecific: '0',
//                 catalogNo: '',
//                 users: []
//             });
//             setSelectedImage(null);
//             setImagePreview(null);
//         } catch (error) {
//             console.error('Error creating article:', error);
//             setErrorMsg('Failed to create article. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     const renderBinaryField = (id, label) => (
//         <Form.Group controlId={id} className="mb-3">
//             <Form.Label className="article-creation-label">{label}</Form.Label>
//             <Form.Select
//                 name={id}
//                 value={formData[id]}
//                 onChange={handleBinaryChange}
//                 className="article-creation-select"
//             >
//                 <option value="0">0</option>
//                 <option value="1">1</option>
//             </Form.Select>
//         </Form.Group>
//     );
//
//     const renderTextField = (id, label, placeholder = "") => (
//         <Form.Group controlId={id} className="mb-3">
//             <Form.Label className="article-creation-label">{label}</Form.Label>
//             <Form.Control
//                 type="text"
//                 placeholder={placeholder}
//                 name={id}
//                 value={formData[id]}
//                 onChange={handleInputChange}
//                 className="article-creation-input"
//             />
//         </Form.Group>
//     );
//
//     return (
//         <Container fluid className="article-creation-container">
//             <Row className="justify-content-center">
//                 <Col xl={10} xxl={8}>
//                     <Card className="article-creation-card">
//                         <Card.Header className="article-creation-header">
//                             <span className="article-creation-header-label">Article Creation</span>
//                         </Card.Header>
//                         <Card.Body className="article-creation-body">
//                             {errorMsg && (
//                                 <Alert variant="danger" onClose={() => setErrorMsg('')} dismissible
//                                        className="article-creation-alert">
//                                     {errorMsg}
//                                 </Alert>
//                             )}
//                             {successMsg && (
//                                 <Alert variant="success" onClose={() => setSuccessMsg('')} dismissible
//                                        className="article-creation-alert">
//                                     {successMsg}
//                                 </Alert>
//                             )}
//                             <Form onSubmit={handleSubmit}>
//                                 <Row className="mb-4">
//                                     <Col lg={6}>
//                                         {renderTextField('serialNumber', 'Serial Number', 'Enter Serial Number')}
//                                     </Col>
//                                     <Col lg={6}>
//                                         {renderTextField('catalogNo', 'Catalog No.', 'Enter Catalog No.')}
//                                     </Col>
//                                 </Row>
//
//                                 <Row className="mb-4">
//                                     <Col lg={12}>
//                                         <Form.Group controlId="articleImage" className="mb-3">
//                                             <Form.Label className="article-creation-label">Article Image (.webp
//                                                 only)</Form.Label>
//                                             <Form.Control
//                                                 type="file"
//                                                 accept="image/webp"
//                                                 onChange={handleImageChange}
//                                                 className="article-creation-input"
//                                             />
//                                             {imagePreview && (
//                                                 <div className="mt-3 text-center">
//                                                     <img
//                                                         src={imagePreview}
//                                                         alt="Preview"
//                                                         style={{
//                                                             maxWidth: '200px',
//                                                             maxHeight: '200px',
//                                                             objectFit: 'contain'
//                                                         }}
//                                                     />
//                                                 </div>
//                                             )}
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//
//                                 <Row className="mb-4">
//                                     <Col lg={12}>
//                                         <Form.Group controlId="filterTags">
//                                             <Form.Label className="article-creation-label">Filter Tags</Form.Label>
//                                             <Select
//                                                 isMulti
//                                                 name="filterTags"
//                                                 options={tagOptions}
//                                                 className="article-creation-select-container"
//                                                 classNamePrefix="article-creation-select"
//                                                 value={tagOptions.filter(option =>
//                                                     formData.filterTags.includes(option.value)
//                                                 )}
//                                                 onChange={handleTagsChange}
//                                                 placeholder="Select tags..."
//                                             />
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//
//                                 <Row className="mb-4">
//                                     <Col lg={12}>
//                                         <Form.Group controlId="description">
//                                             <Form.Label className="article-creation-label">Description</Form.Label>
//                                             <Form.Control
//                                                 as="textarea"
//                                                 rows={3}
//                                                 placeholder="Article description"
//                                                 name="description"
//                                                 value={formData.description}
//                                                 onChange={handleInputChange}
//                                                 className="article-creation-textarea"
//                                             />
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//
//                                 <div className="article-creation-divider">
//                                     <span className="article-creation-divider-text">Dimensions</span>
//                                 </div>
//
//                                 <Row className="mb-4">
//                                     <Col sm={6} lg={4}>
//                                         {renderTextField('heightRange', 'Height Range')}
//                                     </Col>
//                                     <Col sm={6} lg={4}>
//                                         {renderTextField('widthRange', 'Width Range')}
//                                     </Col>
//                                     <Col sm={6} lg={4}>
//                                         {renderTextField('depthRange', 'Depth Range')}
//                                     </Col>
//                                 </Row>
//
//                                 <div className="article-creation-divider">
//                                     <span className="article-creation-divider-text">Features</span>
//                                 </div>
//
//                                 <Row className="g-4">
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('gapControl', 'Gap Control')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('doors', 'Doors')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('drawers', 'Drawers')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('jointControl', 'Joint Control')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('caseEdge', 'Case Edge')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('frontEdge', 'Front Edge')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('topDrwrHeight', 'Top Drawer Height')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('isCreated', 'Is Created')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('adjShelves', 'Adj Shelves')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('matBack', 'Material Back')}
//                                     </Col>
//                                     <Col sm={6} md={4} lg={3}>
//                                         {renderBinaryField('userSpecific', 'User Specific')}
//                                     </Col>
//                                 </Row>
//
//                                 <div className="article-creation-footer">
//                                     <Button
//                                         variant="dark"
//                                         type="submit"
//                                         disabled={loading}
//                                         className="article-creation-submit"
//                                     >
//                                         {loading ? 'Creating...' : 'Create Article'}
//                                     </Button>
//                                 </div>
//                             </Form>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };
//
// export default ArticleCreationComponent;


import React, {useState} from 'react';
import axios from 'axios';
import {Button, Card, Col, Container, Form, Row, Spinner, Toast} from 'react-bootstrap';
import Select from 'react-select';
import './ArticleCreationStyles.css';
import memoizedCategories from "../../ShowRoomComponent/memoizedCategories";

const extractTags = (categories) => {
    const allTags = new Set();

    const traverse = (item) => {
        if (item.tags) {
            item.tags.forEach(tag => allTags.add(tag));
        }
        if (item.subcategories) {
            item.subcategories.forEach(traverse);
        }
    };

    categories.forEach(traverse);
    return Array.from(allTags).sort();
};

const allTags = extractTags(memoizedCategories);
const tagOptions = allTags.map(tag => ({
    value: tag,
    label: tag.replace(/_/g, ' ')
}));

const baseURL = process.env.REACT_APP_BASEURL;

const ArticleCreationComponent = () => {
    const [formData, setFormData] = useState({
        serialNumber: '',
        gapControl: '0',
        doors: '0',
        drawers: '0',
        jointControl: '0',
        heightRange: '',
        widthRange: '',
        depthRange: '',
        caseEdge: '0',
        frontEdge: '0',
        filterTags: [],
        topDrwrHeight: '0',
        description: '',
        isCreated: '0',
        adjShelves: '0',
        matBack: '0',
        userSpecific: '0',
        catalogNo: '',
        users: []
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({show: false, message: '', type: ''});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type !== 'image/webp') {
                setNotification({
                    show: true,
                    message: 'Please upload only WebP format images.',
                    type: 'danger'
                });
                e.target.value = null;
                return;
            }
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTagsChange = (selectedOptions) => {
        const selectedTags = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prev => ({...prev, filterTags: selectedTags}));
    };

    const handleBinaryChange = (e) => {
        const {name, value} = e.target;
        if (value === '0' || value === '1') {
            setFormData((prev) => ({...prev, [name]: value}));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Data validation for required fields
        if (!formData.serialNumber.trim()) {
            setNotification({
                show: true,
                message: 'Serial Number cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }
        if (!formData.catalogNo.trim()) {
            setNotification({
                show: true,
                message: 'Catalog Number cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }
        if (!selectedImage) {
            setNotification({
                show: true,
                message: 'Article Image cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }
        if (!formData.widthRange.trim()) {
            setNotification({
                show: true,
                message: 'Width Range cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }
        if (!formData.heightRange.trim()) {
            setNotification({
                show: true,
                message: 'Height Range cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }
        if (!formData.depthRange.trim()) {
            setNotification({
                show: true,
                message: 'Depth Range cannot be empty.',
                type: 'danger'
            });
            setLoading(false);
            return;
        }

        try {
            const submissionData = {
                ...formData,
                filterTags: formData.filterTags.join(',')
            };

            const formDataToSend = new FormData();
            formDataToSend.append('articleData', JSON.stringify(submissionData));

            if (selectedImage) {
                formDataToSend.append('image', selectedImage);
            }

            const response = await axios.post(
                `${baseURL}/api/internal/articles/create`,
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );


            setNotification({
                show: true,
                message: 'Article created successfully!',
                type: 'success'
            });

            // Reload page after successful creation
            setTimeout(() => {
                window.location.reload();
            }, 2000);

        } catch (error) {
            console.error('Error creating article:', error);
            const message = error.response.data
            console.log(message)
            if (message === "Serial number exists")
                setNotification({
                    show: true,
                    // message: 'Failed to create article. Please try again.',
                    message: 'Serial number already exists. Choose a different serial number',
                    type: 'danger'
                });
            else
                setNotification({
                    show: true,
                    message: 'Failed to create article. Please try again.',
                    type: 'danger'
                })
        } finally {
            setLoading(false);
        }
    };

    const renderBinaryField = (id, label) => (
        <Form.Group controlId={id} className="mb-3">
            <Form.Label className="article-creation-field-label">{label}</Form.Label>
            <Form.Select
                name={id}
                value={formData[id]}
                onChange={handleBinaryChange}
                className="article-creation-binary-select"
            >
                <option value="0">No</option>
                <option value="1">Yes</option>
            </Form.Select>
        </Form.Group>
    );

    const renderTextField = (id, label, placeholder = "") => (
        <Form.Group controlId={id} className="mb-3">
            <Form.Label className="article-creation-field-label">{label}</Form.Label>
            <Form.Control
                type="text"
                placeholder={placeholder}
                name={id}
                value={formData[id]}
                onChange={handleInputChange}
                className="article-creation-text-input"
            />
        </Form.Group>
    );

    return (
        <Container fluid className="article-creation-container bg-light">
            <Row className="justify-content-center">
                <Col xl={10} xxl={8}>
                    <div className="article-creation-toast-container">
                        <Toast
                            show={notification.show}
                            onClose={() => setNotification({...notification, show: false})}
                            className={`article-creation-toast bg-${notification.type}`}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body className="text-white">
                                {notification.message}
                            </Toast.Body>
                        </Toast>
                    </div>

                    <Card className="article-creation-card border-0 shadow-sm">
                        <Card.Header className="article-creation-header bg-dark">
                            <h4 className="article-creation-title mb-0 text-white">Create New Article</h4>
                        </Card.Header>

                        <Card.Body className="article-creation-body">
                            <Form onSubmit={handleSubmit} className="article-creation-form">
                                <div className="article-creation-section">
                                    <h5 className="article-creation-section-title">Basic Information</h5>
                                    <Row className="g-3">
                                        <Col md={6}>
                                            {renderTextField('serialNumber', 'Serial Number', 'Enter Serial Number')}
                                        </Col>
                                        <Col md={6}>
                                            {renderTextField('catalogNo', 'Catalog Number', 'Enter Catalog Number')}
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col lg={12}>
                                            <Form.Group controlId="articleImage">
                                                <Form.Label className="article-creation-field-label">
                                                    Article Image (.webp only)
                                                </Form.Label>
                                                <div className="article-creation-image-upload">
                                                    <Form.Control
                                                        type="file"
                                                        accept="image/webp"
                                                        onChange={handleImageChange}
                                                        className="article-creation-file-input"
                                                    />
                                                    {imagePreview && (
                                                        <div className="article-creation-preview mt-3">
                                                            <img
                                                                src={imagePreview}
                                                                alt="Preview"
                                                                className="article-creation-preview-image"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="article-creation-section">
                                    <h5 className="article-creation-section-title">Tags & Description</h5>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group controlId="filterTags" className="mb-4">
                                                <Form.Label className="article-creation-field-label">Filter
                                                    Tags</Form.Label>
                                                <Select
                                                    isMulti
                                                    name="filterTags"
                                                    options={tagOptions}
                                                    className="article-creation-select"
                                                    classNamePrefix="article-creation-select"
                                                    value={tagOptions.filter(option =>
                                                        formData.filterTags.includes(option.value)
                                                    )}
                                                    onChange={handleTagsChange}
                                                    placeholder="Select tags..."
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="description">
                                                <Form.Label
                                                    className="article-creation-field-label">Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    placeholder="Enter article description"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleInputChange}
                                                    className="article-creation-textarea"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="article-creation-section">
                                    <h5 className="article-creation-section-title">Dimensions</h5>
                                    <Row className="g-3">
                                        <Col md={4}>
                                            {renderTextField('heightRange', 'Height Range')}
                                        </Col>
                                        <Col md={4}>
                                            {renderTextField('widthRange', 'Width Range')}
                                        </Col>
                                        <Col md={4}>
                                            {renderTextField('depthRange', 'Depth Range')}
                                        </Col>
                                    </Row>
                                </div>

                                <div className="article-creation-section">
                                    <h5 className="article-creation-section-title">Features</h5>
                                    <Row className="g-3">
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('gapControl', 'Gap Control')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('doors', 'Doors')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('drawers', 'Drawers')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('jointControl', 'Joint Control')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('caseEdge', 'Case Edge')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('frontEdge', 'Front Edge')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('topDrwrHeight', 'Top Drawer Height')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('isCreated', 'Is Created')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('adjShelves', 'Adjustable Shelves')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('matBack', 'Material Back')}
                                        </Col>
                                        <Col sm={6} md={4} lg={3}>
                                            {renderBinaryField('userSpecific', 'User Specific')}
                                        </Col>
                                    </Row>
                                </div>

                                <div className="article-creation-footer mt-4">
                                    <Button
                                        variant="dark"
                                        type="submit"
                                        disabled={loading}
                                        className="article-creation-submit-btn"
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Creating...
                                            </>
                                        ) : (
                                            'Create Article'
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ArticleCreationComponent;

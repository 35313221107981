// import React, {Suspense, useEffect, useState} from 'react';
// import {Modal, Spinner} from 'react-bootstrap';
// import {Elements} from '@stripe/react-stripe-js';
// import axios from 'axios';
// import {useDispatch, useSelector} from 'react-redux';
// import {emptyCart, paymentSucceeded} from '../features/cart/cartSlice';
// import OrderService from '../api/OrderService';
// import {useNavigate} from 'react-router-dom';
// import SuccessModal from './SuccessModal';
// import {loadStripe} from "@stripe/stripe-js/pure";
// import PaymentForm from "./PaymentForm";
//
// const PaymentModal = ({show, onHide, order, price, orderId, processingFee, invoicePayment}) => {
//     const [clientSecret, setClientSecret] = useState(null);
//     const [stripePromise, setStripePromise] = useState(null);  // Moved to state to lazy-load Stripe
//     const [error, setError] = useState(null);
//     const [showSuccessModal, setShowSuccessModal] = useState(false);
//     const [successMessage, setSuccessMessage] = useState(null);
//     const [successOrderId, setSuccessOrderId] = useState(null);
//     const [showCouponField, setShowCouponField] = useState(true);
//     const [couponCode, setCouponCode] = useState('');
//     const [isCouponApplied, setIsCouponApplied] = useState(false);
//     const [priceState, setPriceState] = useState(Number(price));
//     const [isLoading, setIsLoading] = useState(false);
//
//     const token = useSelector((state) => state.user.accessToken);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const user = useSelector(state => state.user.user);
//     const baseURL = process.env.REACT_APP_BASEURL;
//
//     useEffect(() => {
//         if (show) {
//             createPaymentIntent();
//
//
//             // Lazy load Stripe when the modal is opened
//             const loadStripeAsync = async () => {
//                 // const stripeLib = loadStripe('pk_test_51PNcpVHPrftNOJaIGr4jPmF6IzYDzG64q4FHbOGaybQWg9Mn6Y6i20nXkmojJbUALh1mCFCGXKqyJpSPahogWwj500j0VNAFPt');
//                 const stripeLib = await loadStripe('pk_live_51PNcpVHPrftNOJaIOhl0tlU2xcpGczBG2EBxTqp89VIxzcAPYGthqG3CyxUVxdZukljFDMbnrt9FtmrXyiFydcdR00h6K6BoG7');
//                 setStripePromise(stripeLib);
//             };
//             loadStripeAsync();
//         }
//     }, [show]);
//
//     const createPaymentIntent = async () => {
//         try {
//             const {data: paymentIntentData} = await axios.post(
//                 `${baseURL}/api/stripe/create-payment-intent`,
//                 {
//                     amount: price * 100,
//                     currency: 'usd',
//                     name: `${user.firstName} ${user.lastName}`,
//                     email: user.email,
//                     payment_method_types: ['card', 'us_bank_account']
//                 },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     },
//                 }
//             );
//             setClientSecret(paymentIntentData.clientSecret);
//         } catch (err) {
//             console.error('Error creating payment intent:', err);
//             setError('Failed to initialize payment. Please try again.');
//         }
//     };
//
//     const handlePaymentSuccess = async (paymentIntent) => {
//         try {
//             setIsLoading(true);
//             if (order && !invoicePayment) {
//                 order.processingTxId = paymentIntent.id;
//                 order.status = paymentIntent.status;
//                 const orderResponse = await OrderService(order, token);
//                 setSuccessOrderId(orderResponse.data);
//                 if (orderResponse.status !== 201) {
//                     throw new Error('Error creating order: ' + orderResponse.data);
//                 }
//                 dispatch(emptyCart());
//                 if (paymentIntent.status === 'processing') {
//                     setSuccessMessage(
//                         <>
//                             Thank you for placing your order with The Sealab!<br/>
//                             Your Order Number is: <strong>{orderResponse.data}</strong>.<br/>
//                             Your payment is currently being processed and you will receive an email shortly when your
//                             payment has been confirmed.
//                         </>
//                     );
//                 } else if (paymentIntent.status === "succeeded") {
//                     setSuccessMessage(
//                         <>
//                             Thank you for placing your order with The Sealab!<br/>
//                             Your Order Number is: <strong>{orderResponse.data}</strong>.<br/>
//                             You will receive an email shortly when your order has been processed.
//                         </>
//                     );
//                 }
//
//                 // Add Google Ads Conversion Tracking
//                 if (typeof window.gtag !== 'undefined') {
//                     window.gtag('event', 'conversion', {
//                         'send_to': 'AW-11552954224/jnADCOT9-4IaEPCu8IQr',
//                         'value': price, // Use the actual price
//                         'currency': 'USD'
//                     });
//                 }
//
//                 setShowSuccessModal(true);
//             }
//
//             if (orderId && invoicePayment) {
//                 await axios.post(
//                     `${baseURL}/user/customer-portal/${orderId}/invoicePayment`,
//                     {
//                         "invoiceTxId": paymentIntent.id,
//                         "invoiceAmount": price,
//                         "salesTax": (price - (price / 1.08875)).toFixed(2)
//                     },
//                     {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                             'Content-Type': 'application/json',
//                         },
//                     }
//                 );
//                 if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
//                     setSuccessMessage(
//                         <>
//                             Thank you for completing your order with The Sealab!<br/>
//                             You will receive an email shortly when the status of your order updates!
//                         </>
//                     );
//                 }
//
//                 // Add Google Ads Conversion Tracking for invoice payment
//                 if (typeof window.gtag !== 'undefined') {
//                     window.gtag('event', 'conversion', {
//                         'send_to': 'AW-11552954224/jnADCOT9-4IaEPCu8IQr',
//                         'value': price, // Use the actual price
//                         'currency': 'USD'
//                     });
//                 }
//                 setShowSuccessModal(true);
//             }
//
//             setIsLoading(false);
//             onHide();
//             dispatch(paymentSucceeded(true));
//         } catch (err) {
//             console.error('Error processing payment:', err);
//             setError(err.message || 'An error occurred while processing the payment. Please try again.');
//             dispatch(paymentSucceeded(false));
//             setIsLoading(false);
//         }
//     };
//
//     const handlePaymentError = (errorMessage) => {
//         setError(errorMessage);
//         dispatch(paymentSucceeded(false));
//     };
//
//     const handleCloseModal = () => {
//         setShowSuccessModal(false);
//         if (!invoicePayment)
//             navigate('/');
//         if (invoicePayment)
//             window.location.reload();
//     };
//
//     return (
//         <>
//             <Modal show={show} onHide={onHide} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Payment Information</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {isLoading ? (
//                         <div className="text-center">
//                             <Spinner animation="border" role="status">
//                                 <span className="visually-hidden">Loading...</span>
//                             </Spinner>
//                             <p className="mt-2">Processing your payment...</p>
//                         </div>
//                     ) : (
//                         <>
//                             {error && <div className="alert alert-danger">{error}</div>}
//
//                             {clientSecret && stripePromise && (
//                                 <Elements stripe={stripePromise} options={{
//                                     clientSecret,
//                                     appearance: {
//                                         theme: 'stripe',
//                                     },
//                                     loader: 'auto',
//                                 }}>
//                                     <Suspense fallback={<div>Loading payment form...</div>}>
//                                         <PaymentForm
//                                             price={price}
//                                             onPaymentSuccess={handlePaymentSuccess}
//                                             onPaymentError={handlePaymentError}
//                                             clientSecret={clientSecret}
//                                         />
//                                     </Suspense>
//                                 </Elements>
//                             )}
//                         </>
//                     )}
//                 </Modal.Body>
//             </Modal>
//
//             <SuccessModal
//                 show={showSuccessModal}
//                 handleClose={handleCloseModal}
//                 title="Order Placed Successfully"
//                 message={successMessage}
//             />
//         </>
//     );
// };
//
// export default PaymentModal;


import React, {Suspense, useEffect, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {Elements} from '@stripe/react-stripe-js';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {emptyCart, paymentSucceeded} from '../features/cart/cartSlice';
import OrderService from '../api/OrderService';
import {useNavigate} from 'react-router-dom';
import SuccessModal from './SuccessModal';
import {loadStripe} from '@stripe/stripe-js/pure';
import PaymentForm from './PaymentForm';

const PaymentModal = ({show, onHide, order, price, orderId, processingFee, invoicePayment, showOptions}) => {
    const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [error, setError] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector((state) => state.user.accessToken);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;

    const fullAmount = Number(price);
    const depositAmount = parseFloat((fullAmount * 0.25).toFixed(2));
    const [selectedAmount, setSelectedAmount] = useState(fullAmount);

    useEffect(() => {
        if (show) {
            // When the modal opens, default to full payment.
            console.log('price: ', fullAmount)
            setSelectedAmount(fullAmount);
            createPaymentIntent(fullAmount);


            // Lazy-load Stripe.
            const loadStripeAsync = async () => {
                // Uncomment the following line for a test key if needed.
                // const stripeLib = loadStripe('pk_test_51PNcpVHPrftNOJaIGr4jPmF6IzYDzG64q4FHbOGaybQWg9Mn6Y6i20nXkmojJbUALh1mCFCGXKqyJpSPahogWwj500j0VNAFPt');
                const stripeLib = await loadStripe('pk_live_51PNcpVHPrftNOJaIOhl0tlU2xcpGczBG2EBxTqp89VIxzcAPYGthqG3CyxUVxdZukljFDMbnrt9FtmrXyiFydcdR00h6K6BoG7');
                setStripePromise(stripeLib);
            };
            loadStripeAsync();
        }
    }, [show, fullAmount]);

    const createPaymentIntent = async (amount) => {
        try {
            const {data: paymentIntentData} = await axios.post(
                `${baseURL}/api/stripe/create-payment-intent`,
                {
                    amount: Math.round(amount * 100), // amount in cents
                    currency: 'usd',
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    payment_method_types: ['card', 'us_bank_account'],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setClientSecret(paymentIntentData.clientSecret);
        } catch (err) {
            console.error('Error creating payment intent:', err);
            setError('Failed to initialize payment. Please try again.');
        }
    };

    const handleAmountChange = (newAmount) => {
        const numericAmount = parseFloat(newAmount).toFixed(2);
        setSelectedAmount(Number(numericAmount));
        createPaymentIntent(Number(numericAmount));
    };

    const handlePaymentSuccess = async ({paymentIntent, paymentOption}) => {
        try {
            setIsLoading(true);
            if (order && !invoicePayment) {
                order.processingTxId = paymentIntent.id;
                order.status = paymentIntent.status;
                const orderResponse = await OrderService(order, token);
                if (orderResponse.status !== 201) {
                    throw new Error('Error creating order: ' + orderResponse.data);
                }
                dispatch(emptyCart());
                if (paymentIntent.status === 'processing') {
                    setSuccessMessage(
                        <>
                            Thank you for placing your order with The Sealab!<br/>
                            Your Order Number is: <strong>{orderResponse.data}</strong>.<br/>
                            Your payment is currently being processed and you will receive an email shortly when your
                            payment has been confirmed.
                        </>
                    );
                } else if (paymentIntent.status === 'succeeded') {
                    setSuccessMessage(
                        <>
                            Thank you for placing your order with The Sealab!<br/>
                            Your Order Number is: <strong>{orderResponse.data}</strong>.<br/>
                            You will receive an email shortly when your order has been processed.
                        </>
                    );
                }
                if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'conversion', {
                        send_to: 'AW-11552954224/jnADCOT9-4IaEPCu8IQr',
                        value: selectedAmount,
                        currency: 'USD',
                    });
                }
                setShowSuccessModal(true);
            }

            if (orderId && invoicePayment) {
                // Map the payment option to the desired string.
                const formattedPaymentOption =
                    paymentOption === 'full'
                        ? 'fullPayment'
                        : paymentOption === 'deposit'
                            ? 'depositPayment'
                            : 'customPayment';

                await axios.post(
                    `${baseURL}/user/customer-portal/${orderId}/invoicePayment`,
                    {
                        invoiceTxId: paymentIntent.id,
                        invoiceAmount: selectedAmount,
                        salesTax: (selectedAmount - selectedAmount / 1.08875).toFixed(2),
                        paymentOption: formattedPaymentOption,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
                    setSuccessMessage(
                        <>
                            Thank you for completing your order with The Sealab!<br/>
                            You will receive an email shortly when the status of your order updates!
                        </>
                    );
                }
                if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'conversion', {
                        send_to: 'AW-11552954224/jnADCOT9-4IaEPCu8IQr',
                        value: selectedAmount,
                        currency: 'USD',
                    });
                }
                setShowSuccessModal(true);
            }
            setIsLoading(false);
            onHide();
            dispatch(paymentSucceeded(true));
        } catch (err) {
            console.error('Error processing payment:', err);
            setError(err.message || 'An error occurred while processing the payment. Please try again.');
            dispatch(paymentSucceeded(false));
            setIsLoading(false);
        }
    };

    const handlePaymentError = (errorMessage) => {
        setError(errorMessage);
        dispatch(paymentSucceeded(false));
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        if (!invoicePayment) {
            navigate('/');
        } else {
            window.location.reload();
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <p className="mt-2">Processing your payment...</p>
                        </div>
                    ) : (
                        <>
                            {error && <div className="alert alert-danger">{error}</div>}
                            {clientSecret && stripePromise && (
                                <Elements
                                    stripe={stripePromise}
                                    options={{
                                        clientSecret,
                                        appearance: {theme: 'stripe'},
                                        loader: 'auto',
                                    }}
                                >
                                    <Suspense fallback={<div>Loading payment form...</div>}>
                                        <PaymentForm
                                            fullAmount={fullAmount}
                                            depositAmount={depositAmount}
                                            selectedAmount={selectedAmount}
                                            onAmountChange={handleAmountChange}
                                            onPaymentSuccess={handlePaymentSuccess}
                                            onPaymentError={handlePaymentError}
                                            clientSecret={clientSecret}
                                            showOptions={showOptions}
                                        />
                                    </Suspense>
                                </Elements>
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>

            <SuccessModal
                show={showSuccessModal}
                handleClose={handleCloseModal}
                title="Order Placed Successfully"
                message={successMessage}
            />
        </>
    );
};

export default PaymentModal;

import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {
    BlindFrontsTable,
    DoorDimensionsTable,
    DrawerFrontsTable,
    DrawerTable,
    HardwareTable,
    MaterialTable,
    ProfileTable,
    SppTable
} from './tables';
import {fetchOrderSummary} from '../../features/orders/orderSlice';
import './OrderMatHardwareStyles.css';
import generatePDF from "./PDFGenerator";
import LoadingPage from "../Loading/LoadingPage";

const OrderMatHardwareComponent = () => {
    const {orderId} = useParams();
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.accessToken);
    const baseURL = process.env.REACT_APP_BASEURL;
    const isUserLoggedIn = UserLoggedIn();

    const {orderData, status, error} = useSelector(state => state.order);

    useEffect(() => {
        if (token && orderId) {
            dispatch(fetchOrderSummary({orderId, token, baseURL}));
        }
    }, [dispatch, orderId, token, baseURL]);

    const handleDownloadPDF = () => {
        generatePDF({
            ...orderData
        });
    };

    if (status === 'loading') {
        return <LoadingPage/>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    if (!orderData.materialSqft) {
        return null;
    }

    return (
        <div className="mat-hware-container min-vh-100">
            <h2 id="mat-hware-summary-title">Order Component Lists</h2>
            <div className="download-btn">
                <button className="btn btn-dark" onClick={handleDownloadPDF}>
                    Download PDF
                </button>
            </div>
            <div className="row">
                <MaterialTable materialSqft={orderData.materialSqft}/>
                <HardwareTable hardwareQuantity={orderData.hardwareQuantity}/>
                <ProfileTable prfData={orderData.prfData}/>
                <DrawerTable drawerData={orderData.drawerData}/>
                <DrawerFrontsTable drawerFronts={orderData.drawerFronts}/>
                <DoorDimensionsTable doorDimensions={orderData.doorDimensions}/>
                <BlindFrontsTable blindFronts={orderData.blindFronts}/>
                <SppTable sppData={orderData.sppData}/>
            </div>
        </div>
    );
};

export default OrderMatHardwareComponent;
// import React from "react";
//
// /**
//  * HardwareTable Component
//  *
//  * This component renders a table body (`<tbody>`) displaying a list of hardware items.
//  * It filters out any hardware items where `hardware` is "Placeholder".
//  * It uses `React.memo` to prevent unnecessary re-renders, ensuring better performance
//  * when the `hardware` prop does not change.
//  *
//  * @param {Object} props - Component properties.
//  * @param {Array<Object>} props.hardware - Array of hardware objects to display.
//  * Each hardware object should include:
//  *   - hardware: The name or type of the hardware.
//  *   - mfgNum: The manufacturing number of the hardware.
//  *   - quantity: The number of hardware items available.
//  */
// export const HardwareTable = React.memo(({hardware}) => (
//     <tbody>
//     {hardware
//         .filter(item => item.hardware !== "Placeholder") // Filter out "Placeholder" items
//         .map((item, index) => (
//             <tr key={index}>
//                 <td>{item.hardware}</td>
//                 <td>{item.mfgNum}</td>
//                 <td>{item.quantity}</td>
//             </tr>
//         ))}
//     </tbody>
// ));


import React from "react";

/**
 * HardwareTable Component
 *
 * This component renders a table body (`<tbody>`) displaying a list of hardware items.
 * It filters out any hardware items where `hardware` is "Placeholder".
 * It also combines duplicate hardware items, summing up their quantities.
 * It uses `React.memo` to prevent unnecessary re-renders, ensuring better performance
 * when the `hardware` prop does not change.
 *
 * @param {Object} props - Component properties.
 * @param {Array<Object>} props.hardware - Array of hardware objects to display.
 * Each hardware object should include:
 *   - hardware: The name or type of the hardware.
 *   - mfgNum: The manufacturing number of the hardware.
 *   - quantity: The number of hardware items available.
 */
export const HardwareTable = React.memo(({hardware}) => {
    // Aggregate hardware items by name, summing up the quantities
    const combinedHardware = hardware
        .filter(item => item.hardware !== "Placeholder") // Remove placeholders
        .reduce((acc, item) => {
            const key = item.hardware; // Use hardware name as key
            if (!acc[key]) {
                acc[key] = {...item}; // If not in accumulator, add it
            } else {
                acc[key].quantity += item.quantity; // Sum up quantities
            }
            return acc;
        }, {});

    const hardwareArray = Object.values(combinedHardware); // Convert object back to array

    return (
        <tbody>
        {hardwareArray.map((item, index) => (
            <tr key={index}>
                <td>{item.hardware}</td>
                <td>{item.mfgNum || "-"}</td>
                {/* Show "-" if mfgNum is missing */}
                <td>{item.quantity}</td>
            </tr>
        ))}
        </tbody>
    );
});

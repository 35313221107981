import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

// Async Thunks
export const fetchVendors = createAsyncThunk(
    "vendors/fetchVendors",
    async (_, {getState}) => {
        const token = getState().user.accessToken; // Retrieve token from state
        const response = await axios.get(`${baseURL}/api/vendors`, {
            headers: {Authorization: `Bearer ${token}`},
        });
        return response.data;
    }
);

export const searchVendorsByName = createAsyncThunk(
    "vendors/searchVendorsByName",
    async (name, {getState}) => {
        const token = getState().user.accessToken;
        const response = await axios.get(`${baseURL}/api/vendors/search`, {
            headers: {Authorization: `Bearer ${token}`},
            params: {name},
        });
        return response.data;
    }
);

export const searchVendorByEmail = createAsyncThunk(
    "vendors/searchVendorByEmail",
    async (email, {getState}) => {
        const token = getState().user.accessToken;
        const response = await axios.get(`${baseURL}/api/vendors/email`, {
            headers: {Authorization: `Bearer ${token}`},
            params: {email},
        });
        return response.data ? [response.data] : [];
    }
);

export const createVendor = createAsyncThunk(
    "vendors/createVendor",
    async (vendor, {getState}) => {
        const token = getState().user.accessToken;
        const response = await axios.post(`${baseURL}/api/vendors`, vendor, {
            headers: {Authorization: `Bearer ${token}`},
        });
        return response.data;
    }
);

export const updateVendor = createAsyncThunk(
    "vendors/updateVendor",
    async ({id, vendor}, {getState}) => {
        const token = getState().user.accessToken;
        const response = await axios.put(`${baseURL}/api/vendors/${id}`, vendor, {
            headers: {Authorization: `Bearer ${token}`},
        });
        return response.data;
    }
);

export const deleteVendor = createAsyncThunk(
    "vendors/deleteVendor",
    async (id, {getState}) => {
        const token = getState().user.accessToken;
        await axios.delete(`${baseURL}/api/vendors/${id}`, {
            headers: {Authorization: `Bearer ${token}`},
        });
        return id;
    }
);

// Slice
const vendorSlice = createSlice({
    name: "vendors",
    initialState: {
        vendors: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendors.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchVendors.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.vendors = action.payload;
            })
            .addCase(fetchVendors.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })

            // Handle other cases
            .addCase(searchVendorsByName.fulfilled, (state, action) => {
                state.vendors = action.payload;
            })
            .addCase(searchVendorByEmail.fulfilled, (state, action) => {
                state.vendors = action.payload;
            })
            .addCase(createVendor.fulfilled, (state, action) => {
                state.vendors.push(action.payload);
            })
            .addCase(updateVendor.fulfilled, (state, action) => {
                const updatedVendor = action.payload;
                const index = state.vendors.findIndex((v) => v.id === updatedVendor.id);
                if (index !== -1) state.vendors[index] = updatedVendor;
            })
            .addCase(deleteVendor.fulfilled, (state, action) => {
                state.vendors = state.vendors.filter((v) => v.id !== action.payload);
            });
    },
});

export default vendorSlice.reducer;

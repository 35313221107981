// Import necessary dependencies
import React from 'react';
import {getTooltipContent, renderDropdown} from './utils/renderUtils';

/**
 * MaterialsSection Component
 *
 * This component renders the "Materials" section of the order form, allowing users
 * to configure material options for case, front, and back panel materials.
 *
 * @param {Object} props - Props passed to the component
 * @param {Object} itemProperties - The properties of the current item being configured
 * @param {boolean} excludeFronts - State indicating whether the front parts should be excluded
 * @param {Function} setExcludeFronts - Function to toggle excluding front parts
 * @param {Array} materials - Array of material options
 * @param {Function} handleInputChange - Function to handle input changes
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions
 * @param {Array} edgebandingOptions - Array of edgebanding options (not used directly here)
 */
function MaterialsSection({
                              itemProperties,
                              excludeFronts,
                              setExcludeFronts,
                              materials,
                              handleInputChange,
                              handleToolTipClick,
                              edgebandingOptions
                          }) {
    return (
        <div className="order-form__section">
            {/* Section Title */}
            <h3 className="order-form__section-title">Materials</h3>

            {/* Dropdown for Case Material (if caseEdge is not '0') */}
            {itemProperties.caseEdge !== '0' &&
                renderDropdown(
                    'Case Material', // Label for the dropdown
                    'caseMaterial', // Field name
                    materials.filter(m => m.visible === 1).map(m => m.description), // Filtered material options
                    undefined, // Default value (undefined in this case)
                    false, // Disable condition (false here, dropdown is always enabled)
                    itemProperties, // Current item properties
                    handleInputChange, // Function to handle input changes
                    handleToolTipClick // Function to handle tooltip interactions
                )}

            {/* Dropdown and checkbox for Front Material (if frontEdge is not '0') */}
            {itemProperties.frontEdge !== '0' && (
                <>
                    {/* Dropdown for Front Material */}
                    {renderDropdown(
                        'Front Material', // Label for the dropdown
                        'frontMaterial', // Field name
                        materials.filter(m => m.visible === 1).map(m => m.description), // Filtered material options
                        '3/4" Medex', // Default value for front material
                        itemProperties.excludeFronts, // Disable condition (based on excludeFronts state)
                        itemProperties, // Current item properties
                        handleInputChange, // Function to handle input changes
                        handleToolTipClick // Function to handle tooltip interactions
                    )}

                    {/* Checkbox to exclude front parts */}
                    {itemProperties.caseEdge !== '0' && itemProperties.frontEdge !== '0' && (
                        <div className="order-form__checkbox-group">
                            <input
                                type="checkbox"
                                className="order-form__checkbox"
                                id="excludeFronts"
                                checked={itemProperties.excludeFronts} // Checkbox state
                                onChange={() => {
                                    // Toggle excludeFronts state and update the item properties
                                    setExcludeFronts(!excludeFronts);
                                    handleInputChange(
                                        {target: {value: !excludeFronts}},
                                        'excludeFronts'
                                    );
                                }}
                            />
                            <label
                                className="order-form__checkbox-label"
                                htmlFor="excludeFronts"
                            >
                                Exclude Front Parts?
                            </label>

                            {/* Tooltip for the "Exclude Front Parts" checkbox */}
                            <span
                                className="order-form__tooltip-trigger"
                                onClick={() => handleToolTipClick('excludeFronts')}
                            >
                            <span className="order-form__tooltip-content">
                                {getTooltipContent('excludeFronts')}
                            </span>
                        </span>
                        </div>
                    )}


                </>
            )}

            {/* Dropdown for Back Panel Material (if matBack is not '0') */}
            {itemProperties.matBack !== '0' &&
                renderDropdown(
                    'Back Panel Material', // Label for the dropdown
                    'backPanelMaterial', // Field name
                    materials.filter(m => m.visible === 1).map(m => m.description), // Filtered material options
                    undefined, // Default value (undefined in this case)
                    false, // Disable condition (false here, dropdown is always enabled)
                    itemProperties, // Current item properties
                    handleInputChange, // Function to handle input changes
                    handleToolTipClick // Function to handle tooltip interactions
                )}
        </div>
    );
}

export default MaterialsSection;

import React, {useState} from 'react';
import {Alert, Button, Form, Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {storeUser} from '../features/user/userSlice'; // Adjust the import path as per your project structure
import LoginService from "../api/LoginService";
import ForgotPasswordModal from "./ForgotPasswordModal"; // Assuming you have a login service

const LoginModal = ({show, handleClose, handleRegisterOpen, fromCart}) => {
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();

    const handleChange = (e) => {
        setLoginData({...loginData, [e.target.id]: e.target.value});
    };

    const handleLogin = async () => {
        try {
            const response = await LoginService(loginData);
            if (response.data.message === "Successfully Signed In") {
                dispatch(storeUser({
                    user: response.data.userDTO,
                    accessToken: response.data.token,
                    refreshToken: response.data.refreshToken,
                    role: response.data.role,
                }));
                handleClose(); // Close modal on successful login
            } else if (response.data.statusCode === 500) {
                setErrorMessage('Either your email or password is incorrect.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setErrorMessage('Login failed. Please try again.');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const handleForgotPasswordOpen = () => {
        setShowForgotPassword(true);
        handleClose();
    };

    const handleForgotPasswordClose = () => {
        setShowForgotPassword(false);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: 'black'}}>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form onKeyDown={handleKeyDown}>
                        <Form.Group controlId="email">
                            <Form.Label style={{color: 'black'}}>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={handleChange}/>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label style={{color: 'black'}}>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={handleChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100">
                        <div className="d-flex justify-content-between mb-3">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                handleForgotPasswordOpen();
                            }} style={{textDecoration: 'none', color: 'black'}}>Forgot Password?</a>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                handleRegisterOpen();
                            }} style={{textDecoration: 'none', color: 'black'}}>Register</a>
                        </div>
                        <Button
                            variant="primary"
                            onClick={handleLogin}
                            style={{backgroundColor: 'black', color: 'white', width: '100%', padding: '15px'}}
                        >
                            Login
                        </Button>
                        {fromCart && (
                            <p className="mt-3 text-center" style={{fontStyle: 'italic', color: 'black'}}>
                                In order to receive a quote, you must either login or register. Please note it may take
                                up to one business day to receive pricing.
                            </p>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
            <ForgotPasswordModal show={showForgotPassword} handleClose={handleForgotPasswordClose}/>
        </>
    );
};

export default LoginModal;


